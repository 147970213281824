<template>
  <form @submit.prevent="submit()" v-bind="{...$attrs,...$props}" v-on="$listeners">
    <slot name="before" :rform="self"></slot>
    <div :class="classContent">
      <slot :rform="self"></slot>
    </div>
    <slot name="after" :rform="self"></slot>
  </form>
</template>
<script>
import Documento from '../js/db/Documento';
export default {
  data() {
    return {
      self: this,
      target: process.env.TARGET,
      formData: { ...this.getFilterFromLocalStorage(), ...this.defaultData, ...this.initialData },
      errors: {},
      filterConfig: {},
      validateFields: {} // Igual que validationFields pero se usa desde fuera (rInput)
    };
  },
  props: {
    classContent: {},
    dbAdapter: {
      type: Object
    },
    itemId: String,
    defaultData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    initialData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    validationMessage: {
      type: String,
      default: "Revise los datos inválidos"
    },
    validationFields: {
      type: Object,
      default: function() {
        return {};
      }
      // Ejemplo de validationFields
      // {
      //  dni: {isValid: DNI.isValid, errorMessage: "* Introduzca un DNI válido"},
      //  iban: {isValid: IBAN.isValid, errorMessage: "* Introduzca un IBAN válido"}
      // }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    beforeSave: {
      type: Function,
      default: function(data, resolve, reject) {
        resolve(data);
      }
    },
    filtersName: String,
    documentoAdapter: {
      type: Object,
      default: ()=>{return Documento;}
    }
  },
  mounted() {
    var self = this;
    if (self.itemId) {
      self.loadItem(self.itemId);
    }
  },
  methods: {
    loadFilters: function(filters) {
      const self = this;
      self.clear();
      self.formData = { ...filters, ...self.formData };
    },
    getValue: function(name) {
      var self = this;
      var namespaces = name.split(".");
      var context = self.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        context = context[namespaces[i]];
      }
      if (this.$attrs && this.$attrs.multiple && context && context != null && context.indexOf(",") > -1) {
        context = context.split(",");
      }
      if (this.type == "datepicker") {
        if (context) {
          var previousVal = context;
          if (typeof context == "string") {
            context = new Date(previousVal);
            if (isNaN(context.getTime())) {
              var dateParts = previousVal.split("/");
              // month is 0-based, that's why we need dataParts[1] - 1
              context = new Date(dateParts[2], dateParts[1] - 1, + dateParts[0]); 
            }
          }
          context = [context];
        } else {
          context = [];
        }
      }
      return context;
    },
    submit: function() {
      const self = this;
      self.onSubmit(self.getFilter());
    },
    onSubmit: function(filters) {
      var self = this;
      self.saveFilterToLocalStorage(filters);
      self.$emit('submit', filters);
    },
    saveFilterToLocalStorage: function(filters) {
      var self = this;
      if (self.filtersName) {
        localStorage.setItem("filter-" + self.filtersName, JSON.stringify(filters));
      }
    },
    getFilterFromLocalStorage: function() {
      var self = this;
      var objReturn = {};
      if (self.filtersName) {
        objReturn = localStorage.getItem("filter-" + self.filtersName);
      }
      if (objReturn && typeof objReturn == "string") {
        try {
          objReturn = JSON.parse(objReturn);
        } catch (e) {
          objReturn = {};
        }
      }
      return objReturn || {};
    },
    validate: function(data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        var error = {message: self.validationMessage};
        error.errors = {};
        const fields = Object.assign(self.validationFields, self.validateFields);
        for (const key in fields) {
          if (fields.hasOwnProperty(key)) {
            const element = fields[key];
            if (!element.isValid(self.getValue(key) || "")) {
              var obj = {};
              obj[key] = element.errorMessage;
              error.errors = Object.assign(error.errors, obj);
            }
          }
        }
        if (Object.keys(error.errors).length > 0) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    },
    beforeSaveFn: function() {
      var self = this;
      return new Promise(self.beforeSave.bind(self, self.formData));
    },
    uploadDocuments: function(data) {
      var self = this;
      var iterate = function(uploads, input, parent, key) {
        if (Array.isArray(input)) {
          input.forEach(function(el, idx) {
            iterate(uploads, el, input, idx);
          });
        } else if (typeof input === "object" && input !== null) {
          if (input._base64 && self.documentoAdapter) {
            uploads.push(
              new Promise(function(resolve, reject) {
                var params = {
                  nombre: input.nombre,
                    extension: input.extension,
                    _path: input._path,
                    base64: input._base64,
                    tipo: input.tipo
                  };
                  self.documentoAdapter.uploadData(params)
                    .then(function(res) {
                      //Sustituir campos
                      parent[key] = res.list ? res.list[0] : res;
                      resolve();
                    })
                    .catch(function(e) {
                      reject(e);
                    });
                })
            );
          } else {
            for (let el in input) {
              iterate(uploads, input[el], input, el);
            }
          }
        }
        return uploads;
      };
      return new Promise(function(resolve, reject) {
        Promise.all(iterate([], data)).then( _ => {
            resolve(data);
          }).catch(e => reject(e));
      });
    },
    loadItem: function(itemId) {
      var self = this;
      var app = self.$f7;
      if (self.dbAdapter) {
        app.preloader.show();
        self.dbAdapter
          .get({ itemId })
          .then(function(res) {
            self.formData = res;
            self.$emit("loadItem", res);
          })
          .catch(function(e) {
            console.log(e);
            app.dialog.alert(e.message);
            self.$emit("errorLoading", e.message);
          })
          .then(function() {
            app.preloader.hide();
          });
      } else {
        self.$emit("loadItem", self.formData);
      }

    },
    saveItem(ignoreAdapter = false) {
      
      var self = this;
      var app = self.$f7;
      if (!self.formData.guardadoSilencioso) {
        app.preloader.show();
      }
      self
        .beforeSaveFn()
        .then(res => self.validate(res))
        .then(res => self.uploadDocuments(res))
        .then(function(data) {
          if (self.dbAdapter && !ignoreAdapter) {
            return self.dbAdapter.save({
              data: data,
              itemId: self.itemId
            });
          } else {
            return new Promise(function(resolve, reject) {
              resolve(self.formData);
            });
          }
        })
        .then(function(res) {
          self.errors = {};
          self.$emit("save", res);
        })
        .catch(function(e) {
          console.log(e);
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
          self.errors = e.errors || {};
          self.$emit("error", e);
        })
        .then(function() {
          if (!self.formData.guardadoSilencioso) {
            app.preloader.hide();
          }
        });
    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          if (self.dbAdapter) {
            self.dbAdapter.delete({itemId: self.itemId}).then(function(res) {
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("rlist.registro_eliminado"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.$emit("deleteItem");
                self.$f7router.back()
              }
            }).catch(function(error){
              app.dialog.alert(error.message);
            });
          } else {	
            self.$emit("deleteItem");	
          }
        }
      );
    },
    clear: function() {
      var self = this;
      //self.$set(self, 'formData', {});
      self.formData = { ...{}, ...this.defaultData};
      return;
    },
    getFilter: function() {
      var self = this;
      //var res = [];
      var res = {};
      for (let f in self.formData) {
        let d = self.formData[f];
        let fc = self.filterConfig[f] || {};
        res[f] = d;
        // if (d && typeof d === "object" && d.constructor == Object) {
        //   // M2o
        //   let searchField = fc.searchField || "id";
        //   //let operation = fc.operation || "=";
        //   if (!d[searchField]) continue;
        //   //res.push([f + "." + searchField, operation, d[searchField]]);
        //   res[f] = d[searchField];
        // } else {
        // // if ((d && typeof d === "string") || d instanceof String) {
        //   //let operation = fc.operation || "%LIKE%";
        //   //res.push([f, operation, d]);
        //   res[f] = d;
        // }
      }
      return res;
    }
  }
};
</script>