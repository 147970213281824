<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('facturas.editar_factura')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('facturas.factura')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('facturas.generar')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :defaultData="{lineasFactura:[]}"
      :readonly="readonly"
      :dbAdapter="Factura"
    >
      <f7-row class="padding-vertical-half padding-right">
        <f7-col width="100" medium="75">
        </f7-col>
        <f7-col width="100" medium="25">
        </f7-col>
      </f7-row>
      <f7-block-title>
        Factura
      </f7-block-title>
      <f7-card class="padding-vertical-half padding-right" :style="'z-index:'+(rform.formData.lineasFactura.length + 1)">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  floating-label
                  :label="$t('facturas.numero')"
                  :form="rform"
                  type="text"
                  name="numero"
                  :readonly="true"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('facturas.estado')"
                  :form="rform"
                  type="text"
                  name="estado.estado"
                  :readonly="true"
                ></r-input>
                <r-input
                  v-if="rform.formData.estado && rform.formData.estado.estado == 'CANCELADO'"
                  floating-label
                  :label="$t('facturas.motivo_cancel')"
                  :form="rform"
                  type="text"
                  name="estado.mensaje"
                  :readonly="true"
                >
                </r-input>
                <r-autocomplete
                  ref="cliente"
                  :dbAdapter="Cliente"
                  floating-label
                  :label="$t('facturas.cliente')"
                  :form="rform"
                  name="cliente"
                  labelProp="nombre"
                  :readonly="true"
                ></r-autocomplete>
              </ul>
            </f7-col>
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  class="col-50"
                  floating-label
                  :label="$t('facturas.fecha_emision')"
                  :form="rform"
                  type="datepicker"
                  name="fechaEmision"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  class="col-50"
                  floating-label
                  :label="$t('facturas.fecha_vencimiento')"
                  :form="rform"
                  type="datepicker"
                  name="fechaVencimiento"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  class="col-50"
                  floating-label
                  :label="$t('facturas.subtotal')"
                  :form="rform"
                  type="text"
                  name="subtotal"
                  :readonly="true"
                ></r-input>
                <r-input
                  class="col-50"
                  floating-label
                  :label="$t('facturas.totalIva')"
                  :form="rform"
                  type="text"
                  name="totalIva"
                  :readonly="true"
                ></r-input>
                <r-input
                  class="col-50"
                  floating-label
                  :label="$t('facturas.totalFactura')"
                  :form="rform"
                  type="text"
                  name="totalFactura"
                  :readonly="true"
                ></r-input>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <f7-block-title>
        Líneas Factura
      </f7-block-title>

      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
        v-for="(item,index) in rform.formData.lineasFactura"
        :key="'linea-'+index"
        :style="'z-index:'+(rform.formData.lineasFactura.length - index)"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <!-- <f7-col width="100" medium="50"> -->
                  <r-input
                    class="col-100"
                    floating-label
                    :label="$t('facturas.concepto')"
                    :form="rform"
                    type="text"
                    :name="'lineasFactura.'+index+'.concepto'"
                    :readonly="true"
                  ></r-input>
                  <r-input
                    class="col-100 medium-33"
                    floating-label
                    :label="$t('facturas.codigo_pedido')"
                    :form="rform"
                    type="text"
                    :name="'lineasFactura.'+index+'.codPedido'"
                    :readonly="true"
                  ></r-input>
                  <r-input
                    class="col-100 medium-33"
                    floating-label
                    :label="$t('facturas.matricula')"
                    :form="rform"
                    type="text"
                    :name="'lineasFactura.'+index+'.matricula'"
                    :readonly="true"
                  ></r-input>
                  <r-input
                    class="col-100 medium-33"
                    floating-label
                    :label="$t('facturas.tipoIva')"
                    :form="rform"
                    type="text"
                    :name="'lineasFactura.'+index+'.tipoIva'"
                    :readonly="true"
                  ></r-input>
                  <r-input
                    class="col-100 medium-33"
                    floating-label
                    :label="$t('facturas.codigo_inspeccion')"
                    :form="rform"
                    type="text"
                    :name="'lineasFactura.'+index+'.codInspeccion'"
                    :readonly="true"
                  ></r-input>
                  <r-input
                    class="col-100 medium-33"
                    floating-label
                    :label="$t('facturas.bastidor')"
                    :form="rform"
                    type="text"
                    :name="'lineasFactura.'+index+'.bastidor'"
                    :readonly="true"
                  ></r-input>
                  <r-input
                    class="col-100 medium-33"
                    floating-label
                    :label="$t('facturas.total')"
                    :form="rform"
                    type="text"
                    :name="'lineasFactura.'+index+'.total'"
                    :readonly="true"
                  ></r-input>
                <!-- </f7-col> -->
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<style scoped>
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Factura from "./../../js/db/Factura.js";
import Cliente from "./../../js/db/Cliente.js";

export default {
  components: {
    rForm,
    rInput,
    rAutocomplete,
  },
  data: function() {
    var self = this;
    return {
      Factura,
      Cliente,
      readonly: !this.editable,
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("facturas.factura_guardada"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>