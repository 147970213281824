import DatabaseModel from './DatabaseModel.js';

var Combos = Object.create(DatabaseModel);

Combos.model = 'combos';

Combos.getComboById = function(comboId){
    var self = this;
    var params = {
        url: self.server_inspecciones + self.prefix + self.model + '/' + comboId + '/search/' ,
        method: 'POST',
        data: {}
    };
    return self.sendRequest(params);
}

export default Combos;