<template>
  <f7-page :page-content="false" @page:beforeremove="onPageBeforeRemove">
    <!-- Top Navbar -->
    <f7-navbar
      :title="$t('facturas.facturas')"
      :back-link="$t('atras')"
    ></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-list
        ref="rlist"
        slot="default"
        :dbAdapter="Factura"
        sort="fechaEmision"
        sortDir="DESC"
        :tableMode="true"
        :virtualListParams="{ height: 32 }"
        class="card full-height data-table"
        classContent="card-content full-height"
      >
        <template v-slot:before="{ rlist }">
          <f7-card-header>
            <div
              class="data-table-header"
              v-if="Object.keys(rlist.selected).length == 0"
            >
              <div class="data-table-links">
                <a class="link" href="/facturas/new/">Generar Factura</a>
              </div>
              <div class="data-table-actions">
                <f7-link
                  small
                  @click="rlist.refresh()"
                  icon-f7="arrow_counterclockwise"
                ></f7-link>
                <f7-link small @click="rlist.exportExcel('Facturas.xlsx')" icon-f7="doc_text_fill"  v-tooltip="'Exportar a Excel'"></f7-link>
                <f7-link
                  small
                  @click="$refs.rpanel.toggle()"
                  icon-f7="line_horizontal_3_decrease"
                  v-show="!rPanelOpenedByBreakpoint"
                ></f7-link>
              </div>
            </div>
            <div class="data-table-header-selected display-flex" v-else>
              <!-- Selected table title -->
              <div class="data-table-title-selected">
                <span class="data-table-selected-count">{{
                  $tc(
                    "rlist.n_registros_seleccionados",
                    Object.keys(rlist.selected).length,
                    { count: Object.keys(rlist.selected).length }
                  )
                }}</span>
              </div>
              <!-- Selected table actions -->
              <div class="data-table-actions">
                <!-- <f7-link icon-f7="trash"></f7-link> -->
              </div>
            </div>
          </f7-card-header>
        </template>

        <template v-slot:header="{ rlist }">
          <thead class="thead-sticky">
            <tr>
              <th class="checkbox-cell" style="width: 2rem;">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    @change="rlist.toggleSelectAll($event.target.checked)"
                  />
                  <i class="icon-checkbox"></i>
                </label>
              </th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('numero')"
                :class="rlist.sortableClasses('numero')"
              >
                {{ $t("facturas.numero") }}
              </th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('estado')"
                :class="rlist.sortableClasses('estado')"
              >
                {{ $t("facturas.estado") }}
              </th>
              <th>{{ $t("facturas.cliente") }}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('fechaEmision')"
                :class="rlist.sortableClasses('fechaEmision')"
              >
                {{ $t("facturas.fecha_emision") }}
              </th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('fechaVencimiento')"
                :class="rlist.sortableClasses('fechaVencimiento')"
              >
                {{ $t("facturas.fecha_vencimiento") }}
              </th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('subtotal')"
                :class="rlist.sortableClasses('subtotal')"
              >
                {{ $t("facturas.subtotal") }}
              </th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('totalIva')"
                :class="rlist.sortableClasses('totalIva')"
              >
                {{ $t("facturas.totalIva") }}
              </th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('totalFactura')"
                :class="rlist.sortableClasses('totalFactura')"
              >
                {{ $t("facturas.totalFactura") }}
              </th>
              <th>{{ $t("facturas.lineas") }}</th>
              <th
                style="width:7em;"
                class="label-cell text-align-center right-column-sticky"
              >
                <f7-icon f7="gear_alt_fill"></f7-icon>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:default="{ vlData, rlist }">
          <tr v-for="item in vlData.items" :key="'factura-' + item.id">
            <th class="checkbox-cell">
              <label class="checkbox">
                <input
                  type="checkbox"
                  :checked="item.id in rlist.selected"
                  @change="rlist.toggleSelect(item, $event.target.checked)"
                />
                <i class="icon-checkbox"></i>
              </label>
            </th>
            <td class="label-cell">{{ item.numero }}</td>
            <td v-html="getEstadoBadge(item.estado.estado)"></td>
            <td>{{ item.cliente.nombre }}</td>
            <td>
              {{
                item.fechaEmision || "-" | dateParse | dateFormat("DD/MM/YYYY")
              }}
            </td>
            <td>
              {{
                item.fechaVencimiento ||
                  "-" | dateParse | dateFormat("DD/MM/YYYY")
              }}
            </td>
            <td>{{ item.subtotal }}</td>
            <td>{{ item.totalIva }}</td>
            <td>{{ item.totalFactura }}</td>
            <td class>{{ item.lineasFactura.length }}</td>
            <td class="text-align-right right-column-sticky">
              <f7-link
                icon-f7="eye"
                color="green"
                :href="`/facturas/view/${item.id}/`"
                v-tooltip="'Ver Factura'"
              ></f7-link>
              <f7-link
                icon-f7="square_pencil"
                color="blue"
                :href="`/facturas/edit/${item.id}/`"
                v-tooltip="'Editar Factura'"
              ></f7-link>
              <f7-link
                v-if="item.estado.estado != 'RECHAZADA' && item.estado.estado != 'CANCELADA'"
                icon-f7="paperplane_fill"
                color="green"
                @click="send(item)"
                v-tooltip="'Enviar Factura'"
              ></f7-link>
              <f7-link
                v-if="item.estado.estado != 'RECHAZADA' && item.estado.estado != 'CANCELADA'"
                icon-f7="cloud_download_fill"
                color="green"
                @click.prevent="downloadFactura(item)"
                v-tooltip="'Descargar Factura'"
              ></f7-link>
              <f7-link
                v-if="item.estado.estado != 'PAGADA' && item.estado.estado != 'CANCELADA'"
                icon-f7="clear"
                color="red"
                @click="facturaSelected = item"
                popup-open=".popup-cancel-factura"
                v-tooltip="'Cancelar Factura'"
              ></f7-link>
            </td>
          </tr>
        </template>
        <template v-slot:afterInner="{ rlist }">
          <rPanel
            ref="rpanel"
            side="right"
            @openedByBreakpoint="rPanelOpenedByBreakpoint = true"
            @closedByBreakpoint="rPanelOpenedByBreakpoint = false"
            v-slot="{ rpanel }"
          >
            <r-form
              @submit="
                rlist.setFilter($event);
                rpanel.close();
              "
            >
              <template v-slot:before="{ rform }">
                <f7-toolbar>
                  <div>Filtro</div>
                  <f7-link icon-f7="bin_xmark" @click="rform.clear()"></f7-link>
                </f7-toolbar>
              </template>
              <template v-slot="{ rform }">
                <f7-list no-hairlines-md>
                  <template v-slot:list>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('facturas.numero')"
                      :form="rform"
                      type="text"
                      name="numero"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('facturas.matricula')"
                      :form="rform"
                      type="text"
                      name="matricula"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('facturas.bastidor')"
                      :form="rform"
                      type="text"
                      name="bastidor"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('facturas.codigo_inspeccion')"
                      :form="rform"
                      type="text"
                      name="codigoInspeccion"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('facturas.codigo_pedido')"
                      :form="rform"
                      type="text"
                      name="codigoPedido"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('facturas.estado')"
                      :form="rform"
                      type="select"
                      name="estado"
                    >
                      <option selected></option>
                      <option value="CREADA">Creada</option>
                      <option value="ENVIADA">Enviada</option>
                      <option value="PAGADA">Pagada</option>
                      <option value="RECHAZADA">Rechazada</option>
                      <option value="CANCELADA">Cancelada</option>
                    </r-input>
                    <r-autocomplete
                      :dbAdapter="Cliente"
                      floating-label
                      :label="$t('facturas.cliente')"
                      :form="rform"
                      name="clienteid"
                      idProp="id"
                      labelProp="nombre"
                      ref="cliente"
                      @loaded="if($event.length==1) $refs.cliente.setValue($event[0]);"
                    ></r-autocomplete>
                    <li class="label-group">
                      <label>{{ $t("facturas.fecha_emision") }}</label>
                    </li>
                    <r-input
                      floating-label
                      :label="$t('facturas.desde')"
                      :form="rform"
                      type="datepicker"
                      name="fechaEmisionDesde"
                      :calendar-params="{
                        closeOnSelect: true,
                        routableModals: false,
                        dateFormat: {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        }
                      }"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('facturas.hasta')"
                      :form="rform"
                      type="datepicker"
                      name="fechaEmisionHasta"
                      :calendar-params="{
                        closeOnSelect: true,
                        routableModals: false,
                        dateFormat: {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        }
                      }"
                    ></r-input>
                    <li class="label-group">
                      <label>{{ $t("facturas.fecha_vencimiento") }}</label>
                    </li>
                    <r-input
                      floating-label
                      :label="$t('facturas.desde')"
                      :form="rform"
                      type="datepicker"
                      name="fechaVencimientoDesde"
                      :calendar-params="{
                        closeOnSelect: true,
                        routableModals: false,
                        dateFormat: {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        }
                      }"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('facturas.hasta')"
                      :form="rform"
                      type="datepicker"
                      name="fechaVencimientoHasta"
                      :calendar-params="{
                        closeOnSelect: true,
                        routableModals: false,
                        dateFormat: {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        }
                      }"
                    ></r-input>
                    <f7-button
                      type="submit"
                      class="margin-vertical"
                      icon-f7="search"
                      >Buscar</f7-button
                    >
                    <pre style="display:none;">{{ rform.formData }}</pre>
                  </template>
                </f7-list>
              </template>
            </r-form>
          </rPanel>
        </template>
        <template v-slot:after="{ rlist }">
          <f7-card-footer>
            <span>
              <b>{{
                $tc("rlist.n_registros", rlist.count, { count: rlist.count })
              }}</b>
            </span>
          </f7-card-footer>
        </template>
      </r-list>
    </f7-page-content>
    <f7-popup
      ref="popupCancelFactura"
      class="popup-cancel-factura"
      swipe-to-close
    >
      <f7-page>
        <f7-navbar title="Cancelar Factura">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card class="elevation-3 padding-vertical-half padding-right">
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <input
                      maxlength="256"
                      floating-label
                      :placeholder="$t('facturas.motivo_cancel')"
                      type="text"
                      name="motivoCancel"
                      ref="motivoCancel"
                      class="padding-left"
                    />
                  </f7-col>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-button
            fill
            icon-f7="clear"
            text="Cancelar"
            class="display-flex margin-left"
            color="red"
            @click="cancelFactura()"
          ></f7-button>
        </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<style scoped>
.label-group {
  margin-top: 20px;
  margin-left: 20px;
  font-weight: bold;
}
</style>
<script>
import rList from "./../../components/rList.vue";
import rPanel from "./../../components/rPanel.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Factura from "./../../js/db/Factura.js";
import Cliente from "./../../js/db/Cliente.js";
export default {
  components: {
    rList,
    rPanel,
    rForm,
    rInput,
    rAutocomplete,
    rTimepicker
  },
  data() {
    return {
      Factura,
      Cliente,
      rPanelOpenedByBreakpoint: false,
      facturaSelected: null
    };
  },
  computed: {},
  methods: {
    cancelFactura: function() {
      var self = this;
      var app = self.$f7;
      var id = self.facturaSelected.id;
      var mensaje = self.$refs.motivoCancel.value;
      if (!mensaje || mensaje == "") {
        app.dialog.alert("Debe introducir un motivo.");
        return;
      }
      self.$f7.dialog.confirm(
        "¿Esta seguro que desea cancelar la factura seleccionada?",
        "Cancelar",
        function() {
          app.preloader.show();
          Factura.delete({ itemId: id, data: { comentario: mensaje } })
            .then(function(res) {
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: "Factura cancelada",
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.$refs.motivoCancel.value = "";
                if (self.$refs.popupCancelFactura) {
                  self.$refs.popupCancelFactura.close(true);
                }
                self.$refs.rlist.refresh();
              }
            })
            .catch(function(error) {
              app.dialog.alert(error.message);
            })
            .finally(_ => app.preloader.hide());
        }
      );
    },
    getEstadoBadge: function(estado) {
      switch (estado) {
        case "CREADA":
          var texto = "Creada";
          var color = "gray";
          break;
        case "ENVIADA":
          var texto = "Enviada";
          var color = "blue";
          break;
        case "PAGADA":
          var texto = "Pagada";
          var color = "green";
          break;
        case "RECHAZADA":
          var texto = "Rechazada";
          var color = "red";
          break;
        case "CANCELADA":
          var texto = "Cancelada";
          var color = "red";
          break;
        case "COPIA_ORIGINAL":
          var texto = "Copia Original";
          var color = "gray";
          break;
        default:
          var texto = estado;
          var color = "gray";
      }
      return `<span class="badge color-` + color + `">` + texto + `</span>`;
    },
    onPageBeforeRemove() {
      const self = this;
      // Destroy popup when page removed
      if (self.popup) self.popup.destroy();
    },
    send(item) {
      const self = this;
      const app = self.$f7;
      app.preloader.show();
      Factura.send({ itemId: item.id }).then(_ => self.$refs.rlist.refresh()).finally(_ => app.preloader.hide());
    },
    downloadFactura(item){
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Factura.downloadFactura(item).finally(_=>app.preloader.hide());
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>
