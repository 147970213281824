import { routesMain, routesLeft } from '../pages/routes-main.js';
import pedidos from '../pages/pedidos/routes.js';
import inspecciones from '../pages/inspecciones/routes.js';
import conflictos from '../pages/conflictos/routes.js';
import marcas from '../pages/marcas/routes.js';
import modelos from '../pages/modelos/routes.js';
import coches from '../pages/coches/routes.js';
import clientes from '../pages/clientes/routes.js';
import usuarios from '../pages/usuarios/routes.js';
import facturas from '../pages/facturas/routes.js';
import config from '../pages/config/routes.js';
import ventas from '../pages/ventas/routes.js';
import gruposfuncionales from '../pages/gruposfuncionales/routes.js';
import formatos from '../pages/formatos/routes.js';

var routes = [
    ...usuarios,
    ...facturas,
    ...pedidos,
    ...inspecciones,
    ...conflictos,
    ...marcas,
    ...modelos,
    ...coches,
    ...clientes,
    ...config,
    ...ventas,
    ...gruposfuncionales,
    ...formatos,
    ...routesMain
];
export { routes, routesLeft };