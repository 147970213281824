import Coches from './coches.vue';
import CocheForm from './coche-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/coches/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('coches', ['ADMINISTRATIVO','SUPERVISOR'], Coches),
        //keepAlive: true
    },
    {
        path: '/coches/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('coches/new', ['ADMINISTRATIVO','SUPERVISOR'], CocheForm)
    },
    {
        path: '/coches/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('coches/edit', ['ADMINISTRATIVO','SUPERVISOR'], CocheForm)
    },
    {
        path: '/coches/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('coches/view', ['ADMINISTRATIVO','SUPERVISOR'], CocheForm, { editable: false })
    },
];
export default routes;