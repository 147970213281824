<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('ventas.ventas')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-list
        ref="rlist"
        slot="default"
        :dbAdapter="Venta"
        sort="fechaGrabacion"
        sortDir="DESC"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
      >
        <template v-slot:before="{rlist}">
          <f7-card-header>
            <div class="data-table-header" v-if="Object.keys(rlist.selected).length==0">
              <f7-button fill 
                color="red" 
                popup-open=".popup-borrar-importacion" 
                icon-f7="trash"> Borrar importación</f7-button>
              <f7-button fill 
                color="blue" 
                class="margin-left"
                icon-f7="arrow_down_doc_fill"
                @click="downloadPlantilla"> Descargar Plantilla</f7-button>
              <!-- :dbAdapter="Venta" -->
              <r-form
                v-slot="{rform}"
                ref="rformImport"
                :readonly="false"
                :beforeSave="beforeSaveImport"
                @save="onImport"
                @error="onImportError"
                :documentoAdapter="Documento"
              >
                <r-files :form="rform" name="documento" :maxFiles='1' label="Importar Documento" @loadFile="loadFile" :preview="false"></r-files>
              </r-form>
              <div class="data-table-actions">
                <f7-link small @click="rlist.refresh()" icon-f7="arrow_counterclockwise"></f7-link>
                <f7-link small @click="rlist.exportExcel('Ventas.xlsx')" icon-f7="doc_text_fill"  v-tooltip="'Exportar a Excel'"></f7-link>
                <f7-link
                  small
                  @click="$refs.rpanel.toggle()"
                  icon-f7="line_horizontal_3_decrease"
                  v-show="!rPanelOpenedByBreakpoint"
                ></f7-link>
              </div>
            </div>
            <div class="data-table-header-selected display-flex" v-else>
              <!-- Selected table title -->
              <div class="data-table-title-selected">
                <span
                  class="data-table-selected-count"
                >{{$tc('rlist.n_registros_seleccionados', Object.keys(rlist.selected).length, {count:Object.keys(rlist.selected).length}) }}</span>
              </div>
              <!-- Selected table actions -->
              <div class="data-table-actions">
                <f7-link icon-f7="trash"></f7-link>
              </div>
            </div>
          </f7-card-header>
        </template>

        <template v-slot:header="{rlist}">
          <thead class="thead-sticky">
            <tr>
              <th class="checkbox-cell" style="width: 2rem;">
                <label class="checkbox">
                  <input type="checkbox" @change="rlist.toggleSelectAll($event.target.checked)" />
                  <i class="icon-checkbox"></i>
                </label>
              </th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('codigoCentro')"
                :class="rlist.sortableClasses('codigoCentro')"
              >{{$t('ventas.codigoCentro')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('nombreCentro')"
                :class="rlist.sortableClasses('nombreCentro')"
              >{{$t('ventas.nombreCentro')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('fechaVenta')"
                :class="rlist.sortableClasses('fechaVenta')"
              >{{$t('ventas.fechaVenta')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('fechaGrabacion')"
                :class="rlist.sortableClasses('fechaGrabacion')"
              >{{$t('ventas.fechaGrabacion')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('vin')"
                :class="rlist.sortableClasses('vin')"
              >{{$t('ventas.vin')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('numeroStock')"
                :class="rlist.sortableClasses('numeroStock')"
              >{{$t('ventas.numeroStock')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('modelo')"
                :class="rlist.sortableClasses('modelo')"
              >{{$t('ventas.modelo')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('version')"
                :class="rlist.sortableClasses('version')"
              >{{$t('ventas.version')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('anyoModelo')"
                :class="rlist.sortableClasses('anyoModelo')"
              >{{$t('ventas.anyoModelo')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('fechaMatriculacion')"
                :class="rlist.sortableClasses('fechaMatriculacion')"
              >{{$t('ventas.fechaMatriculacion')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('matricula')"
                :class="rlist.sortableClasses('matricula')"
              >{{$t('ventas.matricula')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('tipoVehiculo')"
                :class="rlist.sortableClasses('tipoVehiculo')"
              >{{$t('ventas.tipoVehiculo')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('kilometraje')"
                :class="rlist.sortableClasses('kilometraje')"
              >{{$t('ventas.kilometraje')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('ivaDeducible')"
                :class="rlist.sortableClasses('ivaDeducible')"
              >{{$t('ventas.ivaDeducible')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('garantia')"
                :class="rlist.sortableClasses('garantia')"
              >{{$t('ventas.garantia')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('ventaUrgente')"
                :class="rlist.sortableClasses('ventaUrgente')"
              >{{$t('ventas.ventaUrgente')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('comienzoGarantiaReestreno')"
                :class="rlist.sortableClasses('comienzoGarantiaReestreno')"
              >{{$t('ventas.comienzoGarantiaReestreno')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('duracion')"
                :class="rlist.sortableClasses('duracion')"
              >{{$t('ventas.duracion')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('fechaCompra')"
                :class="rlist.sortableClasses('fechaCompra')"
              >{{$t('ventas.fechaCompra')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('fechaCreacion')"
                :class="rlist.sortableClasses('fechaCreacion')"
              >{{$t('ventas.fechaCreacion')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('claveCompraVO')"
                :class="rlist.sortableClasses('claveCompraVO')"
              >{{$t('ventas.claveCompraVO')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('precioCompraVO')"
                :class="rlist.sortableClasses('precioCompraVO')"
              >{{$t('ventas.precioCompraVO')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('costeMntoOExposicion')"
                :class="rlist.sortableClasses('costeMntoOExposicion')"
              >{{$t('ventas.costeMntoOExposicion')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('costeGarantia')"
                :class="rlist.sortableClasses('costeGarantia')"
              >{{$t('ventas.costeGarantia')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('costeReacondicionamiento')"
                :class="rlist.sortableClasses('costeReacondicionamiento')"
              >{{$t('ventas.costeReacondicionamiento')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('precioVentaNeto')"
                :class="rlist.sortableClasses('precioVentaNeto')"
              >{{$t('ventas.precioVentaNeto')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('precioVentaNetoIva')"
                :class="rlist.sortableClasses('precioVentaNetoIva')"
              >{{$t('ventas.precioVentaNetoIva')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('canalVenta')"
                :class="rlist.sortableClasses('canalVenta')"
              >{{$t('ventas.canalVenta')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('vendedor')"
                :class="rlist.sortableClasses('vendedor')"
              >{{$t('ventas.vendedor')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('cliente')"
                :class="rlist.sortableClasses('cliente')"
              >{{$t('ventas.cliente')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('visitasPagina')"
                :class="rlist.sortableClasses('visitasPagina')"
              >{{$t('ventas.visitasPagina')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('consultasACentro')"
                :class="rlist.sortableClasses('consultasACentro')"
              >{{$t('ventas.consultasACentro')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('enlacesEnviados')"
                :class="rlist.sortableClasses('enlacesEnviados')"
              >{{$t('ventas.enlacesEnviados')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('diasEnStock')"
                :class="rlist.sortableClasses('diasEnStock')"
              >{{$t('ventas.diasEnStock')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('calculoContribucion')"
                :class="rlist.sortableClasses('calculoContribucion')"
              >{{$t('ventas.calculoContribucion')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('fechaImportacion')"
                :class="rlist.sortableClasses('fechaImportacion')"
              >{{$t('ventas.fechaImportacion')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('codigoInspeccion')"
                :class="rlist.sortableClasses('codigoInspeccion')"
              >{{$t('ventas.inspeccion')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('calificacion')"
                :class="rlist.sortableClasses('calificacion')"
              >{{$t('ventas.calificacion')}}</th>
              <th style="width:7em;" class="label-cell text-align-center right-column-sticky">
                <f7-icon f7="gear_alt_fill"></f7-icon>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:default="{vlData,rlist}">
          <tr v-for="(item) in vlData.items" :key="'venta-'+item.id">
            <th class="checkbox-cell">
              <label class="checkbox">
                <input
                  type="checkbox"
                  :checked="item.id in rlist.selected"
                  @change="rlist.toggleSelect(item, $event.target.checked)"
                />
                <i class="icon-checkbox"></i>
              </label>
            </th>
            <td class>{{item.codigoCentro}}</td>
            <td class>{{item.nombreCentro}}</td>
            <td class>{{item.fechaVenta || '-' | dateParse | dateFormat('DD/MM/YYYY')}}</td>
            <td class>{{item.fechaGrabacion || '-' | dateParse | dateFormat('DD/MM/YYYY')}}</td>
            <td class>{{item.vin}}</td>
            <td class>{{item.numeroStock}}</td>
            <td class>{{item.modelo}}</td>
            <td class>{{item.version}}</td>
            <td class>{{item.anyoModelo}}</td>
            <td class>{{item.fechaMatriculacion || '-' | dateParse | dateFormat('MM/YY')}}</td>
            <td class>{{item.matricula}}</td>
            <td class>{{item.tipoVehiculo}}</td>
            <td class>{{item.kilometraje}}</td>
            <td v-html="getSiNoBadge(item.ivaDeducible)"></td>
            <td v-html="getSiNoBadge(item.garantia)"></td>
            <td v-html="getSiNoBadge(item.ventaUrgente)"></td>
            <td class>{{item.comienzoGarantiaReestreno || '-' | dateParse | dateFormat('DD/MM/YYYY')}}</td>
            <td class>{{item.duracion}}</td>
            <td class>{{item.fechaCompra || '-' | dateParse | dateFormat('DD/MM/YYYY')}}</td>
            <td class>{{item.fechaCreacion || '-' | dateParse | dateFormat('DD/MM/YYYY')}}</td>
            <td class>{{item.claveCompraVO}}</td>
            <td class>{{item.precioCompraVO}}</td>
            <td class>{{item.costeMntoOExposicion}}</td>
            <td class>{{item.costeGarantia}}</td>
            <td class>{{item.costeReacondicionamiento}}</td>
            <td class>{{item.precioVentaNeto}}</td>
            <td class>{{item.precioVentaNetoIva}}</td>
            <td class>{{item.canalVenta}}</td>
            <td class>{{item.vendedor}}</td>
            <td class>{{item.cliente}}</td>
            <td class>{{item.visitasPagina}}</td>
            <td class>{{item.consultasACentro}}</td>
            <td class>{{item.enlacesEnviados}}</td>
            <td class>{{item.diasEnStock}}</td>
            <td class>{{item.calculoContribucion}}</td>
            <td class>{{item.fechaImportacion || '-' | dateParse | dateFormat('DD/MM/YYYY')}}</td>
            <td class>{{item.inspeccion ? item.inspeccion.codigo : ''}}</td>
            <td class v-html="getCalificacionBadge(item.inspeccion)"></td>
            <td class="text-align-right right-column-sticky">
              <f7-link icon-f7="exclamationmark_triangle" :color="getCalificacionColor(item.inspeccion)" v-tooltip="getCalificacionColor(item.inspeccion) == 'green' ? 'Certificación Realizada Favorablemente' : 'Certificación NO Realizada Favorablemente'"></f7-link>
              <f7-link icon-f7="arrow_up_right_circle_fill" :color="item.inspeccion ? 'green' : 'red'" v-tooltip="item.inspeccion ? 'Ver Certificación' : 'No hay Certificación asociada'" :href="item.inspeccion ? `/inspecciones/view/${item.inspeccion.id}/` : ``"></f7-link>
              <!-- <f7-link icon-f7="trash" color="red" @click="rlist.removeItem(item.id)"></f7-link> -->
            </td>
          </tr>
        </template>
        <template v-slot:afterInner="{rlist}">
          <rPanel
            ref="rpanel"
            side="right"
            @openedByBreakpoint="rPanelOpenedByBreakpoint=true"
            @closedByBreakpoint="rPanelOpenedByBreakpoint=false"
            v-slot="{rpanel}"
          >
            <r-form @submit="rlist.setFilter($event); rpanel.close();">
              <template v-slot:before="{rform}">
                <f7-toolbar>
                  <div>Filtro</div>
                  <f7-link icon-f7="bin_xmark" @click="rform.clear();"></f7-link>
                </f7-toolbar>
              </template>
              <template v-slot="{rform}">
                <f7-list no-hairlines-md>
                  <template v-slot:list>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.codigoCentro')"
                      :form="rform"
                      type="text"
                      name="codigoCentro"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.nombreCentro')"
                      :form="rform"
                      type="text"
                      name="nombreCentro"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.vin')"
                      :form="rform"
                      type="text"
                      name="bastidor"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.numeroStock')"
                      :form="rform"
                      type="text"
                      name="numeroStock"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.modelo')"
                      :form="rform"
                      type="text"
                      name="modelo"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.version')"
                      :form="rform"
                      type="text"
                      name="version"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.anyoModelo')"
                      :form="rform"
                      type="text"
                      name="anyoModelo"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.matricula')"
                      :form="rform"
                      type="text"
                      name="matricula"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.tipoVehiculo')"
                      :form="rform"
                      type="text"
                      name="tipoVehiculo"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.claveCompraVO')"
                      :form="rform"
                      type="text"
                      name="claveCompraVO"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.canalVenta')"
                      :form="rform"
                      type="text"
                      name="canalVenta"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.vendedor')"
                      :form="rform"
                      type="text"
                      name="vendedor"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.cliente')"
                      :form="rform"
                      type="text"
                      name="cliente"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('ventas.calificacion')"
                      :form="rform"
                      type="select"
                      name="calificacion"
                    >
                      <option selected></option>
                      <option value="CERTIFICADAS">Certificadas para Ventas</option>
                      <option value="NO_CERTIFICADAS">No Certificadas para Ventas</option>
                    </r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('ventas.inspeccion')"
                      :form="rform"
                      type="text"
                      name="codigoInspeccion"
                    ></r-input>

                    <r-input
                      floating-label
                      :label="$t('ventas.ivaDeducible')"
                      :form="rform"
                      type="select"
                      name="ivaDeducible"
                    >
                      <option selected></option>
                      <option :value="true">Sí</option>
                      <option :value="false">No</option>
                    </r-input>

                    <r-input
                      floating-label
                      :label="$t('ventas.garantia')"
                      :form="rform"
                      type="select"
                      name="garantia"
                    >
                      <option selected></option>
                      <option :value="true">Sí</option>
                      <option :value="false">No</option>
                    </r-input>

                    <r-input
                      floating-label
                      :label="$t('ventas.ventaUrgente')"
                      :form="rform"
                      type="select"
                      name="ventaUrgente"
                    >
                      <option selected></option>
                      <option :value="true">Sí</option>
                      <option :value="false">No</option>
                    </r-input>

                    <li class="label-group"><label>{{$t('ventas.kilometraje')}}</label></li>
                    <r-input
                      floating-label
                      :label="$t('ventas.desde')"
                      :form="rform"
                      type="text"
                      name="kilometrajeDesde"
                      pattern="[0-9]*"
                      validate
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('ventas.hasta')"
                      :form="rform"
                      type="text"
                      name="kilometrajeHasta"
                      pattern="[0-9]*"
                      validate
                    ></r-input>

                    <li class="label-group"><label>{{$t('ventas.precioCompraVO')}}</label></li>
                    <r-input
                      floating-label
                      :label="$t('ventas.desde')"
                      :form="rform"
                      type="text"
                      name="precioCompraVODesde"
                      pattern="[0-9]*"
                      validate
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('ventas.hasta')"
                      :form="rform"
                      type="text"
                      name="precioCompraVOHasta"
                      pattern="[0-9]*"
                      validate
                    ></r-input>

                    <li class="label-group"><label>{{$t('ventas.precioVentaNeto')}}</label></li>
                    <r-input
                      floating-label
                      :label="$t('ventas.desde')"
                      :form="rform"
                      type="text"
                      name="precioVentaNetoDesde"
                      pattern="[0-9]*"
                      validate
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('ventas.hasta')"
                      :form="rform"
                      type="text"
                      name="precioVentaNetoHasta"
                      pattern="[0-9]*"
                      validate
                    ></r-input>

                    <li class="label-group"><label>{{$t('ventas.precioVentaNetoIva')}}</label></li>
                    <r-input
                      floating-label
                      :label="$t('ventas.desde')"
                      :form="rform"
                      type="text"
                      name="precioVentaNetoIvaDesde"
                      pattern="[0-9]*"
                      validate
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('ventas.hasta')"
                      :form="rform"
                      type="text"
                      name="precioVentaNetoIvaHasta"
                      pattern="[0-9]*"
                      validate
                    ></r-input>

                    <li class="label-group"><label>{{$t('ventas.fechaVenta')}}</label></li>
                    <r-input
                      floating-label
                      :label="$t('ventas.desde')"
                      :form="rform"
                      type="datepicker"
                      name="fechaVentaDesde"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('ventas.hasta')"
                      :form="rform"
                      type="datepicker"
                      name="fechaVentaHasta"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>

                    <li class="label-group"><label>{{$t('ventas.fechaGrabacion')}}</label></li>
                    <r-input
                      floating-label
                      :label="$t('ventas.desde')"
                      :form="rform"
                      type="datepicker"
                      name="fechaGrabacionDesde"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('ventas.hasta')"
                      :form="rform"
                      type="datepicker"
                      name="fechaGrabacionHasta"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>

                    <li class="label-group"><label>{{$t('ventas.fechaMatriculacion')}}</label></li>
                    <r-input
                      floating-label
                      :label="$t('ventas.desde')"
                      :form="rform"
                      type="datepicker"
                      name="fechaMatriculacionDesde"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('ventas.hasta')"
                      :form="rform"
                      type="datepicker"
                      name="fechaMatriculacionHasta"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>

                    <li class="label-group"><label>{{$t('ventas.fechaCompra')}}</label></li>
                    <r-input
                      floating-label
                      :label="$t('ventas.desde')"
                      :form="rform"
                      type="datepicker"
                      name="fechaCompraDesde"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('ventas.hasta')"
                      :form="rform"
                      type="datepicker"
                      name="fechaCompraHasta"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>

                    <li class="label-group"><label>{{$t('ventas.fechaCreacion')}}</label></li>
                    <r-input
                      floating-label
                      :label="$t('ventas.desde')"
                      :form="rform"
                      type="datepicker"
                      name="fechaCreacionDesde"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('ventas.hasta')"
                      :form="rform"
                      type="datepicker"
                      name="fechaCreacionHasta"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>

                    <li class="label-group"><label>{{$t('ventas.fechaImportacion')}}</label></li>
                    <r-input
                      floating-label
                      :label="$t('ventas.desde')"
                      :form="rform"
                      type="datepicker"
                      name="fechaImportacionDesde"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('ventas.hasta')"
                      :form="rform"
                      type="datepicker"
                      name="fechaImportacionHasta"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>

                    <f7-button type="submit" class="margin-vertical" icon-f7="search">Buscar</f7-button>
                  </template>
                </f7-list>
              </template>
            </r-form>
          </rPanel>
        </template>
        <template v-slot:after="{rlist}">
          <f7-card-footer>
            <span>
              <b>{{$tc('rlist.n_registros', rlist.count, {count:rlist.count})}}</b>
            </span>
          </f7-card-footer>
        </template>
      </r-list>
    </f7-page-content>

    <f7-popup ref="popupBorrar" class="popup-borrar-importacion" @popup:closed="$refs.rformBorrar.clear();" swipe-to-close>
      <f7-page>
        <f7-navbar title="Borrar Ventas">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-block-title>* Se tiene en cuenta la Fecha Grabación</f7-block-title>
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
      <r-form
        ref="rformBorrar"
        v-slot="{rform}"
        :readonly="false"
        :dbAdapter="Venta"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <f7-col width="100" medium="100">
                  <r-input
                    floating-label
                    :label="$t('ventas.anyo')"
                    :form="rform"
                    type="text"
                    name="anyo"
                    required
                    validate
                    pattern="[0-9]{4}"
                  ></r-input>
                  <r-input
                      floating-label
                      :label="$t('ventas.mes')"
                      :form="rform"
                      type="select"
                      name="mes"
                      required
                      validate
                    >
                      <option selected></option>
                      <option :value="1">Enero</option>
                      <option :value="2">Febrero</option>
                      <option :value="3">Marzo</option>
                      <option :value="4">Abril</option>
                      <option :value="5">Mayo</option>
                      <option :value="6">Junio</option>
                      <option :value="7">Julio</option>
                      <option :value="8">Agosto</option>
                      <option :value="9">Septiembre</option>
                      <option :value="10">Octubre</option>
                      <option :value="11">Noviembre</option>
                      <option :value="12">Diciembre</option>
                    </r-input>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
      </r-form>
      <f7-button
          fill
          icon-f7="trash"
          color="red"
          text="Borrar"
          class="display-flex margin-left margin-top"
          @click="borrarVentas"
        ></f7-button>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>

    <f7-popup ref="popupResumen" class="popup-resumen-importacion" swipe-to-close @popup:closed="importacion = {resultsOk: [], resultsKo: []}">
      <f7-page>
        <f7-navbar title="Resumen de la Importación">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-block-title>Total ventas importadas: {{importacion.resultsOk.length + importacion.resultsKo.length}}</f7-block-title>
      <f7-block-title>Total ventas con certificación favorable: {{importacion.resultsOk.length}}</f7-block-title>
      <f7-block-title>Total ventas sin certificación favorable: {{importacion.resultsKo.length}}</f7-block-title>
      <f7-card
        v-if="importacion.resultsKo.length > 0"
        class="elevation-3 padding-vertical-half padding-right"
      >
        <div class="margin-left">
          <div v-for="(item,index) in importacion.resultsKo"
              :key="'importacion-' + index">{{item.nombreCentro + ' - ' + item.vin + ' - ' + (item.matricula && item.matricula != 'null' ? item.matricula + ' - ' : '' ) + (!item.inspeccion ? 'Sin certificación asociada' : (!item.inspeccion.calificacion ? 'Certificación sin realizar' : 'Certificación no favorable'))}}</div>
        </div>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>


  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rList from "./../../components/rList.vue";
import rPanel from "./../../components/rPanel.vue";
import rForm from "./../../components/rForm.vue";
import rFiles from "./../../components/rFiles.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Documento from "./../../js/db/Documento.js";
import Venta from "./../../js/db/Venta.js";
export default {
  components: {
    rList,
    rPanel,
    rForm,
    rFiles,
    rInput,
    rAutocomplete
  },
  data() {
    return {
      Documento,
      Venta,
      rPanelOpenedByBreakpoint: false,
      importacion: {resultsOk: [], resultsKo: []}
    };
  },
  computed: {},
  methods: {
    downloadPlantilla: function() {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Venta.downloadPlantilla().finally(_=>app.preloader.hide());
    },
    beforeSaveImport: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      // var o = Object.assign({}, data);
      // o.documento = Array.isArray(o.documento) ? o.documento[0] : o.documento;
      // resolve(o);
      if(data) {
        resolve(data.documento);
      } else {
        reject("No se ha indicado ningún documento para importar.")
      }
    },
    onImport: function (res) {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      if(!res || res.documento.length == 0) {
        reject("No se ha indicado ningún documento para importar.")
      }
      var params = {data: {documento: res.documento[0]}};
      Venta.post(params)
        .then(function(res) {
          self.$refs.rformImport.clear();
          self.$refs.rlist.refresh();
          self.importacion = res;
          self.$refs.popupResumen.open();
        }).catch(function(e) {
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
        }).finally(function(e) {
          app.preloader.hide();
        });
    },
    onImportError: function (e) {
      var self = this;
      var app = self.$f7;
      self.$refs.rformImport.clear();
      self.$refs.rlist.refresh();
    },
    loadFile: function () {
      var self = this;
      self.$refs.rformImport.saveItem();
    },
    borrarVentas: function() {
      var self = this;
      var app = self.$f7;

      var data = self.$refs.rformBorrar.formData;
      if (!data.anyo || data.anyo == '' || data.anyo.length < 4) {
        app.dialog.alert("Introduzca un año válido")
      } else if (!data.mes || data.mes == '') {
        app.dialog.alert("Introduzca un mes válido")
      } else {
        app.preloader.show();
        Venta.delete(data).then(function(res) {
            app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: 'Borrado realizado: ' + res.length + ' registros eliminados',
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
              if (self.$refs.popupBorrar) {
                self.$refs.popupBorrar.close();
              }
              self.$refs.rlist.refresh();
          }).catch(function(error){
            app.dialog.alert(error.message);
          }).finally(_=>app.preloader.hide());
      }

    },
    getSiNoBadge: function(bool) {
      if (bool == null || bool == undefined) {
        var texto = "-";
        var color = "gray";
      } else if (bool) {
        var texto = "Si";
        var color = "green";
      } else {
        var texto = "No";
        var color = "red";
      }
      return `<span class="badge color-` + color + `">` + texto + `</span>`;
    },
    getCalificacionBadge: function(certificacion) {
      if (!certificacion) {
        var texto = "Sin certificación";
        var color = "gray";
      }else if(certificacion.lineaPedido && certificacion.lineaPedido.tipoInspeccion == "CERTIFICACION_M"){
        var realizada = !!certificacion.calificacion;
        var texto = realizada ? "Realizado" : "Sin realizar";
        var color = realizada ? "green" : "gray";
      } else {
        switch (certificacion.calificacion) {
          case "EXCELENTE":
            var texto = "Favorable";
            var color = "green";
            break;
          case "BUENO":
            var texto = "Favorable";
            var color = "lime";
            break;
          case "DESFAVORABLE":
            var texto = "Desfavorable";
            var color = "red";
            break;
          case "EN_CONFLICTO":
            var texto = "Pendiente";
            var color = "orange";
            break;
          case "JUSTIFICADA":
            var texto = "Justificada";
            var color = "orange";
            break;
          case "DEFECTOS_CORREGIDOS":
            var texto = "Resuelto de 2as";
            var color = "black";
            break;
          default:
            var texto = "Sin realizar";
            var color = "gray";
        }
      }
      return `<span class="badge color-` + color + `">` + texto + `</span>`;
    },
    getCalificacionColor: function(certificacion) {
      var color = "red";
      if (certificacion) {
        switch (certificacion.calificacion) {
          case "EXCELENTE":
          case "BUENO":
          case "DEFECTOS_CORREGIDOS":
            var texto = "Certificación Realizada Favorablemente";
            color = "green";
            break;
          case "DESFAVORABLE":
          case "EN_CONFLICTO":
          case "JUSTIFICADA":
            var texto = "Certificación No Realizada Favorablemente";
            color = "red";
            break;
        }
      }
      return color;
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>