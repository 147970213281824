<template>
  <f7-list-input
    :value="renderValue()"
    v-bind="{...$attrs,...$props}"
    autocomplete="off"
    v-on="$listeners"
    :error-message-force="!!form.errors[name]"
    :error-message="form.errors[name]"
    :readonly="true"
    :class="{readonly:readonly || form.readonly}"
  >
    <slot></slot>
    <slot name="media" slot="media"></slot>
  </f7-list-input>
</template>
<style>
.readonly input,
.readonly select {
  pointer-events: none;
}
</style>
<script>
export default {
  data() {
    return {
      self: this,
      picker: null
    };
  },
  methods: {
    getValue: function() {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        context = context[namespaces[i]];
      }
      return context;
    },
    renderValue: function() {
      var self = this;
      var context = self.getValue();
      if (context) {
        if (typeof context == "string") context = new Date(context);
        let value = [
          ("0" + context.getHours()).slice(-2),
          ("0" + context.getMinutes()).slice(-2)
        ];
        if (
          self.picker &&
          JSON.stringify(self.picker.getValue()) != JSON.stringify(value)
        ) {
          self.picker.setValue(value);
        }
        return value.join(":");
      } else {
        return null;
      }
    },
    setValue: function(val, a) {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          self.$set(context, namespaces[i], val);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
    }
  },
  props: {
    name: {
      required: true,
      type: String
    },
    form: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    }
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    var app = self.$f7;

    self.picker = app.picker.create({
      inputEl: self.$$(self.$el).find("input")[0],
      rotateEffect: true,
      routableModals: false,
      toolbarCloseText: "Cerrar",
      cols: [
        {
          values: Array.from({ length: 24 }, (_, i) => ("0" + i).slice(-2))
        },
        {
          divider: true,
          content: ":"
        },
        {
          values: "00 15 30 45".split(" ")
        }
      ],
      on: {
        change: function(picker, value, displayValue) {
          let v = self.getValue();
          if (v) {
            if (typeof v == "string") v = new Date(v);
          } else {
            v = new Date();
          }
          v.setHours(value[0]);
          v.setMinutes(value[1]);
          v = new Date(v);
          self.setValue(v, "calendar");
        }
      }
    });
  }
};
</script>