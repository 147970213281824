import Formatos from './formatos.vue';
import FormatoForm from './formatos-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/formatos/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('formatos', ['ADMINISTRATIVO'], Formatos),
        keepAlive: true
    },
    {
        path: '/formatos/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('formatos/new', ['ADMINISTRATIVO'], FormatoForm)
    },
    {
        path: '/formatos/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('formatos/edit', ['ADMINISTRATIVO'], FormatoForm)
    },
    {
        path: '/formatos/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('formatos/view', ['ADMINISTRATIVO'], FormatoForm, { editable: false })
    },
];
export default routes;