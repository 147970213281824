<template>
  <r-chart>
    <h3 v-if="title && title!=''" slot="title">{{title}}</h3>
    <div slot="chart" class="chart-container">
      <v-chart :forceFit="false" :width="width" :height="height" :data="data">
        <v-coord v-if="orientation == 'H'" type="rect" direction="LB" />
        <v-tooltip />
        <v-axis v-if="orientation == 'H'" :useHtml="true" :dataKey="nombre"  :label="label" />
        <v-axis v-else />
        <v-bar :position="nombre + '*' + valor" />
      </v-chart>
    </div>
    <slot name="filter" slot="filter"/>
  </r-chart>
</template>

<script>
  import rChart from "./rChart.vue";
  export default {
    extends: rChart,
    components: {
      rChart,
    },
    data() {
      return {
        label: { 
          offset: 4, 
          htmlTemplate: (text, item, index) => {
            return `<span title="${text}">${text}</span>`;
          }, 
          textStyle: {fontSize:11} 
        }
      };
    },
    props: {
      orientation: {
        type: String,
        default: "V",
      },
      height: {
        type: Number,
        default: 300
      },
      width: {
        type: Number,
        default: 695
      }
    }
  };
</script>
<style scoped>
  .chart-container {
    max-height:300px;
    overflow-y: auto;
  } 

</style>
