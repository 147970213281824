import Error403 from './../pages/403.vue';

var RoutesUtils = {
    checkAuthPage: function(routeTo, routeFrom, resolve, reject) {
        var self = this;
        let user = RoutesUtils.getUser();
        if (!user || user == null) {
            reject();
            self.app.views.main.router.navigate("/login/");
        } else {
            resolve();
        }
    },
    checkAuth: function() {
        let user = RoutesUtils.getUser();
        if (!user || user == null) {
            return false;
        } else {
            return true;
        }
    },
    checkPermission: function(page, roles, Comp, props) {
        return function(routeTo, routeFrom, resolve, reject) {
            var self = this;
            if (RoutesUtils.checkRolesPermission(roles)) {
                resolve({
                    component: Comp
                }, {
                    props: props
                });
            } else {
                resolve({ component: Error403 });
            }
        }
    },
    checkRolesPermission: function(roles) {
        var self = this;
        let user = RoutesUtils.getUser();
        if (!user || user == null || roles.filter(r => user.roles.indexOf(r) > -1).length <= 0 ) {
            return false;
        } 
        else {
            return true;
        }
    },
    getUser: function() {
        var user = localStorage.getItem("user");
        if (typeof user != "undefined" && user) {
            var userObj = JSON.parse(user);
            return userObj;
        }
        return null;
    }
}

export default RoutesUtils;