import Facturas from './facturas.vue';
import FacturaForm from './factura-form.vue';
import FacturaNew from './factura-new.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/facturas/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('facturas', ['ADMINISTRATIVO'], Facturas),
        //keepAlive: true
    },
    {
        path: '/facturas/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('facturas/new', ['ADMINISTRATIVO'], FacturaNew)
    },
    {
        path: '/facturas/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('facturas/edit', ['ADMINISTRATIVO'], FacturaForm)
    },
    {
        path: '/facturas/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('facturas/view', ['ADMINISTRATIVO'], FacturaForm, { editable: false })
    },
];
export default routes;