<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('clientes.cliente_form.editar_cliente')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('clientes.cliente_form.cliente')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('clientes.cliente_form.nuevo_cliente')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      @loadItem="loadItem"
      :readonly="readonly"
      :dbAdapter="Cliente"
      :defaultData="{configuraciones:[]}"
      :beforeSave="beforeSave"
      :documentoAdapter="Documento"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="20">
              <r-files 
                :maxFiles="1"
                :label="$t('clientes.cliente_form.imagen')" 
                :form="rform" 
                :name="'imagen'" 
                :documentoAdapter="Documento"
              />
              <r-input
                    floating-label
                    :label="$t('clientes.cliente_form.conectarConGIVO')"
                    :form="rform"
                    type="check"
                    name="conectarConGIVO"
                  ></r-input>
              <div v-if="rform.formData.conectarConGIVO">
                <!-- private String urlLogin; -->
                <r-input floating-label :label="$t('clientes.cliente_form.servidor.url')" :form="rform" type="text" name="servidor.url"></r-input>
                <!-- private String urlServer; -->
                <r-input floating-label :label="$t('clientes.cliente_form.servidor.token')" :form="rform" type="text" name="servidor.token"></r-input>
              </div>
            </f7-col>
            <f7-col width="100" medium="40">
              <ul>
                <f7-row>
                  <r-input
                    class="col-50"
                    floating-label
                    :label="$t('clientes.cliente_form.cif')"
                    :form="rform"
                    type="text"
                    name="cif"
                  ></r-input>
                  <r-input
                    class="col-50"
                    floating-label
                    :label="$t('clientes.cliente_form.nombre')"
                    :form="rform"
                    type="text"
                    name="nombre"
                  ></r-input>
                </f7-row>
                <r-input
                  floating-label
                  :label="$t('clientes.cliente_form.eMail')"
                  :form="rform"
                  type="text"
                  name="email"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('clientes.cliente_form.razonSocial')"
                  :form="rform"
                  type="text"
                  name="razonSocial"
                ></r-input>
                  <!-- :readonly="id != undefined" -->
                <r-input
                  floating-label
                  :label="$t('clientes.cliente_form.tipoCliente')"
                  :form="rform"
                  type="select"
                  name="tipoCliente"
                  @change="changeTipoCliente"
                >
                  <option selected disabled></option>
                  <option value="MARCA">Marca</option>
                  <option value="GRUPO_AUTOMOCION">Grupo de automoción</option>
                  <option value="CONCESIONARIO">Concesionario</option>
                </r-input>
                <r-autocomplete
                  v-if="!$refs.rform || !$refs.rform.formData || !$refs.rform.formData.tipoCliente || $refs.rform.formData.tipoCliente != 'MARCA'"
                  :dbAdapter="Cliente"
                  floating-label
                  :label="$t('clientes.cliente_form.clientePadre')"
                  :form="rform"
                  name="idPadre"
                  nameId="idPadre"
                  idProp="id"
                  labelProp="nombre"
                  @change="changePadre"
                  :readonly="readonly || !$refs.rform || !$refs.rform.formData || !$refs.rform.formData.tipoCliente || $refs.rform.formData.tipoCliente == 'MARCA'"
                ></r-autocomplete>
                <f7-row>
                  <r-input
                    floating-label
                    :label="$t('clientes.cliente_form.hora_apertura')"
                    :form="rform"
                    type="datepicker"
                    name="horaApertura"
                    ref="horaApertura"
                    hidden
                    :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
                  ></r-input>
                  <r-timepicker
                    floating-label
                    :label="$t('clientes.cliente_form.hora_apertura')"
                    :form="rform"
                    :readonly="false"
                    class="col-50"
                    name="horaApertura"
                  ></r-timepicker>
                  <r-input
                    floating-label
                    :label="$t('clientes.cliente_form.hora_cierre')"
                    :form="rform"
                    type="datepicker"
                    name="horaCierre"
                    ref="horaCierre"
                    hidden
                    :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
                  ></r-input>
                  <r-timepicker
                    floating-label
                    class="col-50"
                    :label="$t('clientes.cliente_form.hora_cierre')"
                    :form="rform"
                    :readonly="false"
                    name="horaCierre"
                  ></r-timepicker>
                </f7-row>
              </ul>
            </f7-col>
            <f7-col width="100" medium="40">
              <ul>
                <div class="label-group">{{$t('clientes.cliente_form.direccion_inspeccion')}}</div>
                <f7-row>
                  <f7-col width="25">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.codigo_postal')"
                      :form="rform"
                      type="text"
                      name="direccionInspeccion.cp"
                    ></r-input>
                  </f7-col>
                  <f7-col width="75">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.direccion')"
                      :form="rform"
                      type="text"
                      name="direccionInspeccion.direccion"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.poblacion')"
                      :form="rform"
                      type="text"
                      name="direccionInspeccion.poblacion"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.provincia')"
                      :form="rform"
                      type="text"
                      name="direccionInspeccion.provincia"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.pais')"
                      :form="rform"
                      type="text"
                      name="direccionInspeccion.pais"
                    ></r-input>
                  </f7-col>
                </f7-row>
                <div class="label-group">{{$t('clientes.cliente_form.direccion_facturacion')}}</div>
                <f7-row>
                  <f7-col width="25">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.codigo_postal')"
                      :form="rform"
                      type="text"
                      name="direccionFacturacion.cp"
                    ></r-input>
                  </f7-col>
                  <f7-col width="75">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.direccion')"
                      :form="rform"
                      type="text"
                      name="direccionFacturacion.direccion"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.poblacion')"
                      :form="rform"
                      type="text"
                      name="direccionFacturacion.poblacion"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.provincia')"
                      :form="rform"
                      type="text"
                      name="direccionFacturacion.provincia"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.pais')"
                      :form="rform"
                      type="text"
                      name="direccionFacturacion.pais"
                    ></r-input>
                  </f7-col>
                </f7-row>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>











        <f7-block-title>
          ** {{rform.formData.nombre}} - Contactos del cliente
          <f7-link @click="if(!rform.formData.clienteContactos) rform.formData.clienteContactos = []; rform.formData.clienteContactos.push({});">Nuevo contacto</f7-link>
          <f7-card 
            v-for="(clienteContacto,index2) in rform.formData.clienteContactos" :key="'clienteContacto-'+index2"
            class="padding-vertical-half padding-right"
          >
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <r-autocomplete
                      class="col-33"
                      :dbAdapter="ClienteContacto"
                      floating-label
                      :label="$t('perfil.cliente_contacto')"
                      :form="rform"
                      :name="'clienteContactos.' + index2 + '.clienteContacto'"
                      labelProp="nombre"
                      idProp="valor"
                      :source="self => mostrarClienteContacto(self,rform)"
                    ></r-autocomplete>
                    <r-input
                      class="col-33"
                      floating-label
                      :label="$t('perfil.nombre')"
                      :form="rform"
                      :name="'clienteContactos.' + index2 + '.nombre'"
                    ></r-input>
                    <r-files 
                      class="col-33" 
                      :maxFiles="1"
                      :label="$t('perfil.firma')" 
                      :form="rform" 
                      :name="'clienteContactos.' + index2 + '.firma'" 
                    />
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
            <f7-link
              @click="$delete(rform.formData.clienteContactos,index2);"
              class="margin-half"
              style="position:absolute;right:0;top:0;"
              icon-f7="trash"
            ></f7-link>
          </f7-card>
        </f7-block-title>













      <!-- Marcas asociadas -->
      <f7-block-title v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.tipoCliente">
        <div v-if="$refs.rform.formData.tipoCliente == 'MARCA'">
          ** Si no se asocia ninguna marca, el cliente no podrá ver ninguna marca
        </div>
        <div v-else-if="!$refs.rform.formData.idPadre || $refs.rform.formData.idPadre == ''">
          ** Selecciona un Padre para poder añadirle marcas
        </div>
        <div v-else>
          ** Si no se asocia ninguna Marca, este cliente podra ver todas las marcas asociadas a su padre
        </div>
        <f7-card
          class="elevation-3 padding-vertical-half padding-right"
        >
          <f7-card-content>
            <f7-list class="list-form">
                  <r-autocomplete-m2m
                    :dbAdapter="Marca"
                    class="col-100"
                    floating-label
                    :label="$t('clientes.cliente_form.marcas')"
                    :form="rform"
                    name="marcas"
                    labelProp="nombre"
                    type="textarea"
                    :lineBreak="false"
                    idProp=""
                    :filter="rform && rform.formData && rform.formData.idPadre ? {filter: {clienteId: rform.formData.idPadre}} : {}"
                  ></r-autocomplete-m2m>
            </f7-list>
          </f7-card-content>
        </f7-card>
        <!-- {{$t('clientes.cliente_form.marcas')}}
        <f7-link v-if="!rform.readonly && $refs.rform.formData && $refs.rform.formData.tipoCliente && ($refs.rform.formData.tipoCliente == 'MARCA' || ($refs.rform.formData.idPadre && $refs.rform.formData.idPadre != ''))" @click="rform.formData.marcas.push({})">{{$t('clientes.cliente_form.add')}}</f7-link> -->
      </f7-block-title>

      <!-- Configuraciones -->
      <f7-block-title  v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.tipoCliente" style="z-index: 30">
        <div v-if="$refs.rform.formData.tipoCliente == 'MARCA'">
          ** Asocie las configuraciones de la marca, después se podrán personalizar por cada Concesionario
        </div>
        <div v-else-if="!$refs.rform.formData.idPadre || $refs.rform.formData.idPadre == ''">
          ** Selecciona un Padre para poder ver y editar sus configuraciones
        </div>
        {{$t('clientes.cliente_form.configuraciones')}}
        <f7-link v-if="!rform.readonly && rform.formData && rform.formData.tipoCliente && rform.formData.tipoCliente == 'MARCA'" @click="rform.formData.configuraciones.push({}); algunCambioEnConf = true;">{{$t('clientes.cliente_form.add')}}</f7-link>
      </f7-block-title>

      <div v-if="rform && rform.formData && rform.formData.tipoCliente" style="z-index: 30">
        <f7-card
          class="elevation-3 padding-vertical-half padding-right"
          v-for="(item,index) in rform.formData.configuraciones"
          :key="'configuracion-'+index"
          :style="'z-index:'+(rform.formData.configuraciones.length - index)"
        >
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <r-autocomplete
                    class="col-25"
                    :dbAdapter="TipoConfiguracion"
                    floating-label
                    :label="$t('clientes.cliente_form.tipo_configuracion')"
                    :form="rform"
                    :name="'configuraciones.' + index + '.tipoConfiguracion'"
                    labelProp="nombre"
                    idProp="valor"
                    @loaded="setExtraObject($event,index,item)"
                    @change="setExtraObject($event,index)"
                    :readonly="rform.formData.tipoCliente != 'MARCA'"
                  ></r-autocomplete>
                  <r-autocomplete
                    class="col-25"
                    :hidden="rform.formData.configuraciones && rform.formData.configuraciones[index] && (!rform.formData.configuraciones[index].configuracionLinea || (rform.formData.configuraciones[index].configuracionLinea && rform.formData.configuraciones[index].configuracionLinea.conTipoInspeccion === false))"
                    :dbAdapter="TipoInspeccion"
                    floating-label
                    :label="$t('clientes.cliente_form.tipo_inspeccion')"
                    :form="rform"
                    :name="'configuraciones.' + index + '.tipoInspeccion'"
                    labelProp="nombre"
                    idProp="valor"
                    :readonly="rform.formData.tipoCliente != 'MARCA'"
                    @change="algunCambioEnConf = true;"
                  ></r-autocomplete>
                  <div
                    :hidden="rform.formData.configuraciones && rform.formData.configuraciones[index] && rform.formData.configuraciones[index].configuracionLinea && rform.formData.configuraciones[index].configuracionLinea.conTipoInspeccion === true"
                    class="col-25"
                    >
                  </div>
                  <r-autocomplete
                    class="col-25"
                    :dbAdapter="AlcanceConfiguracion"
                    floating-label
                    :label="$t('clientes.cliente_form.alcance')"
                    :form="rform"
                    :name="'configuraciones.' + index + '.alcance'"
                    labelProp="nombre"
                    idProp="valor"
                    :readonly="rform.formData.tipoCliente != 'MARCA'"
                    @change="algunCambioEnConf = true;"
                  ></r-autocomplete>
                  <r-input
                    v-if="rform.formData.configuraciones && rform.formData.configuraciones[index] && rform.formData.configuraciones[index].configuracionLinea && rform.formData.configuraciones[index].configuracionLinea.tipoValorDto.nombre == 'COMBO'"
                    class="col-25"
                    floating-label
                    :label="rform.formData.configuraciones[index].configuracionLinea?rform.formData.configuraciones[index].configuracionLinea.tipoValorDto.etiqueta:$t('clientes.cliente_form.valor')"
                    :form="rform"
                    type="select"
                    :name="'configuraciones.' + index + '.valor'"
                    @change="algunCambioEnConf = true;"
                  >
                    <option selected></option>
                    <option v-for="(valor,confIndexValor) in rform.formData.configuraciones[index].configuracionLinea.tipoValorDto.valoresObj"
                      :key="'configuraciones.' + index + '.valor.' + confIndexValor"
                      :value="valor.valor"
                    >{{valor.nombre}}</option>
                  </r-input>
                  <r-autocomplete
                    v-else-if="rform.formData.configuraciones && rform.formData.configuraciones[index] && rform.formData.configuraciones[index].tipoConfiguracion == 'FORMATO_PEDIDO'"
                    class="col-25"
                    floating-label
                    :dbAdapter="Formato"
                    :label="rform.formData.configuraciones[index].configuracionLinea?rform.formData.configuraciones[index].configuracionLinea.tipoValorDto.etiqueta:$t('clientes.cliente_form.valor')"
                    :form="rform"
                    :name="'configuraciones.' + index + '.valor'"
                    labelProp="nombre"
                    idProp="id"
                    @change="algunCambioEnConf = true;"
                  >
                  </r-autocomplete>
                  <r-input
                    v-else
                    class="col-25"
                    floating-label
                    :label="rform.formData.configuraciones[index].configuracionLinea?rform.formData.configuraciones[index].configuracionLinea.tipoValorDto.etiqueta:$t('clientes.cliente_form.valor')"
                    :form="rform"
                    :name="'configuraciones.' + index + '.valor'"
                    @change="algunCambioEnConf = true;"
                  ></r-input>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-link
            v-if="!rform.readonly && rform.formData && rform.formData.tipoCliente && rform.formData.tipoCliente == 'MARCA'"
            @click="$delete(rform.formData.configuraciones,index); algunCambioEnConf = true;"
            class="margin-half"
            style="position:absolute;right:0;top:0;"
            icon-f7="trash"
          ></f7-link>
        </f7-card>
      </div>
      <!-- Configuraciones -->
      <f7-block-title  v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.tipoCliente">
        {{$t('clientes.cliente_form.configuraciones')}}
        <f7-link style="z-index: 0;" v-if="!rform.readonly && rform.formData && rform.formData.tipoCliente && rform.formData.tipoCliente == 'MARCA'" @click="rform.formData.configuraciones.push({}); algunCambioEnConf = true;">{{$t('clientes.cliente_form.add')}}</f7-link>
      </f7-block-title>


    </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import Cliente from "./../../js/db/Cliente.js";
import TipoConfiguracion from "./../../js/db/TipoConfiguracion.js";
import TipoInspeccion from "./../../js/db/TipoInspeccion.js";
import AlcanceConfiguracion from "./../../js/db/AlcanceConfiguracion.js";
import TipoProcedimiento from "./../../js/db/TipoProcedimiento.js";
import ClienteContacto from "./../../js/db/ClienteContacto.js";
import Documento from "./../../js/db/Documento.js";
import Formato from "./../../js/db/Formato.js";
import Marca from "./../../js/db/Marca.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rAutocomplete,
    rAutocompleteM2m
  },
  data: function() {
    var self = this;
    return {
      Cliente,
      ClienteContacto,
      Documento,
      Formato,
      Marca,
      TipoConfiguracion,
      TipoInspeccion,
      AlcanceConfiguracion,
      TipoProcedimiento,
      algunCambioEnConf: false,
      readonly: !this.editable,
      newImagenSelected : false
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    changeTipoCliente: function(e) {
      var self = this;
      self.$set(self.$refs.rform.formData, 'marcas', []); 
      self.$set(self.$refs.rform.formData, 'idPadre', undefined)
    },
    setExtraObject: function(e,index,item) {
      var self = this;
      if(e.extraObject) {
        self.algunCambioEnConf = true;
        if(e.extraObject.tipoValorDto && e.extraObject.tipoValorDto.valores) {
          e.extraObject.tipoValorDto.valoresObj = JSON.parse(e.extraObject.tipoValorDto.valores);
        }
        self.$refs.rform.formData.configuraciones[index].configuracionLinea = e.extraObject;
      } else if("change" === e.type) {
        console.log(e.type);
      } else {
        if(e.find(opt => opt.valor === item.tipoConfiguracion)) e.find(opt => opt.valor === item.tipoConfiguracion).extraObject
        var tipoConfiguracion = e.find(opt => opt.valor === item.tipoConfiguracion);
        if(tipoConfiguracion && tipoConfiguracion.extraObject && tipoConfiguracion.extraObject.tipoValorDto && tipoConfiguracion.extraObject.tipoValorDto.valores) {
          try {
            tipoConfiguracion.extraObject.tipoValorDto.valoresObj = JSON.parse(tipoConfiguracion.extraObject.tipoValorDto.valores);
          } catch (error) {
            console.log("JSON-ERROR: " + tipoConfiguracion.extraObject.tipoValorDto.valores)
          }
        }
        if(item && tipoConfiguracion && tipoConfiguracion.extraObject) {
          item.configuracionLinea = tipoConfiguracion.extraObject;
        }
        // No sé que hace pero arregla mi problema ----------------------
        self.$refs.rform.formData.cambiarConfiguracionesEnHijos = true;
        self.$refs.rform.formData.cambiarConfiguracionesEnHijos = false;
        // --------------------------------------------------------------

      }
    },
    changePadre: function(e) {
      var self = this;
      var app = self.$f7;
      if (!self.id && self.$refs.rform.formData.idPadre && self.$refs.rform.formData.tipoCliente != 'MARCA') {
        Cliente.getConfiguraciones({data: {idCliente: self.$refs.rform.formData.idPadre}}).then(function(res) {
          self.$set(self.$refs.rform.formData, 'configuraciones', res); 
        }).catch(function(error) {
          if (error.message) {
            app.dialog.alert(error.message);
          } else {
            app.dialog.alert(error);
          }
        })

      }
    },
    loadItem: function(res) {
      var self = this;
      if (!res.marcas || res.marcas == null) {
        self.$set(self.$refs.rform.formData, 'marcas', []);
      }
      if (!res.configuraciones || res.configuraciones == null) {
        self.$set(self.$refs.rform.formData, 'configuraciones', []);
      }
    },
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;

      formData.imagen = Array.isArray(formData.imagen) ? formData.imagen[0] : formData.imagen;
      if (self.algunCambioEnConf && formData.tipoCliente == 'MARCA') {
        app.preloader.hide();
        app.dialog.confirm(
          "¿Desea efectuar estos cambios en los concesionarios asociados a la Marca?" ,
          "Cambios en Concesionarios",
          function() {
            formData.cambiarConfiguracionesEnHijos = true;
            resolve(formData);
          },
          function() {
            formData.cambiarConfiguracionesEnHijos = false;
            resolve(formData);
          }
        );
      } else {
        formData.cambiarConfiguracionesEnHijos = false;
        resolve(formData);
      }

      // Revisar los contactos del cliente para ver si tienen firma de tipo list
      if (formData.clienteContactos) {
        formData.clienteContactos.forEach(function(contacto) {
          if (contacto.firma instanceof Array && contacto.firma.length > 0) {
            contacto.firma = contacto.firma[0];
          }
        });
      } else {
        formData.cambiarContactosEnHijos = false;
        resolve(formData);
      }
    },
    mostrarClienteContacto: function(self,rform) {
      return function(query, render) {
        var results = [];
        var clienteContactos = [];
        if (rform.formData.clienteContactos) {
          for (var index in rform.formData.clienteContactos) {
            var cc = rform.formData.clienteContactos[index];
            if (cc.clienteContacto) {
              clienteContactos.push(cc.clienteContacto)
            }
          }
        }
        for (var i = 0; i < self.items.length; i++) {
          if (
            ( self.items[i][self.searchProp].toLowerCase().indexOf(query.toLowerCase()) >= 0 ) && 
            !clienteContactos.includes(self.items[i]['valor'])) {
              self.items[i]['txtValue'] = self.items[i][self.searchProp];
              results.push(self.items[i]);
            }
        }
        render(results);
      };
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("clientes.cliente_form.cliente_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>