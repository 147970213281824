import DatabaseModel from './DatabaseModel.js';

var Tabla = Object.create(DatabaseModel);

Tabla.model = 'core/tabla';

Tabla.getTabla = function(codigo) {
    var self = this;
    var params = {
        url: self.server_inspecciones + self.prefix + self.model + '/getTabla/' + codigo + '/',
        method: 'GET',
        data: {}
    };
    return self.sendRequest(params, false);
}

export default Tabla;