<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('formatos.formatos')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Formato"
        tableCode="FORMATO"
        filterPosition="PANEL"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="false"
        :controlsWidth="90"
        :searchOnInit="true"
        :mostrarBadgeFilters="false"
      >
        <template v-slot:optionsColumn="{item}">
          <f7-link icon-f7="eye" color="green" :href="`/formatos/view/${item.id}/`" v-tooltip="'Ver'"></f7-link>
          <f7-link icon-f7="square_pencil" color="blue" :href="`/formatos/edit/${item.id}/`" v-tooltip="'Editar'"></f7-link>
        </template>
      </r-dynamic-list>
      <!-- rList Table CARD-->
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import Formato from "./../../js/db/Formato.js";
import rDynamicList from "./../../components/rDynamicList.vue";
import Utils from "./../../js/Utils.js";

export default {
  components: {
    rDynamicList
  },
  data() {
    return {
      Formato,
      Utils,
      rPanelOpenedByBreakpoint: false,
      inspeccion: {}
    };
  },
  computed: {},
  methods: {
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>