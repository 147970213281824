<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('modelos.modelo_form.editar_modelo')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('modelos.modelo_form.modelo')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('modelos.modelo_form.nuevo_modelo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly && id"
          fill
          icon-f7="trash"
          text="Eliminar"
          color="red"
          class="display-flex"
          @click="$refs.rform.deleteItem()"
        ></f7-button>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Modelo"
      :beforeSave="beforeSave"
    >
      <f7-card class="padding-vertical-half padding-right">
        <f7-card-content class="list list-form">
          <ul>
            <r-files 
              :maxFiles="1"
              :form="rform" 
              :name="'imagen'"
              label="Añadir imagen"
              :documentoAdapter="Documento"
            />
            <r-input
              floating-label
              :label="$t('modelos.modelo_form.nombre')"
              :form="rform"
              type="text"
              name="nombre"
            ></r-input>
            <r-autocomplete
              :dbAdapter="Marca"
              floating-label
              :label="$t('coches.coche_form.marca')"
              :form="rform"
              name="marca"
              idProp=""
              labelProp="nombre"
            ></r-autocomplete>
          </ul>
        </f7-card-content>
      </f7-card>

      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rFiles from "./../../components/rFiles.vue";
import Modelo from "./../../js/db/Modelo.js";
import Marca from "./../../js/db/Marca.js";
import Documento from "./../../js/db/Documento.js";

export default {
  components: {
    rForm,
    rInput,
    rAutocomplete,
    rFiles
  },
  data: function() {
    var self = this;
    return {
      Modelo,
      Marca,
      Documento,
      readonly: !this.editable
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var o = Object.assign({}, data);
      o.imagen = Array.isArray(o.imagen) ? o.imagen[0] : o.imagen;
      resolve(o);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("modelos.modelo_form.modelo_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>