<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('formatos.editar')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('formatos.formato')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('formatos.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="bolt"
          text="Edición Avanzada"
          class="display-flex"
          color="red"
          popup-open=".popup-jsonedit"
        ></f7-button>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button color="blue" @click="readonly=false">Editar</f7-button>
          <!-- <f7-button color="red" @click="deleteItem">Eliminar</f7-button> -->
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Formato"
    >
      <f7-card
        v-if="!rform.formData.comprobaciones || rform.formData.comprobaciones.length == 0"
      >
        <f7-card-content>
          {{$t('inspecciones.inspeccion_form.no_comprobaciones')}}
        </f7-card-content>
      </f7-card>

      <f7-card
        v-else
        class="elevation-3 padding-vertical-half padding-right"
        v-for="(item,index) in rform.formData.comprobaciones"
        :key="'comprobacion-'+index"
      >
        <r-comprobacion :comprobacion="item" :name="'comprobaciones.'+index" :form="rform" :desdeFormato="true"></r-comprobacion>
      </f7-card>

      <!-- Popup de edición avanzada del formato -->
      <f7-popup ref="popupJsonedit" class="popup-jsonedit" swipe-to-close tablet-fullscreen>
        <f7-page>
          <f7-navbar title="Edición avanzada de formato">
            <f7-nav-right>
              <f7-button
                v-if="!readonly"
                fill
                icon-f7="floppy_disk"
                text="Guardar"
                class="display-flex"
                @click="$refs.rform.saveItem()"
              ></f7-button>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <v-jsoneditor 
            v-model="rform.formData" 
            height="100%" 
            :plus="true" 
            @error="onError"
          ></v-jsoneditor>

        </f7-page>
      </f7-popup>

    </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import Formato from "./../../js/db/Formato.js";
import rComprobacion from "./../../components/rComprobacion.vue";
import VJsoneditor from 'v-jsoneditor/src/index'

export default {
  components: {
    rForm,
    rInput,
    rComprobacion,
    VJsoneditor
  },
  data: function() {
    var self = this;
    return {
      Formato,
      readonly: !this.editable
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          GrupoFuncional.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("formatos.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>