<template>
  <span :class='"badge color-" + color' v-html="text"></span>
</template>
<style>
span.badge {
  padding: 6px 10px;
}
</style>
<script>
export default {
  data() {
    return {
      self: this
    };
  },
  props: {
    color: {
      required: false,
      type: String
    },
    text: {
      required: true,
      type: String
    }
  }
};
</script>