<template>
  <f7-page page="main-menu">
    <f7-list media-list class="no-margin">
      <f7-list-item
        :title="$f7.data.user.nombre"
        :header="$f7.data.user.username"
        class="padding-vertical"
      >
        <div slot="footer">
          <f7-link href="/perfil/" view=".view-main">Mi perfil</f7-link> |
          <f7-link @click="closeSession()">Cerrar sesión</f7-link>
        </div>
        <img
          v-if="0"
          slot="media"
          src="https://cdn.framework7.io/placeholder/fashion-88x88-3.jpg"
          width="44"
        />
        <f7-icon
          v-else
          slot="media"
          f7="person_crop_circle"
          style="font-size:3rem;"
          class="text-color-gray"
        />
      </f7-list-item>
    </f7-list>

    <f7-toolbar bottom>
      <span class="text-color-gray">{{version}}</span>
    </f7-toolbar>

    <f7-list>
      <f7-list-item link="/" :title="$t('main_menu.inicio')" view=".view-main" panel-close>
        <f7-icon slot="media" f7="house"></f7-icon>
      </f7-list-item>
      <!-- Todos los usuarios -->
      <f7-list-item 
        v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'CLIENTE']}"
        link="/pedidos/" :title="$t('main_menu.pedidos')" view=".view-main" panel-close>
        <f7-icon slot="media" f7="cart"></f7-icon>
      </f7-list-item>
      <f7-list-item
        link="/inspecciones/"
        :title="$t('main_menu.inspecciones')"
        view=".view-main"
        panel-close
      >
        <f7-icon slot="media" f7="checkmark_seal"></f7-icon>
      </f7-list-item>
      <f7-list-item link="/conflictos/" :title="$t('main_menu.conflictos')" view=".view-main" panel-close>
        <f7-icon slot="media" material="warning"></f7-icon>
      </f7-list-item>
      <!-- Fin Todos los usuarios -->
      <!-- Administrativo -->
      <f7-list-item
        v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
        link="/clientes/"
        :title="$t('main_menu.clientes')"
        view=".view-main"
        panel-close
      >
        <f7-icon slot="media" f7="person_2_square_stack"></f7-icon>
      </f7-list-item>
      <f7-list-item 
        v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO','SUPERVISOR']}"
        link="/marcas/" :title="$t('main_menu.marcas')" view=".view-main" panel-close>
        <f7-icon slot="media" f7="tag"></f7-icon>
      </f7-list-item>
      <f7-list-item 
        v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO','SUPERVISOR']}"
        link="/modelos/" :title="$t('main_menu.modelos')" view=".view-main" panel-close>
        <f7-icon slot="media" material="airport_shuttle"></f7-icon>
      </f7-list-item>
      <f7-list-item 
        v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO','SUPERVISOR']}"
        link="/coches/" :title="$t('main_menu.coches')" view=".view-main" panel-close>
        <f7-icon slot="media" material="time_to_leave"></f7-icon>
      </f7-list-item>
      <!-- TODO RRM: Comentado por ahora ya que no esta hecho -->
      <!-- <f7-list-item
        v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
        link="/formatos/"
        :title="$t('main_menu.formatos')"
        view=".view-main"
        panel-close
      >
        <f7-icon slot="media" f7="square_list"></f7-icon>
      </f7-list-item>
      <f7-list-item
        v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
        link="/facturas/"
        :title="$t('main_menu.facturas')"
        view=".view-main"
        panel-close
      >
        <f7-icon slot="media" f7="doc_text"></f7-icon>
      </f7-list-item> -->
      <f7-list-item
        v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
        link="/usuarios/"
        :title="$t('main_menu.usuarios')"
        view=".view-main"
        panel-close
      >
        <f7-icon slot="media" f7="person_2"></f7-icon>
      </f7-list-item>
      <f7-list-item
        v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
        link="/facturas/"
        :title="$t('main_menu.facturas')"
        view=".view-main"
        panel-close
      >
        <f7-icon slot="media" f7="doc_plaintext"></f7-icon>
      </f7-list-item>
      <f7-list-item
        v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO','CLIENTE']}"
        link="/ventas/"
        :title="$t('main_menu.ventas')"
        view=".view-main"
        panel-close
      >
        <f7-icon slot="media" f7="rocket_fill"></f7-icon>
      </f7-list-item>
      <f7-list-item 
        v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
        link="/gruposfuncionales/" :title="$t('main_menu.gruposfuncionales')" view=".view-main" panel-close>
        <f7-icon slot="media" f7="capsule_fill"></f7-icon>
      </f7-list-item>
      <f7-list-item 
        v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
        link="/formatos/" :title="$t('main_menu.formatos')" view=".view-main" panel-close>
        <f7-icon slot="media" f7="doc_plaintext"></f7-icon>
      </f7-list-item>
      <!-- <f7-list-item
        link="/configuracion/"
        :title="$t('main_menu.configuracion')"
        view=".view-main"
        panel-close
      >
        <f7-icon slot="media" f7="gear_alt"></f7-icon>
      </f7-list-item> -->
    </f7-list>

    <div class="block"></div>
  </f7-page>
</template>
<style>
.panel-left.panel-in-breakpoint:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
  content: "";
  z-index: 6000;
}
</style>
<script>
export default {
  data() {
    return {
      version: process.env.BUILDDATE
    };
  },
  methods: {
    closeSession: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm("¿Cerrar sesión?", function() {
        app.methods.removeSession();
        app.views.main.router.navigate("/");
        app.panel.close("left");
      });
    }
  }
};
</script>