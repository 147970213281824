<template>
  <div>
    <div class="title">
      <slot name ="title"></slot>
    </div>
    <slot name="chart"/>
    <div class="filter">
      <slot name="filter"/>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      };
    }, 
    props: {
      data: {
        type: Array,
        default: function() {
          return [];
        }
      },
      nombre: {
        type: String,
        default: 'nombre'
      },
      valor: {
        type: String,
        default: 'valor'
      },
      title: {
        type: String,
        default: ''
      },
      height: {
        type: Number,
        default: 300
      },
      withFilter: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
<style scoped>
  .title {
    text-align: center;
  }
</style>
