import GruposFuncionales from './gruposfuncionales.vue';
import GrupoFuncionalForm from './gruposfuncionales-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/gruposfuncionales/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gruposfuncionales', ['ADMINISTRATIVO'], GruposFuncionales),
        keepAlive: true
    },
    {
        path: '/gruposfuncionales/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gruposfuncionales/new', ['ADMINISTRATIVO'], GrupoFuncionalForm)
    },
    {
        path: '/gruposfuncionales/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gruposfuncionales/edit', ['ADMINISTRATIVO'], GrupoFuncionalForm)
    },
    {
        path: '/gruposfuncionales/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gruposfuncionales/view', ['ADMINISTRATIVO'], GrupoFuncionalForm, { editable: false })
    },
];
export default routes;