import { render, staticRenderFns } from "./formatos.vue?vue&type=template&id=16eb4204&scoped=true"
import script from "./formatos.vue?vue&type=script&lang=js"
export * from "./formatos.vue?vue&type=script&lang=js"
import style0 from "./formatos.vue?vue&type=style&index=0&id=16eb4204&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16eb4204",
  null
  
)

export default component.exports