<template>
  <img 
    v-if="!documento || esImagen" 
    :src="srcImg" @click="Documento.download(documento)">
  <img 
    v-else 
    src="./../static/img/download-from-cloud.png" @click="Documento.download(documento)">  

</template>
<style scoped>
img {
  cursor: pointer;
}
</style>
<script>
import Documento from "./../js/db/Documento.js";

export default {
  name: "r-imagen",
  components: {
  },
  data() {
    return {
      self: this,
      Documento,
      srcImg: '',
      imgExtensions: ['jpg', 'jpeg', 'gif', 'tiff', 'png']
    };
  },
  methods: { 
    loadDocumento: function(documento) {
      var self = this;
      if (documento && documento != null) {
      Documento.getDocumento(documento).then(function(data){
        var blob = new Blob([data]);
        self.srcImg = window.URL.createObjectURL(blob);
      });
    }
    }
  },
  props: {
    documento: {
      required: true
    }
  },
  watch: {
    documento: function(val) {
      this.loadDocumento(val);
    }
  },
  computed: {
    esImagen: function() {
      return this.documento && this.imgExtensions.indexOf(this.documento.extension) > -1;
    }
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;

    this.loadDocumento(self.documento);

  }
};
</script>