<template>
  <r-chart>
    <h3 v-if="title && title!=''" slot="title">{{title}}</h3>
    <v-chart slot="chart" :forceFit="true" :height="height" :data="data">
      <v-tooltip :showTitle="false" :dataKey="nombre + '*' + valor" />
      <v-axis />
      <v-legend :dataKey="nombre" />
      <v-pie :position="valor" :color="nombre" :label="labelConfig"/>
      <v-coord type="theta" />
    </v-chart> 
    <slot name="filter" slot="filter"/>
  </r-chart>
</template>

<script>
  import rChart from "./rChart.vue";
  export default {
    extends: rChart,
    components: {
      rChart,
    },
    data() {
      return {
      };
    },
    props: {
      labelConfig: {
        type: Array,
        default: function() {
          return ['valor', {
            formatter: (val, item) => {
              return item.point.nombre + ': ' + val;
            }
          }];
        }
      }
    }
  };
</script>