<template>
  <f7-popup tablet-fullscreen>
    <f7-page no-toolbar no-navbar no-swipeback login-screen>
      <f7-login-screen-title>
        <img src="static/img/logo.svg" style="width: 250px;" />
      </f7-login-screen-title>
      <f7-list form>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-floating-label">{{$t('usuarios.login.usuario')}}</div>
            <div class="item-input-wrap">
              <input type="text" name="username" placeholder />
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-floating-label">{{$t('usuarios.login.contrasena')}}</div>
            <div class="item-input-wrap">
              <input type="password" name="password" placeholder />
            </div>
          </div>
        </li>
      </f7-list>
      <div class="list">
        <div class="block">
          <button id="login" href="#" class="button button-fill">{{$t('usuarios.login.entrar')}}</button>
        </div>
        <div class="block">
          <a
            href="#"
            @click="olvidePass"
            class="link display-block text-color-gray text-align-center"
          >{{$t('usuarios.login.olvide_mi_contrasena')}}</a>
        </div>
      </div>
    </f7-page>
  </f7-popup>
</template>
<style scoped>
.login-screen-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.md .login-screen-content .login-screen-title {
  margin: 0 auto;
}
</style>
<script>
import Usuario from "./../../js/db/Usuario.js";
export default {
  methods: {
    olvidePass: function(e) {
      var self = this;
      var app = self.$f7;
      app.dialog.alert("Contacte con el Administrativo de SIMECAR para que resetee su contraseña.");
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    var $el = self.$$(self.$el);
    $el.find("#login").click(function() {
      var data = app.form.convertToData($el.find("form"));
      app.preloader.show();
      Usuario.auth(data)
        .then(function(res) {
          app.preloader.hide();
          app.methods.saveSession({
            token: res.access_token
          });
          app.methods.startSession();
        })
        .catch(function(e) {
          app.preloader.hide();
          app.toast
            .create({
              text: e.error_description,
              position: "center",
              closeTimeout: 2000
            })
            .open();
        });
      /*app.request.post(
        app.data.URL_API + "?controller=user&action=login",
        data,
        function(res, status, xhr) {
          app.preloader.hide();
          if (res.success) {
            app.methods.saveSession({
              token: res.token,
              uid: res.uid,
              role: res.role
            });
            app.methods.startSession();
            app.views.main.router.navigate("/");
            app.views.left.router.navigate("/main-menu/");
          } else {
            app.dialog.alert(res.msg, '<i class="material-icons">error</i>');
          }
        },
        function(xhr, status) {
          app.preloader.hide();
          //Error petición login
        },
        "json"
      );*/
    });
    $el.find("input").keypress(function(e) {
      if (e.which == 13) {
        $el.find("#login").click();
      }
    });
  }
};
</script>						