<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('pedidos.pedido_form.editar_pedido')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('pedidos.pedido_form.pedido')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('pedidos.pedido_form.nuevo_pedido')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
<!--         
        <f7-button
          v-if="readonly && rform.formData.estado && rform.formData.estado.estado == 'PDTE_CONFIRMAR'"  
          v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
          fill
          icon-f7="pencil"
          :text="$t('common.editar')"
          class="display-flex"
          color="red accent-4"
          @click.prevent="readonly=false; inspeccionInicial = Object.assign({}, $refs.rform.formData);"
        ></f7-button> -->
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :defaultData="{lineas:[]}"
      :readonly="readonly"
      :dbAdapter="Pedido"
      :documentoAdapter="Documento"
      :beforeSave="beforeSave"
      @loadItem="loadItem"
    >
      <f7-row class="padding-vertical-half padding-right">
        <f7-col width="100" medium="75">
        </f7-col>
        <f7-col width="100" medium="25">
          <f7-button
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
            v-if="readonly && rform.formData.estado && rform.formData.estado.estado == 'PDTE_CONFIRMAR'" 
            fill
            icon-f7="doc_text_fill"
            text="Preparar Cita"
            class="display-flex"
            color="green"
            popup-open=".popup-prepare-pedido"
          ></f7-button>
        </f7-col>
      </f7-row>
      <f7-block-title>
        Cita
      </f7-block-title>
      <f7-card class="padding-vertical-half padding-right" :style="'z-index:'+(rform.formData.lineas.length + 10)">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  v-if="rform.formData.id && rform.formData.id != ''"
                  floating-label
                  :label="$t('pedidos.pedido_form.codigo')"
                  :form="rform"
                  type="text"
                  name="codigo"
                  :readonly="true"
                ></r-input>
                <r-autocomplete
                  ref="cliente"
                  :dbAdapter="Cliente"
                  floating-label
                  :label="$t('pedidos.pedido_form.cliente')"
                  :form="rform"
                  name="cliente"
                  nameId="clienteId"
                  labelProp="nombre"
                  :readonly="rform.formData.id && rform.formData.id != ''"
                  @change="changeCliente($event)"
                  @loaded="if($event.length==1) $refs.cliente.setValue($event[0]);"
                  :shownFields="['horaApertura','horaCierre','direccionInspeccion','nombre','razonSocial','configuraciones']"
                ></r-autocomplete>
                <f7-row>
                  <f7-col width="25">
                    <r-input
                      floating-label
                      :label="$t('pedidos.pedido_form.codigo_postal')"
                      :form="rform"
                      type="text"
                      name="direccion.cp"
                    ></r-input>
                  </f7-col>
                  <f7-col width="75">
                    <r-input
                      floating-label
                      :label="$t('pedidos.pedido_form.direccion')"
                      :form="rform"
                      type="text"
                      name="direccion.direccion"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      floating-label
                      :label="$t('pedidos.pedido_form.poblacion')"
                      :form="rform"
                      type="text"
                      name="direccion.poblacion"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      floating-label
                      :label="$t('pedidos.pedido_form.provincia')"
                      :form="rform"
                      type="text"
                      name="direccion.provincia"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      floating-label
                      :label="$t('pedidos.pedido_form.pais')"
                      :form="rform"
                      type="text"
                      name="direccion.pais"
                    ></r-input>
                  </f7-col>
                </f7-row>
                <r-input
                  v-if="readonly && rform.formData.estado && rform.formData.estado.estado == 'CANCELADO'"
                  floating-label
                  :label="$t('pedidos.pedido_form.motivo_cancel')"
                  :form="rform"
                  type="text"
                  name="estado.mensaje"
                >
                </r-input>
              </ul>
            </f7-col>
            <f7-col width="100" medium="50">
              <ul>
                <!-- Aux para recargar modelo y que se vea imagen -->
                <r-input
                  hidden
                  floating-label
                  :form="rform"
                  type="hidden"
                  name="aux"
                ></r-input>
                <label class="label-group">{{$t('pedidos.pedido_form.fecha_solicitada')}}</label>
                <f7-row no-gap>
                  <r-input
                    class="col-50"
                    floating-label
                    :label="$t('pedidos.pedido_form.dia')"
                    :form="rform"
                    type="datepicker"
                    name="fechaVisitaSolicitadaDesde"
                    ref="fechaVisitaSolicitadaDesde"
                    @change="changeFechaDesde"
                    :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                  ></r-input>
                  <r-timepicker
                    class="col-25"
                    ref="horaDesde"
                    floating-label
                    :label="$t('pedidos.pedido_form.hora_inicio')"
                    :form="rform"
                    :readonly="!rform.formData.fechaVisitaSolicitadaDesde"
                    name="fechaVisitaSolicitadaDesde"
                  ></r-timepicker>
                  <r-input
                    floating-label
                    :form="rform"
                    :hidden="true"
                    type="datepicker"
                    name="fechaVisitaSolicitadaHasta"
                    ref="fechaVisitaSolicitadaHasta"
                    :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
                  ></r-input>
                  <r-timepicker
                    class="col-25"
                    floating-label
                    :label="$t('pedidos.pedido_form.hora_fin')"
                    :form="rform"
                    :readonly="!rform.formData.fechaVisitaSolicitadaHasta"
                    name="fechaVisitaSolicitadaHasta"
                  ></r-timepicker>
                    <!-- outline -->
                  <r-input
                    floating-label
                    class="col-100"
                    :label="$t('pedidos.pedido_form.observaciones')"
                    :form="rform"
                    type="textarea"
                    name="observaciones"
                  >
                  </r-input>
                </f7-row>
                <r-input
                  v-if="readonly"
                  floating-label
                  :label="$t('pedidos.pedido_form.tipo_pedido')"
                  :form="rform"
                  type="select"
                  name="tipoPedido"
                >
                  <option selected disabled></option>
                  <option value="PROGRAMADO">Programado</option>
                  <option value="EN_CAMPO">Realizado en Campo</option>
                </r-input>
                <r-input
                  v-if="readonly"
                  floating-label
                  :label="$t('pedidos.pedido_form.estado')"
                  :form="rform"
                  type="select"
                  name="estado.estado"
                >
                  <option selected disabled></option>
                  <option value="PDTE_CONFIRMAR">Pdte Confirmar</option>
                  <option value="CONFIRMADO">Confirmado</option>
                  <option value="REALIZADO">Realizado</option>
                  <option value="PAGADO">Pagado</option>
                  <option value="CANCELADO">Cancelado</option>
                </r-input>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <f7-block-title v-if="rform.formData.cliente&&rform.formData.cliente.id">
        Vehículos
        <f7-link v-if="!rform.readonly" @click="rform.formData.lineas.push({});$set(rform.formData, 'lineas', rform.formData.lineas) ">Añadir</f7-link>
        <r-form
          class="form-inline"
          v-slot="{rform}"
          ref="rformImport"
          :readonly="false"
          :beforeSave="beforeSaveImport"
          @save="onImport"
          @error="onImportError"
          :documentoAdapter="Documento"
        >
          <r-files :form="rform" name="documento" :maxFiles='1' label="Importar Vehiculos" @loadFile="loadFile" :preview="false"></r-files>
        </r-form>
        <f7-link fill 
          color="blue" 
          class="margin-left"
          icon-f7="arrow_down_doc_fill"
          @click="downloadPlantilla"> Descargar Plantilla</f7-link>
      </f7-block-title>
      <template v-else>
        <f7-block-title>Vehículos</f7-block-title>
        <f7-block>Debe seleccionar el cliente para añadir vehículos en la cita</f7-block>
      </template>

      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
        v-for="(item,index) in rform.formData.lineas"
        :key="'linea-'+index"
        :style="'z-index:'+(rform.formData.lineas.length - index)"
      >
        <f7-card-content>
          <f7-list class="list-form" :style="'z-index:'+(rform.formData.lineas.length - index + 2)">
            <ul>
              <f7-row>
                <f7-col width="100" :medium="readonly? 50 : 100">
                  <r-input
                    floating-label
                    :label="$t('pedidos.pedido_form.matricula')"
                    :form="rform"
                    type="text"
                    :name="'lineas.'+index+'.coche.matricula'"
                    @change="searchCoche"
                    :readonly="item.inicial"
                  ></r-input>
                  <r-input
                    floating-label
                    :label="$t('pedidos.pedido_form.bastidor')"
                    :form="rform"
                    type="text"
                    length="17"
                    :name="'lineas.'+index+'.coche.bastidor'"
                    @change="searchCoche"
                    :readonly="item.inicial"
                  ></r-input>
                  <f7-list-item media-list class="no-line">
                    <r-files 
                      :maxFiles="1"
                      :form="rform" 
                      :name="'lineas.'+index+'.coche.modelo.imagen'"
                      label="Añadir imagen"
                      :readonly="true"
                      slot="media"
                    />
                    <!-- <img
                      slot="media"
                      :src="item&&item.coche&&item.coche.modelo&&item.coche.modelo.imagen? item.coche.modelo.imagen : './../../static/img/car.png'"
                      width="100"
                      style="padding:25px 0;"
                    /> -->
                    <r-autocomplete
                      :dbAdapter="Marca"
                      floating-label
                      :label="$t('pedidos.pedido_form.marca')"
                      :form="rform"
                      :name="'lineas.'+index+'.coche.modelo.marca'"
                      labelProp="nombre"
                      :readonly="item.inicial||!item.coche||!item.coche.matricula||!item.coche.matricula.length||!!item.coche.id"
                    ></r-autocomplete>
                    <r-autocomplete
                      :dbAdapter="Modelo"
                      floating-label
                      :label="$t('pedidos.pedido_form.modelo')"
                      :form="rform"
                      :name="'lineas.'+index+'.coche.modelo'"
                      labelProp="nombre"
                      :readonly="item.inicial||!item.coche||!item.coche.matricula||!item.coche.matricula.length||!!item.coche.id"
                      :shownFields="['nombre','id','marca','imagen']"
                      @change="$set(rform.formData, 'aux', item.coche.modelo.id + item.coche.matricula)"
                      :source="self => mostrarModelos(self, item)"
                    ></r-autocomplete>
                  </f7-list-item>
                  <f7-row>
                    <f7-col
                      class="col-100 medium-30"
                    >
                      <r-input
                        v-if="!readonly && !item.inicial"
                        floating-label
                        :label="$t('pedidos.pedido_form.tipo_inspeccion')"
                        :form="rform"
                        type="select"
                        :name="'lineas.'+index+'.tipoInspeccion'"
                        :readonly="item.inicial"
                        :clearButton="false"
                      >
                        <option selected disabled></option>
                        <option v-for="(option,n) in tiposInspeccion[index]"
                            :key="'-tipoInspeccion-'+n" 
                            :selected="option.valor == rform.formData.lineas[index].tipoInspeccion"
                            :value="option.valor">{{option.nombre}}</option>
                      </r-input>
                      <!-- <r-autocomplete
                        v-if="!readonly && item.inicial"
                        :dbAdapter="TipoInspeccion"
                        floating-label
                        :label="$t('pedidos.pedido_form.tipo_inspeccion')"
                        :form="rform"
                        :name="'lineas.'+index+'.tipoInspeccion'"
                        idProp="valor"
                        labelProp="nombre"
                      ></r-autocomplete> -->
                   </f7-col>
                    <f7-col
                      class="col-100 medium-60"
                    >
                      <r-input
                        floating-label
                        :label="$t('pedidos.pedido_form.observaciones')"
                        :form="rform"
                        type="text"
                        :name="'lineas.'+index+'.observaciones'"
                        :readonly="item.inicial"
                      ></r-input>
                   </f7-col>
                  </f7-row>
                  <f7-row>
                    <r-autocomplete
                      class="col-100 medium-50"
                      v-if="rform.formData.lineas[index].tipoInspeccion == 'CERTIFICACION_M'"
                      :dbAdapter="Cliente"
                      floating-label
                      :label="$t('pedidos.pedido_form.concesionario')"
                      :form="rform"
                      :name="'lineas.'+index+'.concesionario'"
                      nameId="id"
                      labelProp="nombre"
                    ></r-autocomplete>
                    <r-input
                      class="col-100 medium-50"
                      v-if="rform.formData.lineas[index].tipoInspeccion == 'CERTIFICACION_M'"
                      floating-label
                      :label="$t('pedidos.pedido_form.color')"
                      :form="rform"
                      type="text"
                      :name="'lineas.'+index+'.color'"
                    ></r-input>
                    <r-input
                      class="col-100 medium-50"
                      v-if="rform.formData.lineas[index].tipoInspeccion == 'CERTIFICACION_M'"
                      floating-label
                      :label="$t('pedidos.pedido_form.usuario')"
                      :form="rform"
                      type="text"
                      :name="'lineas.'+index+'.usuario'"
                    ></r-input>
                    <r-input
                      class="col-100 medium-50"
                      v-if="rform.formData.lineas[index].tipoInspeccion == 'CERTIFICACION_M'"
                      floating-label
                      :label="$t('pedidos.pedido_form.comision')"
                      :form="rform"
                      type="text"
                      :name="'lineas.'+index+'.comision'"
                    ></r-input>
                    <!-- <r-input
                      class="col-100 medium-50"
                      v-if="rform.formData.lineas[index].tipoInspeccion == 'CERTIFICACION_M'"
                      floating-label
                      :label="$t('pedidos.pedido_form.kilometros')"
                      :form="rform"
                      type="text"
                      :name="'lineas.'+index+'.kilometros'"
                    ></r-input> -->
                    <r-input
                      class="col-100 medium-50"
                      v-if="rform.formData.lineas[index].tipoInspeccion == 'CERTIFICACION_M'"
                      floating-label
                      :label="$t('pedidos.pedido_form.centro_porsche')"
                      :form="rform"
                      type="text"
                      :name="'lineas.'+index+'.centroPorsche'"
                    ></r-input>
                  </f7-row>
                </f7-col>
                <f7-col v-if="readonly" width="100" medium="50">
                  <r-input
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.pedido_form.estado')"
                    :form="rform"
                    type="select"
                    :name="'lineas.'+index+'.estado.estado'"
                  >
                    <option selected disabled></option>
                    <option value="PDTE_CONFIRMAR">Pdte Confirmar</option>
                    <option value="CONFIRMADO">Confirmado</option>
                    <option value="REALIZADO">Realizado</option>
                    <option value="PAGADO">Pagado</option>
                    <option value="CANCELADO">Cancelado</option>
                  </r-input>
                  <r-autocomplete
                    :dbAdapter="TipoInspeccion"
                    floating-label
                    :label="$t('pedidos.pedido_form.tipo_inspeccion')"
                    :form="rform"
                    :name="'lineas.'+index+'.tipoInspeccion'"
                    idProp="valor"
                    labelProp="nombre"
                    :readonly="true"
                  ></r-autocomplete>
                  <r-input
                    v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
                    floating-label
                    :label="$t('pedidos.pedido_form.minutos_estimados')"
                    :form="rform"
                    type="text"
                    :name="'lineas.'+index+'.horasEstimadas'"
                    :readonly="true"
                  ></r-input>
                  <r-input
                    v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
                    floating-label
                    :label="$t('pedidos.pedido_form.importe')"
                    :form="rform"
                    type="text"
                    :name="'lineas.'+index+'.importe'"
                    :readonly="true"
                  ></r-input>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
      
          <!-- Documentos aportados por el cliente -->
          <div v-if="comprobarConfiguracion('DOCUMENTACION_CLIENTE','true')" >
            <br/>
            <f7-block-title>{{$t('pedidos.pedido_form.documentacionAportada')}}</f7-block-title>
            <f7-card class="elevation-3 padding-vertical-half padding-right" :style="'z-index:'+(rform.formData.lineas.length - index + 1)">
              <f7-card-content>
                <f7-row no-gap class="col-100">
                  <r-files class="col-100" :form="rform" :name="'lineas.'+index+'.coche.documentacionAportada'" :label="$t('common.addDoc')" :preview="true" :fileExtensions="getValorConfiguracion('DOCUMENTACION_CLIENTE_EXTENSIONES')"></r-files>
                </f7-row>
              </f7-card-content>
            </f7-card>
          </div>
          <!-- Fin documentos aportados por el cliente -->

        </f7-card-content>
        <f7-link
          v-if="!rform.readonly"
          @click="deleteLinea(index)"
          class="margin-half"
          style="position:absolute;right:0;top:0;"
          icon-f7="trash"
        ></f7-link>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
    <f7-popup ref="popupPedido" class="popup-prepare-pedido" swipe-to-close>
      <f7-page>
        <f7-navbar title="Preparar Cita">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
      <r-form
        ref="rformpedido"
        v-slot="{rform}"
        :itemId="null"
        @save="onPreparePedido"
        :readonly="false"
        :dbAdapter="Inspeccion"
        :beforeSave="beforeSavePrepare"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <f7-col width="100" medium="100">
                  <r-autocomplete
                    :dbAdapter="Inspector"
                    floating-label
                    :label="$t('pedidos.pedido_form.inspector')"
                    :form="rform"
                    name="inspector"
                    nameId="inspector"
                    idProp="username"
                    labelProp="nombre"
                  ></r-autocomplete>
                  <r-input
                    floating-label
                    :label="$t('pedidos.pedido_form.fecha_visita')"
                    :form="rform"
                    type="datepicker"
                    name="fechaVisitaEfectiva"
                    ref="fechaVisitaEfectiva"
                    :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                  ></r-input>
                  <r-timepicker
                    floating-label
                    :label="$t('pedidos.pedido_form.hora')"
                    :form="rform"
                    :readonly="!rform.formData.fechaVisitaEfectiva"
                    name="fechaVisitaEfectiva"
                  ></r-timepicker>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
      </r-form>
      <f7-button
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rformpedido.saveItem()"
        ></f7-button>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>





  </f7-page>
</template>
<style scoped>
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
.form-inline {
  display: inline-flex;
  margin-top: 0;
  margin-bottom: 0;
}
</style>
<style>
  div.errores_importacion {
    overflow-x: auto;
    overflow-y: auto;
  }
</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rFiles from "./../../components/rFiles.vue";
import Pedido from "./../../js/db/Pedido.js";
import Cliente from "./../../js/db/Cliente.js";
import Marca from "./../../js/db/Marca.js";
import Modelo from "./../../js/db/Modelo.js";
import Coche from "./../../js/db/Coche.js";
import Inspeccion from "./../../js/db/Inspeccion.js";
import Inspector from "./../../js/db/Inspector.js";
import Documento from "./../../js/db/Documento.js";
import Formato from "./../../js/db/Formato.js";
import TipoInspeccion from "./../../js/db/TipoInspeccion.js";

export default {
  components: {
    rForm,
    rInput,
    rAutocomplete,
    rTimepicker,
    rFiles
  },
  data: function() {
    return {
      Pedido,
      Cliente,
      Marca,
      Modelo,
      Coche,
      Inspeccion,
      Inspector,
      Formato,
      Documento,
      TipoInspeccion,
      readonly: !this.editable,
      pedidoSelected: undefined,
      tiposInspeccion: []
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    downloadPlantilla: function() {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Pedido.downloadPlantilla().finally(_=>app.preloader.hide());
    },
    beforeSaveImport: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      if(data) {
        resolve(data.documento);
      } else {
        reject("No se ha indicado ningún documento para importar.")
      }
    },
    onImport: function (res) {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      if(res) {
        if(Array.isArray(res.documento)) {
          res = res.documento[0];
        } else {
          res = res.documento;
        }
        res.camposPersonalizados = {};
        res.camposPersonalizados.idCliente = self.$refs.rform.formData.cliente.id;
      } else {
        reject("No se ha indicado ningún documento para importar.")
      }
      Pedido.importalineas({data: res})
        .then(function(res) {
          self.$refs.rformImport.clear();
          self.tiposInspeccion = [];
          var lineasOK = [];
          var lineasKO = [];
          res.list.forEach(l => {
            if(l.camposPersonalizados) {
              self.tiposInspeccion.push(l.camposPersonalizados.tiposInspeccion);
            }
            if(!l.coche.matricula || l.coche.matricula == '') {
              l.coche.matricula = "-";
            }
            if(!l.coche.bastidor || l.coche.bastidor == '') {
              l.coche.bastidor = "-";
            }
            if(l.camposPersonalizados && l.camposPersonalizados.ERROR) {
              lineasKO.push(l);
            } else {
              lineasOK.push(l);
            }
          });
          self.$set(self.$refs.rform.formData, 'lineas', lineasOK); 
          if(lineasKO.length > 0) {
            var msg = '<div class="popup errores_importacion"><div class="right"><!-- Link to close popup --><a class="link popup-close">Cerrar</a></div><div class="block" >';
            lineasKO.forEach(l => {msg += l.camposPersonalizados.ERROR + '<br/>';});
            msg += '</div></div>'
                        // Create dynamic Popup
            var dynamicPopup = app.popup.create({
              content: msg,
              // Events
              on: {
                open: function (popup) {
                  console.log('Popup open');
                },
                opened: function (popup) {
                  console.log('Popup opened');
                },
              }
            });
            dynamicPopup.open();
          }
        }).catch(function(e) {
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
        }).finally(function(e) {
          app.preloader.hide();
        });
    },
    onImportError: function (e) {
      var self = this;
      var app = self.$f7;
      self.$refs.rformImport.clear();
      //self.$refs.rlist.refresh();
    },
    loadFile: function () {
      var self = this;
      self.$refs.rformImport.saveItem();
    },
    deleteLinea: function(index) {
      var self = this;
      self.$delete(self.$refs.rform.formData.lineas,index);
      self.$delete(self.tiposInspeccion,index);
    },
    changeFechaDesde: function(e) {
      var self = this;
      var fDesde = self.$refs.rform && self.$refs.rform.formData ? self.$refs.rform.formData.fechaVisitaSolicitadaDesde : undefined;
      var fHasta = self.$refs.rform && self.$refs.rform.formData ? self.$refs.rform.formData.fechaVisitaSolicitadaHasta : undefined;
      if (fDesde) {
        var horaCierre = new Date(self.$refs.rform.formData.cliente.horaCierre);
        var newDate = new Date(fDesde);
        if (fHasta) {
          try {
            if(typeof fHasta == 'string') {fHasta = new Date(fHasta);}
            newDate.setHours(fHasta.getHours());
            newDate.setMinutes(fHasta.getMinutes());
          } catch (e) {
            console.log(e);
          }
        } else if (horaCierre) {
          newDate.setHours(horaCierre.getHours());
          newDate.setMinutes(horaCierre.getMinutes());
        }
        self.$set(self.$refs.rform.formData, 'fechaVisitaSolicitadaHasta', newDate);
      }
    },
    onPreparePedido: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.loadItem(self.pedidoSelected.id);
      self.$refs.rformpedido.clear();
      if (self.$refs.popupPedido) {
        self.$refs.popupPedido.close(true);
      }
      app.toast
        .create({
          text: self.$t("pedidos.pedido_form.pedido_preparado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    changeCliente: function(event) {
      var self = this;
      // Buscar en configuraciones del cliente la que tiene nombre CITA_DIRECCION_POR_DEFECTO
      var cliente = self.$refs.rform.formData.cliente;
      var configuraciones = cliente?cliente.configuraciones:undefined;
      var configuracion = configuraciones?configuraciones.find(c => c.tipoConfiguracion == 'CITA_DIRECCION_POR_DEFECTO'):undefined;
      let direccionpordefecto = configuracion? configuracion.valor : undefined;
      if (!self.$refs.rform.formData.direccion && direccionpordefecto != "false") {
        self.$set(self.$refs.rform.formData, 'direccion', event.direccionInspeccion);
      }
      var fDesde = self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.fechaVisitaSolicitadaDesde ? self.$refs.rform.formData.fechaVisitaSolicitadaDesde : undefined;
      if (self.$refs.rform.formData.cliente 
          && event.id
          && !fDesde) {
        var horaApertura = new Date(self.$refs.rform.formData.cliente.horaApertura);
        var horaCierre = new Date(self.$refs.rform.formData.cliente.horaCierre);
        if (horaApertura && horaCierre) {
          var today = new Date();
          self.$set(self.$refs.rform.formData, 'fechaVisitaSolicitadaDesde', new Date(today.getFullYear(), today.getMonth(), today.getDate(), horaApertura.getHours(), horaApertura.getMinutes(), 0))
          self.$set(self.$refs.rform.formData, 'fechaVisitaSolicitadaHasta', new Date(today.getFullYear(), today.getMonth(), today.getDate(), horaCierre.getHours(), horaCierre.getMinutes(), 0))
        }
      }
    },
    loadItem: function(res) {
      var self = this;
      if (res) {
        for (let index = 0; index < res.lineas.length; index++) {
          self.$set(self.$refs.rform.formData.lineas[index], 'inicial', true);
        }
      }
      self.pedidoSelected = res;
    },
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var o = Object.assign({}, data);
      o.clienteId = o.cliente.id;
      delete o.cliente;
      resolve(o);
    },
    beforeSavePrepare: function(data, resolve, reject) {
      var self = this;
      var o = Object.assign({}, data);
      o.pedidoId = self.pedidoSelected.id;
      resolve(o);
    },
    searchCoche: function(e) {
      // Como se está llegando aquí desde diferentes elementos, solo nos quedaremos con el que tenga nombre.
      if(!(e instanceof Event)) {
        console.log("evento: " + e);
        return;
      }
      var self = this;
      if (!self.$refs.rform.formData.cliente || !self.$refs.rform.formData.cliente.id) {
        return;
      }
      var aux = e.target.name.split(".");
      var prop = aux.pop();
      var val = e.target.value;
      aux.pop();
      var linea = aux.pop();
      var params = { clienteId: self.$refs.rform.formData.cliente.id };
      params[prop] = val;
      self.$set(
          self.tiposInspeccion,
          linea,
          []
        );
      Coche.getFirstWithInspectionType(params).then(function(res) {
        // Si en la respuesta nos llegan avisos, los mostramos.
        if(res.dto.avisos) {
            self.$f7.dialog.confirm(res.dto.avisos.join("<br/>"),"Aviso:",function() {self.procesarRespuesta(res,linea);},function() {self.procesarKO(linea);});
        } else {
          self.procesarRespuesta(res,linea);
        }
        
      }).catch(function(error) {
        var app = self.$f7;
        app.dialog.alert(error.message);
      });
    },
    procesarRespuesta: function(res,linea) {
      var self = this;
      self.$set(
        self.tiposInspeccion,
        linea,
        res.dto.tiposInspeccion
      );
      if (res.dto.matricula) {
        self.$set(
          self.$refs.rform.formData["lineas"][linea],
          "coche",
          res.dto
        );
      }
      if (res.dto.tipoInspeccionCalculada) {
        self.$set(
          self.$refs.rform.formData["lineas"][linea],
          "tipoInspeccion",
          res.dto.tipoInspeccionCalculada.valor
        );
      } else if (res.dto.tiposInspeccion != null && res.dto.tiposInspeccion.length == 1) {
        self.$set(
          self.$refs.rform.formData["lineas"][linea],
          "tipoInspeccion",
          res.dto.tiposInspeccion[0].valor
        );
      }
    },
    procesarKO: function(linea) {
      var self = this;
      self.$refs.rform.formData["lineas"][linea]["coche"]["matricula"] = "";
    },
    comprobarConfiguracion: function (configuracion,valor) {
      if(valor === undefined) {
        return "true";
      }
      var self = this;
      var app = self.$f7;
      // Si no le pasamos una configuración para buscar, devolvemos false
      if (!configuracion) {
        return false;
      }
      // Miramos a ver si hay cliente asociado a la inspección
      if (self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.cliente) {
        // Si hay cliente, miramos a ver si aún no tenemos sus configuraciones cargadas y las cargamos
        if(!self.$refs.rform.formData.configuraciones) {
          Cliente.getConfiguraciones({data: {idCliente: self.$refs.rform.formData.cliente.id}}).then(function(res) {
            self.$set(self.$refs.rform.formData, 'configuraciones', res); 
          }).catch(function(error) {
            if (error.message) {
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
          });
        }
        // en este punto tenemos que tener las configuraciones del cliente de la inspección cargadas en el formulario.
        if(self.$refs.rform.formData.configuraciones && self.$refs.rform.formData.configuraciones.find(c => c.tipoConfiguracion == configuracion && c.valor == valor)) {
          return true;
        } else {
          return false;
        }
          
      } else {
        return false;
      }
    },

    getValorConfiguracion: function (configuracion) {
      var self = this;
      var app = self.$f7;
      
      // Si no existe la configuración, devolvemos cadena vacía
      if (!configuracion) {
        return "";
      }
      // Miramos a ver si hay cliente asociado a la inspección
      if (self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.cliente) {
        // Si hay cliente, miramos a ver si aún no tenemos sus configuraciones cargadas y las cargamos
        if(!self.$refs.rform.formData.configuraciones) {
          Cliente.getConfiguraciones({data: {idCliente: self.$refs.rform.formData.cliente.id}}).then(function(res) {
            self.$set(self.$refs.rform.formData, 'configuraciones', res); 
          }).catch(function(error) {
            if (error.message) {
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
          });
        }
        // en este punto tenemos que tener las configuraciones del cliente de la inspección cargadas en el formulario.
        if(self.$refs.rform.formData.configuraciones && self.$refs.rform.formData.configuraciones.find(c => c.tipoConfiguracion == configuracion)) {
          return self.$refs.rform.formData.configuraciones.find(c => c.tipoConfiguracion == configuracion).valor;
        } else {
          return "";
        }
          
      } else {
        return "";
      }
    },
    mostrarModelos: function(self, item) {
      return function(query, render) {
        var results = [];
        for (var i = 0; i < self.items.length; i++) {
          if (( self.items[i][self.searchProp].toLowerCase().indexOf(query.toLowerCase()) >= 0 )&&(item.coche.modelo&&item.coche.modelo.marca&&item.coche.modelo.marca.id&&self.items[i].marca.id==item.coche.modelo.marca.id)) {
            self.items[i]['txtValue'] = self.items[i][self.searchProp];
            results.push(self.items[i]);
          }
        }
        render(results);
      };
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.pedidoSelected = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("pedidos.pedido_form.pedido_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>