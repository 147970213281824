import Error403 from './../403.vue';

var checkAuthPage = function(routeTo, routeFrom, resolve, reject) {
    var self = this;
    if (self.app.methods.checkAuth()) {
        resolve();
    } else {
        reject();
        self.app.views.main.router.navigate("/login/");
    }
};
var checkPermission = function(page, Comp) {
    return function(routeTo, routeFrom, resolve, reject) {
        var self = this;
        if (self.app.methods.checkPermission(page)) {
            resolve({
                component: Comp
            });
        } else {
            resolve({ component: Error403 });
        }
    }
};

var routes = [
    /*{
    	path: '/settings/',
    	beforeEnter: checkAuthPage,
    	async: checkPermission('settings', Settings)
    },
    {
    	path: '/privacy/',
    	beforeEnter: checkAuthPage,
    	async: checkPermission('privacy', Privacy)
    },
    {
    	path: '/eula/',
    	beforeEnter: checkAuthPage,
    	async: checkPermission('eula', Eula)
    }*/
];
export default routes;