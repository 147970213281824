<template>
  <f7-page :page-content="false" @page:beforeremove="onPageBeforeRemove">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('pedidos.pedidos.pedidos')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-list
        ref="rlist"
        slot="default"
        :dbAdapter="Pedido"
        sort="fechaVisitaSolicitadaDesde"
        sortDir="DESC"
        :tableMode="true"
        :virtualListParams="{height:32}"
        class="card full-height data-table"
        classContent="card-content full-height"
      >
        <template v-slot:before="{rlist}">
          <f7-card-header>
            <div class="data-table-header" v-if="Object.keys(rlist.selected).length==0">
              <div class="data-table-links">
                <a 
                v-roles="{route: $f7route.url, roles: ['CLIENTE', 'ADMINISTRATIVO']}"
                class="link" href="/pedidos/new/">Nueva Cita</a>
              </div>
              <div class="data-table-actions">
                <f7-link small @click="rlist.refresh()" icon-f7="arrow_counterclockwise"></f7-link>
                <f7-link small @click="rlist.exportExcel('Citas.xlsx')" icon-f7="doc_text_fill"  v-tooltip="'Exportar a Excel'"></f7-link>
                <f7-link
                  small
                  @click="$refs.rpanel.toggle()"
                  icon-f7="line_horizontal_3_decrease"
                  v-show="!rPanelOpenedByBreakpoint"
                ></f7-link>
              </div>
            </div>
            <div class="data-table-header-selected display-flex" v-else>
              <!-- Selected table title -->
              <div class="data-table-title-selected">
                <span
                  class="data-table-selected-count"
                >{{$tc('rlist.n_registros_seleccionados', Object.keys(rlist.selected).length, {count:Object.keys(rlist.selected).length}) }}</span>
              </div>
              <!-- Selected table actions -->
              <div class="data-table-actions">
                <!-- <f7-link icon-f7="trash"></f7-link> -->
              </div>
            </div>
          </f7-card-header>
        </template>

        <template v-slot:header="{rlist}">
          <thead class="thead-sticky">
            <tr>
              <th class="checkbox-cell" style="width: 2rem;">
                <label class="checkbox">
                  <input type="checkbox" @change="rlist.toggleSelectAll($event.target.checked)" />
                  <i class="icon-checkbox"></i>
                </label>
              </th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('codigo')"
                :class="rlist.sortableClasses('codigo')"
              >{{$t('pedidos.pedido_form.codigo')}}</th>
              <th>{{$t('pedidos.pedido_form.cliente')}}</th>
              <th>{{$t('pedidos.pedido_form.estado')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('creationDate')"
                :class="rlist.sortableClasses('creationDate')"
              >{{$t('pedidos.pedido_form.fecha_creacion')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('fechaVisitaSolicitadaDesde')"
                :class="rlist.sortableClasses('fechaVisitaSolicitadaDesde')"
              >{{$t('pedidos.pedido_form.fecha_solicitada')}}</th>
              <th>{{$t('pedidos.pedido_form.vehiculos')}}</th>
              <th style="width:7em;" class="label-cell text-align-center right-column-sticky">
                <f7-icon f7="gear_alt_fill"></f7-icon>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:default="{vlData,rlist}">
          <tr v-for="(item) in vlData.items" :key="'pedido-'+item.id">
            <th class="checkbox-cell">
              <label class="checkbox">
                <input
                  type="checkbox"
                  :checked="item.id in rlist.selected"
                  @change="rlist.toggleSelect(item, $event.target.checked)"
                />
                <i class="icon-checkbox"></i>
              </label>
            </th>
            <td class="label-cell">{{item.codigo}}</td>
            <td class>{{item.cliente.nombre}}</td>
            <td v-html="getEstadoBadge(item.estado.estado)"></td>
            <td
              class
            >{{item.creationDate||'-' | dateParse | dateFormat('DD/MM/YYYY')}}</td>
            <td
              class
            >{{item.fechaVisitaSolicitadaDesde||'-' | dateParse | dateFormat('DD/MM/YYYY')}}</td>
            <td class>{{item.lineas.length}}</td>
            <td class="text-align-right right-column-sticky">
              <f7-link icon-f7="eye" color="green" :href="`/pedidos/view/${item.id}/`" v-tooltip="'Ver Cita'"></f7-link>
              <f7-link 
                v-if="item.estado.estado=='PDTE_CONFIRMAR'"
                v-roles="{route: $f7route.url, roles: ['CLIENTE','ADMINISTRATIVO']}"
                icon-f7="square_pencil" color="blue" :href="`/pedidos/edit/${item.id}/`" v-tooltip="'Editar Cita'"></f7-link>
              <f7-link 
                v-roles="{route: $f7route.url, roles: ['CLIENTE']}"
                v-if="item.estado.estado=='PDTE_CONFIRMAR'"
                icon-f7="clear" 
                color="red" 
                @click="pedidoSelected=item" 
                popup-open=".popup-cancel-pedido" 
                v-tooltip="'Cancelar Cita'"></f7-link>
              <f7-link 
                v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
                v-if="item.estado.estado=='PDTE_CONFIRMAR' || item.estado.estado=='CONFIRMADO'"
                icon-f7="clear" 
                color="red" 
                @click="pedidoSelected=item" 
                popup-open=".popup-cancel-pedido" 
                v-tooltip="'Cancelar Cita'"></f7-link>
              <f7-link 
                v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
                v-if="item.estado.estado == 'PDTE_CONFIRMAR'" icon-f7="doc_text_fill" color="green" 
                popup-open=".popup-prepare-pedido" v-tooltip="'Preparar Cita'" @click="pedidoSelected=item">
              </f7-link>
            </td>
          </tr>
        </template>
        <template v-slot:afterInner="{rlist}">
          <rPanel
            ref="rpanel"
            side="right"
            @openedByBreakpoint="rPanelOpenedByBreakpoint=true"
            @closedByBreakpoint="rPanelOpenedByBreakpoint=false"
            v-slot="{rpanel}"
          >
            <r-form @submit="rlist.setFilter($event); rpanel.close();">
              <template v-slot:before="{rform}">
                <f7-toolbar>
                  <div>Filtro</div>
                  <f7-link icon-f7="bin_xmark" @click="rform.clear();"></f7-link>
                </f7-toolbar>
              </template>
              <template v-slot="{rform}">
                <f7-list no-hairlines-md>
                  <template v-slot:list>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('pedidos.pedido_form.codigo')"
                      :form="rform"
                      type="text"
                      name="codigo"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('pedidos.pedido_form.matricula')"
                      :form="rform"
                      type="text"
                      name="matricula"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('pedidos.pedido_form.bastidor')"
                      :form="rform"
                      type="text"
                      name="bastidor"
                    ></r-input>
                    <r-autocomplete
                      :dbAdapter="Cliente"
                      floating-label
                      :label="$t('pedidos.pedido_form.cliente')"
                      :form="rform"
                      name="clienteId"
                      idProp="id"
                      labelProp="nombre"
                      v-show="filtroCliente"
                      @loaded="if($event.length==1) filtroCliente=false;"
                    ></r-autocomplete>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('pedidos.pedido_form.cif')"
                      :form="rform"
                      type="text"
                      v-show="filtroCliente"
                      name="cifCliente"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('pedidos.pedido_form.estado')"
                      :form="rform"
                      type="select"
                      name="estado"
                    >
                      <option selected></option>
                      <option value="PDTE_CONFIRMAR">Pte. confirmar</option>
                      <option value="CONFIRMADO">Confirmado</option>
                      <option value="REALIZADO">Realizado</option>
                      <option value="PAGADO">Pagado</option>
                      <option value="CANCELADO">Cancelado</option>
                    </r-input>
                    <li class="label-group"><label>{{$t('pedidos.pedido_form.fecha_creacion')}}</label></li>
                    <r-input
                      floating-label
                      :label="$t('pedidos.pedido_form.desde')"
                      :form="rform"
                      type="datepicker"
                      name="fechaCreacionDesde"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('pedidos.pedido_form.hasta')"
                      :form="rform"
                      type="datepicker"
                      name="fechaCreacionHasta"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                    <li class="label-group"><label>{{$t('pedidos.pedido_form.fecha_solicitada')}}</label></li>
                    <r-input
                      floating-label
                      :label="$t('pedidos.pedido_form.desde')"
                      :form="rform"
                      type="datepicker"
                      name="fechaVisitaSolicitadaDesde"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('pedidos.pedido_form.hasta')"
                      :form="rform"
                      type="datepicker"
                      name="fechaVisitaSolicitadaHasta"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                    <f7-button type="submit" class="margin-vertical" icon-f7="search">Buscar</f7-button>
                    <pre style="display:none;">{{rform.formData}}</pre>
                  </template>
                </f7-list>
              </template>
            </r-form>
          </rPanel>
        </template>
        <template v-slot:after="{rlist}">
          <f7-card-footer>
            <span>
              <b>{{$tc('rlist.n_registros', rlist.count, {count:rlist.count})}}</b>
            </span>
          </f7-card-footer>
        </template>
      </r-list>
    </f7-page-content>
    <f7-popup ref="popupPedido" class="popup-prepare-pedido" swipe-to-close>
      <f7-page>
        <f7-navbar title="Preparar Cita">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
      <r-form
        ref="rform"
        v-slot="{rform}"
        :itemId="null"
        @save="onSave"
        :readonly="false"
        :dbAdapter="Inspeccion"
        :beforeSave="beforeSave"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <f7-col width="100" medium="100">
                  <r-autocomplete
                    :dbAdapter="Inspector"
                    floating-label
                    :label="$t('pedidos.pedido_form.inspector')"
                    :form="rform"
                    name="inspector"
                    nameId="inspector"
                    idProp="username"
                    labelProp="nombre"
                    :filter="{filter:{activo: true}}"
                  ></r-autocomplete>
                  <r-input
                    floating-label
                    :label="$t('pedidos.pedido_form.fecha_visita')"
                    :form="rform"
                    type="datepicker"
                    name="fechaVisitaEfectiva"
                    ref="fechaVisitaEfectiva"
                    :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                  ></r-input>
                  <r-timepicker
                    floating-label
                    :label="$t('pedidos.pedido_form.hora')"
                    :form="rform"
                    :readonly="!rform.formData.fechaVisitaEfectiva"
                    name="fechaVisitaEfectiva"
                  ></r-timepicker>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
      </r-form>
      <f7-button
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
    <f7-popup ref="popupCancelPedido" class="popup-cancel-pedido" swipe-to-close>
      <f7-page>
        <f7-navbar title="Cancelar Cita">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
      <f7-card-content>
        <f7-list class="list-form">
          <ul>
            <f7-row>
              <f7-col width="100" medium="100">
                <input
                  maxlength="256"
                  floating-label
                  :placeholder="$t('pedidos.pedido_form.motivo_cancel')"
                  type="text"
                  name="motivoCancel"
                  ref="motivoCancel"
                  class="padding-left"
                >
              </f7-col>
            </f7-row>
          </ul>
        </f7-list>
      </f7-card-content>
      <f7-button
          fill
          icon-f7="clear"
          text="Cancelar"
          class="display-flex"
          color="red"
          @click="cancelPedido()"
        ></f7-button>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rList from "./../../components/rList.vue";
import rPanel from "./../../components/rPanel.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Pedido from "./../../js/db/Pedido.js";
import Cliente from "./../../js/db/Cliente.js";
import Inspector from "./../../js/db/Inspector.js";
import Formato from "./../../js/db/Formato.js";
import Inspeccion from "./../../js/db/Inspeccion.js";
export default {
  components: {
    rList,
    rPanel,
    rForm,
    rInput,
    rAutocomplete,
    rTimepicker
  },
  data() {
    return {
      Pedido,
      Cliente,
      Inspector,
      Inspeccion,
      Formato,
      rPanelOpenedByBreakpoint: false,
      filtroCliente: true,
      pedidoSelected: null,
      inspectores: []
    };
  },
  computed: {},
  methods: {
    cancelPedido: function() {
      var self = this;
      var app = self.$f7;
      var id = self.pedidoSelected.id
      var mensaje = self.$refs.motivoCancel.value;
      if (!mensaje || mensaje == '') {
        app.dialog.alert('Debe introducir un motivo.');
        return;
      }
      self.$f7.dialog.confirm(
        "¿Esta seguro que desea cancelar la cita seleccionada?",
        "Cancelar",
        function() {
          Pedido.delete({itemId: id, data: {id: id, mensaje: mensaje}}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: 'Cita cancelada',
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
                self.$refs.motivoCancel.value = '';
                if (self.$refs.popupCancelPedido) {
                  self.$refs.popupCancelPedido.close(true);
                }
                self.$refs.rlist.refresh();
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var o = Object.assign({}, data);
      o.pedidoId = self.pedidoSelected.id;
      resolve(o);
    },
    getEstadoBadge: function(estado) {
      switch (estado) {
        case "PDTE_CONFIRMAR":
          var texto = "Pte. confirmar";
          var color = "orange";
          break;
        case "CONFIRMADO":
          var texto = "Confirmado";
          var color = "green";
          break;
        case "REALIZADO":
          var texto = "Realizado";
          var color = "blue";
          break;
        case "PAGADO":
          var texto = "Pagado";
          var color = "gray";
          break;
        case "CANCELADO":
          var texto = "Cancelado";
          var color = "red";
          break;
        case "COPIA_ORIGINAL":
          var texto = "Copia Original";
          var color = "gray";
          break;
        default:
          var texto = estado;
          var color = "gray";
      }
      return `<span class="badge color-` + color + `">` + texto + `</span>`;
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rlist.refresh();
      self.$refs.rform.clear();
      if (self.$refs.popupPedido) {
        self.$refs.popupPedido.close(true);
      }
      app.toast
        .create({
          text: self.$t("pedidos.pedido_form.pedido_preparado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    onPageBeforeRemove() {
      const self = this;
      // Destroy popup when page removed
      if (self.popup) self.popup.destroy();
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>