<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('usuarios.usuario_form.editar_usuario')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('usuarios.usuario_form.usuario')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('usuarios.usuario_form.nuevo_usuario')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly && id"
          fill
          icon-f7="trash"
          text="Eliminar"
          color="red"
          class="display-flex"
          @click="$refs.rform.deleteItem()"
        ></f7-button>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <f7-button
          v-if="readonly"
          fill
          icon-f7="pencil"
          text="Editar"
          class="display-flex"
          @click="readonly = false"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      @loadItem="loadItem"
      :readonly="readonly"
      :dbAdapter="Usuario"
      :beforeSave="beforeSave"
      :defaultData="{clientesIdMasDepartamento:[]}"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 2;">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.username')"
                  :form="rform"
                  type="text"
                  name="username"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.nombre')"
                  :form="rform"
                  type="text"
                  name="nombre"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.apellidos')"
                  :form="rform"
                  type="text"
                  name="apellidos"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.telefono')"
                  :form="rform"
                  type="text"
                  name="telefono"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.email')"
                  :form="rform"
                  type="text"
                  name="email"
                ></r-input>
                <!-- <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.iniciales')"
                  :form="rform"
                  type="text"
                  name="iniciales"
                ></r-input> -->
                <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.rol')"
                  :form="rform"
                  type="select"
                  name="rol"
                  @change="changeRol"
                >
                  <option selected disabled></option>
                  <option ></option>
                  <option value="ADMINISTRATIVO">Administrativo</option>
                  <option value="INSPECTOR">Inspector</option>
                  <option value="SUPERVISOR">Supervisor</option>
                  <option value="CLIENTE">Cliente</option>
                </r-input>
                <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.activo')"
                  :form="rform"
                  type="check"
                  name="activo"
                ></r-input>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>

      <!-- Clientes Asociados -->
      <f7-block-title>
        <div 
          v-if="rform.formData.rol && rform.formData.rol == 'ADMINISTRATIVO'"
        > ** Si a un usuario 'ADMINISTRATIVO' no se le asignan clientes, podrá ver todos
        </div>
        {{$t('usuarios.usuario_form.clientes_asociados')}} 
        <f7-link v-if="!rform.readonly" @click="asociaCliente">{{$t('usuarios.usuario_form.add')}}</f7-link>
      </f7-block-title>

      <!-- Clientes asociados -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
        v-for="(item,index) in rform.formData.clientesIdMasDepartamento"
        :key="'cliente-'+index"
        :style="'z-index: '+(100+rform.formData.clientesIdMasDepartamento.length-index)+';'"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row v-if="rform.formData.rol && (rform.formData.rol == 'INSPECTOR' || rform.formData.rol == 'SUPERVISOR')">
                <f7-col width="100" medium="100">
                  <r-autocomplete
                    ref="cliente"
                    :dbAdapter="Cliente"
                    floating-label
                    :label="$t('usuarios.usuario_form.cliente')"
                    :form="rform"
                    :name="'clientesIdMasDepartamento.'+index+'.clienteId'"
                    labelProp="nombre"
                    idProp="id"
                  ></r-autocomplete>
                </f7-col>
              </f7-row>
              <f7-row v-else>
                <f7-col width="100" medium="50">
                  <r-autocomplete
                    ref="cliente"
                    :dbAdapter="Cliente"
                    floating-label
                    :label="$t('usuarios.usuario_form.cliente')"
                    :form="rform"
                    :name="'clientesIdMasDepartamento.'+index+'.clienteId'"
                    labelProp="nombre"
                    idProp="id"
                  ></r-autocomplete>
                </f7-col>
                <f7-col width="100" medium="50">
                  <r-input
                    floating-label
                    :label="$t('usuarios.usuario_form.departamento')"
                    :form="rform"
                    type="text"
                    :name="'clientesIdMasDepartamento.'+index+'.departamento'"
                  ></r-input>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
        <f7-link
          v-if="!rform.readonly"
          @click="$delete(rform.formData.clientesIdMasDepartamento,index)"
          class="margin-half"
          style="position:absolute;right:0;top:0;"
          icon-f7="trash"
        ></f7-link>
      </f7-card>

      <!-- Inspector -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
        v-if="rform.formData.rol == 'INSPECTOR' || rform.formData.rol == 'SUPERVISOR'"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <f7-col width="100" :medium="100">
                  <f7-list-item media-list class="no-line">
                    <r-files 
                      :maxFiles="1"
                      :form="rform" 
                      :name="'inspector.foto'"
                      label="Añadir foto"
                      slot="media"
                    />
                    <r-input
                      floating-label
                      :label="$t('usuarios.usuario_form.nombre')"
                      :form="rform"
                      type="text"
                      :readonly="true"
                      name="nombre"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('usuarios.usuario_form.apellidos')"
                      :form="rform"
                      type="text"
                      :readonly="true"
                      name="apellidos"
                    ></r-input>
                    <r-input
                      floating-label
                      class="ver_gt_estimate"
                      :label="$t('usuarios.usuario_form.verGtEstimate')"
                      :form="rform"
                      type="check"
                      name="inspector.verGtEstimate"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('usuarios.usuario_form.email')"
                      :form="rform"
                      type="text"
                      :readonly="true"
                      name="email"
                    ></r-input>
                  </f7-list-item>
                </f7-col>
              </f7-row>
              <f7-row>
                  <f7-col width="100" medium="25">
                    <label class="label-group" >{{$t('usuarios.usuario_form.firma')}}:</label>
                    <f7-list-item media-list class="no-line">
                      <r-files 
                        :maxFiles="1"
                        :form="rform" 
                        :name="'inspector.firma'"
                        label="Añadir firma"
                        slot="media"
                      />
                    </f7-list-item>
                  </f7-col>
                  <f7-col width="100" medium="75">
                    <r-autocomplete
                      ref="cliente"
                      :dbAdapter="Cliente"
                      floating-label
                      :label="$t('usuarios.usuario_form.clienteDefecto')"
                      :form="rform"
                      :name="'inspector.clienteDefecto'"
                      labelProp="nombre"
                      :idProp="null"
                    ></r-autocomplete>
                  </f7-col>
                  <f7-col width="100" medium="100" class="margin-top">
                    <label class="label-group" >{{$t('pedidos.pedido_form.direccion')}}:</label>
                    <f7-row>
                      <r-input
                        floating-label
                        class="col-25"
                        :label="$t('pedidos.pedido_form.codigo_postal')"
                        :form="rform"
                        type="text"
                        name="inspector.direccion.cp"
                      ></r-input>
                      <r-input
                        floating-label
                        class="col-75"
                        :label="$t('pedidos.pedido_form.direccion')"
                        :form="rform"
                        type="text"
                        name="inspector.direccion.direccion"
                      ></r-input>
                      <r-input
                        floating-label
                        :label="$t('pedidos.pedido_form.poblacion')"
                        :form="rform"
                        class="col-33"
                        type="text"
                        name="inspector.direccion.poblacion"
                      ></r-input>
                      <r-input
                        floating-label
                        class="col-33"
                        :label="$t('pedidos.pedido_form.provincia')"
                        :form="rform"
                        type="text"
                        name="inspector.direccion.provincia"
                      ></r-input>
                      <r-input
                        floating-label
                        class="col-33"
                        :label="$t('pedidos.pedido_form.pais')"
                        :form="rform"
                        type="text"
                        name="inspector.direccion.pais"
                      ></r-input>
                    </f7-row>
                  </f7-col>
                </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
      </f7-card>

      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<style scoped>
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .ver_gt_estimate {
    display: flex;
  }
</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rFiles from "./../../components/rFiles.vue";
import Usuario from "./../../js/db/Usuario.js";
import Cliente from "./../../js/db/Cliente.js";
import Inspector from "./../../js/db/Inspector.js";
import UsuarioCliente from "./../../js/db/UsuarioCliente.js";
import Documento from "./../../js/db/Documento.js";

export default {
  components: {
    rForm,
    rInput,
    rAutocomplete,
    rTimepicker,
    rFiles
  },
  data: function() {
    var self = this;
    return {
      Usuario,
      Cliente,
      UsuarioCliente,
      Documento,
      Inspector,
      readonly: !this.editable,
      usuarioClienteId: undefined,
      inspectorId: undefined,
      newFotoSelected : false,
      newFirmaSelected : false
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    changeRol: function(e) {
      var self = this;
      if (self.$refs.rform.formData.rol == 'INSPECTOR'||self.$refs.rform.formData.rol == 'SUPERVISOR') {
        self.$set(self.$refs.rform.formData, 'inspector', {});
        self.usuarioClienteId = undefined;
      } else {
        UsuarioCliente.get({itemId: self.$refs.rform.formData.username}).then(function(data){
          self.$set(self.$refs.rform.formData, 'clientesIdMasDepartamento', data && data.clientesIdMasDepartamento ? data.clientesIdMasDepartamento : []);
          self.usuarioClienteId = data && data.id ? data.id : undefined;
        });
      }
    },
    loadItem: function(res) {
      var self = this;
      if (res && res.application && res.application.roles && res.application.roles.length > 0) {
        self.$set(self.$refs.rform.formData, 'rol', res.application.roles[0]);
      } 
      if (self.$refs.rform.formData.rol == 'INSPECTOR'||self.$refs.rform.formData.rol == 'SUPERVISOR') {
        Inspector.get({itemId: res.username}).then(function(data){
          self.$set(self.$refs.rform.formData, 'clientesIdMasDepartamento', data && data.clientesIdMasDepartamento ? data.clientesIdMasDepartamento : []);
          self.$set(self.$refs.rform.formData, 'inspector', data ? data : {});
          self.inspectorId = data && data.id ? data.id : undefined;
        });
      } else {
        UsuarioCliente.get({itemId: res.username}).then(function(data){
          self.$set(self.$refs.rform.formData, 'clientesIdMasDepartamento', data && data.clientesIdMasDepartamento ? data.clientesIdMasDepartamento : []);
          self.usuarioClienteId = data && data.id ? data.id : undefined;
        });
      }

    },
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var o = Object.assign({}, data);
      o.application = {};
      o.application.roles = [o.rol];
      if (o.rol == 'ADMINISTRATIVO') {
        o.createInSecurity = true;
      }
      delete o.rol;
      resolve(o);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      res.rol = res.application.roles[0];

      if (res.rol == 'INSPECTOR' || res.rol == 'SUPERVISOR') {
        let inspector = self.$refs.rform.formData.inspector;
        let clientesIdMasDepartamento = self.$refs.rform.formData.clientesIdMasDepartamento;
        inspector.foto=Array.isArray(inspector.foto) ? inspector.foto[0] : inspector.foto;
        inspector.firma=Array.isArray(inspector.firma) ? inspector.firma[0] : inspector.firma;
        inspector.username = res.username;
        inspector.nombre = res.nombre;
        inspector.apellidos = res.apellidos;
        inspector.email = res.email;
        inspector.clienteDefecto = inspector.clienteDefecto;
        inspector.activo = res.activo;
        inspector.clientesIdMasDepartamento = clientesIdMasDepartamento;
        Inspector.save({itemId: self.inspectorId, data: inspector}).then(function(data) {
          res.inspector = data;
          self.$refs.rform.formData = res;  
          self.$set(self.$refs.rform.formData, 'clientesIdMasDepartamento', data && data.clientesIdMasDepartamento ? data.clientesIdMasDepartamento : []);
          self.readonly = true;
          app.toast
            .create({
              text: self.$t("usuarios.usuario_form.usuario_guardado"),
              position: "center",
              closeTimeout: 2000,
              icon: '<i class="f7-icons">checkmark_alt</i>'
            })
            .open();
          self.$set(self.$refs.rform.formData, 'inspector', data ? data : {});
        });
      } else {
        var clientesIdMasDepartamento = self.$refs.rform.formData.clientesIdMasDepartamento;
        
        var usuarioClienteData = {
          clientesIdMasDepartamento: clientesIdMasDepartamento, 
          username: res.username,
          email: res.email,
          rol: res.rol,
          activo: res.activo
        }

        UsuarioCliente.save({itemId: self.usuarioClienteId, data: usuarioClienteData}).then(function(data){
          res.clientesIdMasDepartamento = data.clientesIdMasDepartamento;
          self.$refs.rform.formData = res;  
          self.readonly = true;
          app.toast
            .create({
              text: self.$t("usuarios.usuario_form.usuario_guardado"),
              position: "center",
              closeTimeout: 2000,
              icon: '<i class="f7-icons">checkmark_alt</i>'
            })
            .open();
        });

      }
    },
    asociaCliente: function() {
      var self = this;
      if(self.$refs.rform.formData && !self.$refs.rform.formData.clientesIdMasDepartamento){
        self.$set(self.$refs.rform.formData,'clientesIdMasDepartamento' , [{}]);
      } else {
        self.$refs.rform.formData.clientesIdMasDepartamento.push({});
      }
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>