import MainMenu from '../pages/main-menu.vue';
import Dashboard from '../pages/dashboard.vue';
import NotFoundPage from '../pages/404.vue';
//import Privacidad from '../pages/privacidad.vue';
//import Eula from '../pages/eula.vue';
//import Acerca from '../pages/acerca-de-ohc.vue';
//import Configuracion from '../pages/configuracion.vue';
import RoutesUtils from './../js/RoutesUtils.js';

var routesMain = [{
        path: '/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('dashboard', ['ADMINISTRATIVO', 'CLIENTE', 'INSPECTOR','SUPERVISOR'], Dashboard)
    },
    /*{
      path: '/privacidad/',
      name: 'privacidad',
      component: Privacidad
    },
    {
      path: '/eula/',
      name: 'eula',
      component: Eula
  
    },
    {
      path: '/acerca-de-ohc/',
      name: 'acerca-de-ohc',
      component: Acerca
  
    },
    {
      path: '/configuracion/',
      name: 'configuracion',
      component: Configuracion
  
    },*/
    {
        path: '(.*)',
        component: NotFoundPage,
    },
];


var routesLeft = [{
    path: '/main-menu/',
    beforeEnter: function(routeTo, routeFrom, resolve, reject) {
        if (!RoutesUtils.checkAuth()) {
            reject();
        } else {
            resolve();
        }
    },
    component: MainMenu
}];
export { routesMain, routesLeft };