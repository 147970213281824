<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('marcas.marcas.marcas')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height data-table">
      <!-- rList Table CARD-->
      <r-list
        ref="rlist"
        :dbAdapter="Marca"
        sort="id"
        sortDir="DESC"
        :tableMode="false"
        :virtualListParams="{height:32}"
        class="card full-height"
        classContent="card-content full-height "
      >
        <template v-slot:before="{rlist}">
          <f7-card-header>
            <div class="data-table-header">
              <div class="data-table-links">
                <a class="link" href="/marcas/new/">Nuevo</a>
              </div>
              <div class="data-table-actions">
                <f7-link small @click="rlist.refresh()" icon-f7="arrow_counterclockwise"></f7-link>
                <f7-link small @click="rlist.exportExcel('Marcas.xlsx')" icon-f7="doc_text_fill"  v-tooltip="'Exportar a Excel'"></f7-link>
                <f7-link
                  small
                  @click="$refs.rpanel.toggle()"
                  icon-f7="line_horizontal_3_decrease"
                  v-show="!rPanelOpenedByBreakpoint"
                ></f7-link>
              </div>
            </div>
          </f7-card-header>
        </template>

        <template v-slot:default="{vlData}">
          <f7-list-item
            v-for="(item) in vlData.items"
            :key="'marca-'+item.id"
            :link="'/marcas/edit/'+item.id+'/'"
            :title="item.nombre"
          >
            <r-imagen slot="media" :documento="item.imagen" width="32"></r-imagen>
          </f7-list-item>
        </template>
        <template v-slot:afterInner="{rlist}">
          <rPanel
            ref="rpanel"
            side="right"
            @openedByBreakpoint="rPanelOpenedByBreakpoint=true"
            @closedByBreakpoint="rPanelOpenedByBreakpoint=false"
            v-slot="{rpanel}"
          >
            <r-form @submit="rlist.setFilter($event); rpanel.close();">
              <template v-slot:before="{rform}">
                <f7-toolbar>
                  <div>Filtro</div>
                  <f7-link icon-f7="bin_xmark" @click="rform.clear();"></f7-link>
                </f7-toolbar>
              </template>
              <template v-slot="{rform}">
                <f7-list no-hairlines-md>
                  <template v-slot:list>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('marcas.marca_form.nombre')"
                      :form="rform"
                      type="text"
                      name="nombre"
                    ></r-input>
                    <f7-button type="submit" class="margin-vertical" icon-f7="search">Buscar</f7-button>
                  </template>
                </f7-list>
              </template>
            </r-form>
          </rPanel>
        </template>
        <template v-slot:after="{rlist}">
          <f7-card-footer>
            <span>
              <b>{{$tc('rlist.n_registros', rlist.count, {count:rlist.count})}}</b>
            </span>
          </f7-card-footer>
        </template>
      </r-list>
    </f7-page-content>
  </f7-page>
</template>
<script>
import rList from "./../../components/rList.vue";
import rPanel from "./../../components/rPanel.vue";
import rForm from "./../../components/rForm.vue";
import rImagen from "./../../components/rImagen.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Marca from "./../../js/db/Marca.js";
export default {
  components: {
    rList,
    rPanel,
    rForm,
    rInput,
    rImagen,
    rAutocomplete
  },
  data() {
    return {
      Marca,
      rPanelOpenedByBreakpoint: false
    };
  },
  computed: {},
  methods: {},
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>