<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('clientes.clientes.clientes')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        slot="default"
        :dbAdapter="Cliente"
        tableCode="CLIENTES"
        filterPosition="PANEL"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['id','nombre','idPadre']"
        :massiveActions="true"
        :controlsWidth="110" 
        :searchOnInit="true"
        @updatemany="onUpdateMany"
        :massiveActionsSelectAll="true"
        :mostrarBadgeFilters="false"
      >
        <template v-slot:tableLinks>
          <a class="link" href="/clientes/new/">{{$t('clientes.nuevo')}}</a>
        </template>
        <template v-slot:optionsColumn="{item,rlist}" style="width: 100px;">
          <f7-link icon-f7="eye" color="green" :href="`/clientes/view/${item.id}/`"></f7-link>
              <f7-link icon-f7="square_pencil" color="blue" :href="`/clientes/edit/${item.id}/`"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>
  </f7-page>
</template>
<script>
import rDynamicList from "../../components/rDynamicList.vue";
import Cliente from "./../../js/db/Cliente.js";
export default {
  components: {
    rDynamicList
  },
  data() {
    return {
      Cliente,
      rPanelOpenedByBreakpoint: false
    };
  },
  computed: {},
  methods: {
    getTipoCliente: function(tipoCliente) {
      switch (tipoCliente) {
        case "MARCA":
          var texto = "Marca";
          var color = "red";
          break;
        case "GRUPO_AUTOMOCION":
          var texto = "Grupo Automoción";
          var color = "green";
          break;
        case "CONCESIONARIO":
          var texto = "Concesionario";
          var color = "blue";
          break;
        default:
          var texto = tipoCliente;
          var color = "gray";
      }
      return `<span class="badge width-100 color-` + color + `">` + texto + `</span>`;
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>