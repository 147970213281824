<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('coches.coche_form.editar_coche')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('coches.coche_form.coche')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('coches.coche_form.nuevo_coche')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly && id"
          fill
          icon-f7="trash"
          text="Eliminar"
          color="red"
          class="display-flex"
          @click="$refs.rform.deleteItem()"
        ></f7-button>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Coche"
    >
      <f7-card class="padding-vertical-half padding-right">
        <f7-card-content class="list list-form">
          <ul>
            <r-input
              floating-label
              :label="$t('coches.coche_form.matricula')"
              :form="rform"
              type="text"
              name="matricula"
            ></r-input>
            <r-input
              floating-label
              :label="$t('coches.coche_form.bastidor')"
              :form="rform"
              type="text"
              name="bastidor"
            ></r-input>
            <f7-list-item media-list class="no-line">
              <r-files 
                slot="media"
                :maxFiles="1"
                :form="rform" 
                :name="'modelo.imagen'" 
                :readonly="true"
              />
              <r-autocomplete
                :dbAdapter="Marca"
                floating-label
                :label="$t('coches.coche_form.marca')"
                :form="rform"
                name="modelo.marca"
                labelProp="nombre"
              ></r-autocomplete>
              <r-autocomplete
                :dbAdapter="Modelo"
                floating-label
                :label="$t('coches.coche_form.modelo')"
                :form="rform"
                name="modelo"
                labelProp="nombre"
                :shownFields="['nombre','id','marca']"
                :source="self => mostrarModelos(self,rform)"
              ></r-autocomplete>
            </f7-list-item>
      
          <!-- Documentos aportados por el cliente -->
          <div>
            <br/>
            <f7-block-title>{{$t('coches.coche_form.documentacionAportada')}}</f7-block-title>
            <f7-card class="elevation-3 padding-vertical-half padding-right" >
              <f7-card-content>
                <f7-row no-gap class="col-100">
                  <r-files class="col-100" :form="rform" :name="'documentacionAportada'" :label="$t('common.addDoc')" :preview="true"></r-files>
                </f7-row>
              </f7-card-content>
            </f7-card>
          </div>
          <!-- Fin documentos aportados por el cliente -->

          </ul>


        </f7-card-content>
      </f7-card>

      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<style>
.smooth-dnd-container.horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex !important;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: flex-start;
  --f7-cols-per-row: 1;
}
.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex !important;
  flex: 0 0 60%;
  margin: auto;
}
.draggable-item-horizontal {
  width: 100%;
}
.draggable-item-horizontal img {
  pointer-events: none;
}
.smooth-dnd-container {
  min-height: 0;
}
</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Coche from "./../../js/db/Coche.js";
import Marca from "./../../js/db/Marca.js";
import Modelo from "./../../js/db/Modelo.js";

export default {
  components: {
    rForm,
    rInput,
    rFiles,
    rAutocomplete
  },
  data: function() {
    var self = this;
    return {
      Coche,
      Marca,
      Modelo,
      readonly: !this.editable
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("coches.coche_form.coche_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },

    comprobarConfiguracion: function (configuracion,valor) {
      if(valor === undefined) {
        return "true";
      }
      var self = this;
      var app = self.$f7;
      // Si no le pasamos una configuración para buscar, devolvemos false
      if (!configuracion) {
        return false;
      }
      // Miramos a ver si hay cliente asociado a la inspección
      if (self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.cliente) {
        // Si hay cliente, miramos a ver si aún no tenemos sus configuraciones cargadas y las cargamos
        if(!self.$refs.rform.formData.configuraciones) {
          Cliente.getConfiguraciones({data: {idCliente: self.$refs.rform.formData.cliente.id}}).then(function(res) {
            self.$set(self.$refs.rform.formData, 'configuraciones', res); 
          }).catch(function(error) {
            if (error.message) {
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
          });
        }
        // en este punto tenemos que tener las configuraciones del cliente de la inspección cargadas en el formulario.
        if(self.$refs.rform.formData.configuraciones && self.$refs.rform.formData.configuraciones.find(c => c.tipoConfiguracion == configuracion && c.valor == valor)) {
          return true;
        } else {
          return false;
        }
          
      } else {
        return false;
      }
    },
    mostrarModelos: function(self,rform) {
      return function(query, render) {
        var results = [];
        for (var i = 0; i < self.items.length; i++) {
          if (( self.items[i][self.searchProp].toLowerCase().indexOf(query.toLowerCase()) >= 0 )&&(rform.formData.modelo&&rform.formData.modelo.marca&&rform.formData.modelo.marca.id&&self.items[i].marca.id==rform.formData.modelo.marca.id)) {
            self.items[i]['txtValue'] = self.items[i][self.searchProp];
            results.push(self.items[i]);
          }
        }
        render(results);
      };
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>