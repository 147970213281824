import DatabaseModel from './DatabaseModel.js';

var Inspeccion = Object.create(DatabaseModel);

Inspeccion.model = 'inspeccion';
Inspeccion.modelFront = 'inspecciones';

Inspeccion.downloadCertificado = function(item) {
    var self = this;
    return self.download(
        self.server_inspecciones + self.prefix + self.model + '/' + item.id + '/certificado',
        'Certificado-' + item.lineaPedido.coche.matricula + '.pdf');
}

Inspeccion.aceptarJustificantes = function(item) {
    return Inspeccion.confirmarJustificantes(item.id, true);
}

Inspeccion.rechazarJustificantes = function(id, mensaje) {
    return Inspeccion.confirmarJustificantes(id, false, mensaje.getValue());
}

Inspeccion.confirmarJustificantes = function(id, confirmado, mensaje) {
    var self = this;
    var data = {
        "confirmacion": confirmado ? 'OK' : 'KO',
    }
    if (mensaje) {
        data.mensaje = mensaje;
    }
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/' + id + '/confirmarJustificantes',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Inspeccion.cambioEstado = function(id, data) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/' + id + '/cambioestado',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
                f
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Inspeccion.downloadInforme = function(item) {
    var self = this;
    return self.download(
        self.server_inspecciones + self.prefix + self.model + '/' + item.id + '/informe',
        'Informe-' + item.lineaPedido.coche.matricula + '.zip');
}

Inspeccion.resolverConflictos = function(conflicto) {
    var self = this;
    var data = {
        "idConflicto": conflicto.id,
        "comentario": conflicto.comentarioResolucion,
        "resolucion": conflicto.resolucion
    }
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/' + conflicto.inspeccion.id + '/resolverConflictos',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: [data]
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.list);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Inspeccion.enviarOrdenTaller = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/enviarOrdenTaller',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                var obj = JSON.parse(xhr.response);
                reject(obj.message ? obj.message : obj.error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Inspeccion.enviarSolicitudGarantia = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {

        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/enviarSolicitudGarantia',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                var obj = JSON.parse(xhr.response);
                reject(obj.message ? obj.message : obj.error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Inspeccion.cambiaEstadoVehiculo = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/' + params.id + '/cambiaEstadoVehiculo',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                var obj = JSON.parse(xhr.response);
                reject(obj.message ? obj.message : obj.error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}


Inspeccion.actualizarDocumentacionAportada = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/' + params.id + '/actualizarDocumentacionAportada',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                var obj = JSON.parse(xhr.response);
                reject(obj.message ? obj.message : obj.error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}



Inspeccion.documentosConcatenados = function(params) {
    var self = this;
    return self.download(
        self.server_inspecciones + self.prefix + self.model + '/' + params.id + '/documentosConcatenados/' + params.tipo,
        'Documentos-' + params.lineaPedido.coche.matricula + '.' + params.tipo,
        'POST',
        params.data);
}

Inspeccion.addDocumentos = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/' + params.idInspeccion + '/addDocumentos',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                var obj = JSON.parse(xhr.response);
                reject(obj.message ? obj.message : obj.error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

export default Inspeccion;