import DatabaseModel from './DatabaseModel.js';

var Documento = Object.create(DatabaseModel);

Documento.model = 'core/documento';

Documento.download = function(documento) {
    var self = this;
    documento = Array.isArray(documento) ? documento[0] : documento;
    self.getDocumento(documento).then(function(data) {
        var blob = new Blob([data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, documento.nombre + '.' + documento.extension);
        } else {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = documento.nombre + '.' + documento.extension;
            link.target = '_blank';
            link.click();
            URL.revokeObjectURL(link.href);
        }
    });
}

Documento.getDocumento = function(documento) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/download',
            method: 'POST',
            contentType: 'application/json',
            crossDomain: true,
            xhrFields: { 'responseType': 'arraybuffer' },
            data: documento
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Documento.getDocumentoByPath = function(path) {
    var self = this;
    if (!path) return;
    var params = {
        url: self.server_inspecciones + self.prefix + self.model + '/',
        method: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        data: { path: path }
    };
    return self.sendRequest(params, true);

}

Documento.upload = function(files, fileData) {

    var self = this;
    var data = new FormData();
    data.append("files", files[0]);
    if (fileData) {
        if (fileData.tipo) { data.append("tipo", fileData.tipo); }
        if (fileData.path) { data.append("path", fileData.path); }
        if (fileData.conservarNombre) { data.append("conservarNombre", fileData.conservarNombre); }
        if (fileData.sobrescribir) { data.append("sobrescribir", fileData.sobrescribir); }
    }
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/upload',
            method: 'POST',
            crossDomain: true,
            dataType: 'json',
            data: data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Documento.dataToFiles = function(fileData, fileName) {
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    if (!fileData.base64 && fileData._base64) {
        fileData.base64 = fileData._base64;
    }
    var file = null;
    if (fileData.base64) {
        file = b64toBlob([fileData.base64.split(',')[1]]);
    } else {
        file = fileData;
    }
    var formData = new FormData();
    formData.append("file", file, fileName);
    return [formData.get("file")];
}

Documento.uploadData = function(fileData) {
    const self = this;
    var fileName = `${fileData.nombre}.${fileData.extension}`;
    var files = Documento.dataToFiles(fileData, fileName);
    return self.upload(files, fileData);
}

Documento.getDocumentoData = function(fileData) {
    const self = this;
    return atob(fileData.split(',')[1]);
}

Documento.uploadTo = function(fileData, path, forcedName, tipo, forzar) {
    var fileName = ((forcedName) ? forcedName : fileData.nombre) + `.${fileData.extension}`;
    var files = Documento.dataToFiles(fileData, fileName);
    if (tipo) { fileData.tipo = tipo; }
    if (path) {
        fileData.path = path;
        fileData.conservarNombre = true;
        if (forzar) { fileData.sobrescribir = true; }
    }
    return Documento.upload(files, fileData);
}

Documento.moveTo = function(documento, path, name, forzar) {
    const self = this;
    var data = {
        documento: documento,
        pathDestino: path,
        newName: name,
        conservarNombre: true
    };
    if (forzar) { data.sobrescribir = true; }

    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/move',
            method: 'POST',
            crossDomain: true,
            contentType: 'application/json',
            dataType: 'json',
            data: data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                self.resetCache();
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Documento.delete = function(documento) {
    var self = this;
    var params = {
        url: self.server_inspecciones + self.prefix + self.model + '/delete',
        method: 'POST',
        crossDomain: true,
        contentType: 'application/json',
        dataType: 'json',
        data: documento
    };
    return self.sendRequest(params, false).then(res => {
        self.resetCache();
        return res;
    });
}

Documento.updatePDF = function(documentoPDF, documentoInicial) {
    var self = this;
    var params = {
        url: self.server_inspecciones + self.prefix + self.model + '/updatePDF',
        method: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        data: {
            nuevoDocumento: documentoPDF.archivo[0],
            documentoInicial: documentoInicial.archivo[0]
        }
    };
    return self.sendRequest(params, false);
}

Documento.guardarCamposPDF = function(documentoPDF, updateCamposPDF) {
    var self = this;
    var params = {
        url: self.server_inspecciones + self.prefix + self.model + '/updateCamposPDF',
        method: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        data: {
            documento: documentoPDF,
            campos: updateCamposPDF
        }
    };
    return self.sendRequest(params, false);
}

Documento.getPDFByPath = function(path) {
    var self = this;
    if (!path) return;
    var documento = { path: path };
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/pdf',
            method: 'POST',
            contentType: 'application/json',
            crossDomain: true,
            xhrFields: { 'responseType': 'arraybuffer' },
            data: documento
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });

}

Documento.downloadPDFReferenciasByPath = function(documento) {
    var self = this;
    self.getPDFByPath(documento.path).then(function(data) {
        var blob = new Blob([data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, documento.nombre + '.' + documento.extension);
        } else {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = documento.nombre + '.' + documento.extension;
            link.target = '_blank';
            link.click();
            URL.revokeObjectURL(link.href);
        }
    });
}

export default Documento;