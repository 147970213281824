<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('conflictos.conflicto_form.editar_conflicto')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('conflictos.conflicto_form.conflicto')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      :readonly="readonly"
      :dbAdapter="Conflicto"
    >
      <f7-row class="padding-vertical-half padding-right">
        <f7-col width="100" medium="50">
        </f7-col>
        <f7-col width="100" medium="25">
          <f7-button
            v-roles="{route: $f7route.url, roles: ['CLIENTE'], tipoCliente: ['MARCA']}"
            v-if="rform && rform.formData && !rform.formData.resolucion" 
            fill
            icon-f7="checkmark_alt_circle_fill"
            text="Resolver"
            class="display-flex"
            color="green"
            popup-open=".popup-resolve-conflicto"
            @click="resolucion='OK'; conflicto = rform.formData;"
          ></f7-button>
        </f7-col>
        <f7-col width="100" medium="25">
          <f7-button
            v-roles="{route: $f7route.url, roles: ['CLIENTE'], tipoCliente: ['MARCA']}"
            v-if="rform && rform.formData && !rform.formData.resolucion" 
            fill
            icon-f7="arrowshape_turn_up_left_fill"
            text="Rechazar"
            class="display-flex"
            color="red"
            popup-open=".popup-resolve-conflicto"
            @click="resolucion='KO'; conflicto = rform.formData;"
          ></f7-button>
        </f7-col>
      </f7-row>
      <f7-block-title>
        {{$t('conflictos.conflicto_form.conflicto')}}
      </f7-block-title>
      <f7-card class="padding-vertical-half padding-right">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <f7-row no-gap>
                  <f7-col width="50">
                    <r-input
                      class="col"
                      floating-label
                      :label="$t('conflictos.conflicto_form.estandar')"
                      :form="rform"
                      type="text"
                      name="estandar"
                    ></r-input>
                  </f7-col>
                  <f7-col width="50">
                    <r-input
                      class="col"
                      floating-label
                      :label="$t('conflictos.conflicto_form.subestandar')"
                      :form="rform"
                      type="text"
                      name="subEstandar"
                    ></r-input>
                  </f7-col>
                </f7-row>
                <f7-row no-gap>
                  <f7-col width="33">
                    <r-input
                      class="col"
                      floating-label
                      :label="$t('conflictos.conflicto_form.inspeccion')"
                      :form="rform"
                      type="text"
                      name="inspeccion.codigo"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <li class="readonly" floating-label="" 
                      :label="$t('conflictos.conflicto_form.tipo_conflicto')">
                      <div class="item-content item-input item-input-with-value">
                        <div class="item-inner">
                          <div class="item-title item-label item-floating-label">{{$t('conflictos.conflicto_form.tipo_conflicto')}}</div>
                          <div class="item-input-wrap">
                            <div v-html="getTipoConflictoBadge(rform.formData.tipoConflicto)"></div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </f7-col>
                  <f7-col width="33">
                    <r-autocomplete
                      class="col"
                      :dbAdapter="Cliente"
                      floating-label
                      :label="$t('conflictos.conflicto_form.cliente')"
                      :form="rform"
                      name="cliente"
                      nameId="clienteId"
                      labelProp="nombre"
                    ></r-autocomplete>
                  </f7-col>
                </f7-row>
                <f7-row no-gap>
                  <f7-col width="33">
                    <li class="readonly" floating-label="" 
                      :label="$t('conflictos.conflicto_form.resolucion')">
                      <div class="item-content item-input item-input-with-value">
                        <div class="item-inner">
                          <div class="item-title item-label item-floating-label">{{$t('conflictos.conflicto_form.resolucion')}}</div>
                          <div class="item-input-wrap">
                            <div v-html="getResolucionConflictoBadge(rform && rform.formData ? rform.formData.resolucion : '')"></div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      class="col"
                      floating-label
                      :label="$t('conflictos.conflicto_form.fecha_conflicto')"
                      :form="rform"
                      type="datepicker"
                      name="fechaConflicto"
                      :calendar-params="{timePickerPlaceholder:'Selecciona hora', toolbarCloseText:'Hecho',closeOnSelect:true,timePicker: true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      class="col"
                      floating-label
                      :label="$t('conflictos.conflicto_form.fecha_resolucion')"
                      :form="rform"
                      type="datepicker"
                      name="fechaResolucion"
                      :calendar-params="{timePickerPlaceholder:'Selecciona hora', toolbarCloseText:'Hecho',closeOnSelect:true,timePicker: true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
                    ></r-input>
                  </f7-col>
                </f7-row>
              </ul>
            </f7-col>
            <f7-col width="100" medium="50">
              <ul>
                <f7-row no-gap>
                  <f7-col width="50">
                    <r-input
                      floating-label
                      :label="$t('conflictos.conflicto_form.comentarios')"
                      :form="rform"
                      type="textarea"
                      name="comentario"
                    ></r-input>
                  </f7-col>
                  <f7-col width="50">
                    <r-input
                      floating-label
                      :label="$t('conflictos.conflicto_form.comentarios_resolucion')"
                      :form="rform"
                      type="textarea"
                      name="comentarioResolucion"
                    ></r-input>
                  </f7-col>
                </f7-row>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>

      <f7-block-title>
        {{$t('conflictos.conflicto_form.coche')}}
      </f7-block-title>
      <f7-card class="padding-vertical-half padding-right">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <f7-row no-gap>
                  <r-input
                    class="col-50"
                    floating-label
                    :label="$t('conflictos.conflicto_form.matricula')"
                    :form="rform"
                    type="text"
                    name="inspeccion.coche.matricula"
                  ></r-input>
                  <r-input
                    class="col-50"
                    floating-label
                    :label="$t('conflictos.conflicto_form.bastidor')"
                    :form="rform"
                    type="text"
                    name="inspeccion.coche.bastidor"
                  ></r-input>
                </f7-row>
                <f7-list-item media-list class="no-line">
                  <r-files 
                    slot="media"
                    :maxFiles="1"
                    :form="rform" 
                    :name="'inspeccion.coche.modelo.imagen'" 
                    :readonly="true"
                  />
                  <r-autocomplete
                    :dbAdapter="Marca"
                    floating-label
                    :label="$t('conflictos.conflicto_form.marca')"
                    :form="rform"
                    name="inspeccion.coche.modelo.marca"
                    labelProp="nombre"
                  ></r-autocomplete>
                  <r-autocomplete
                    :dbAdapter="Modelo"
                    floating-label
                    :label="$t('conflictos.conflicto_form.modelo')"
                    :form="rform"
                    name="inspeccion.coche.modelo"
                    labelProp="nombre"
                  ></r-autocomplete>
                </f7-list-item>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>


      <!-- Documentos -->
      
      <div
        v-if="rform.formData.documentos && rform.formData.documentos.length > 0">

        <f7-block-title>
          {{$t('conflictos.conflicto_form.documentos_adjuntos')}}
        </f7-block-title>

        <f7-card class="elevation-3 padding-vertical-half padding-right">
          <f7-card-content>
            <f7-row no-gap>
              <r-files :form="rform" name="documentos" />
            </f7-row>
          </f7-card-content>
        </f7-card>
      </div>

      <!-- Fin Documentos -->

      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>

    <f7-popup ref="popupResolucion" class="popup-resolve-conflicto" swipe-to-close>
      <f7-page>
        <f7-navbar :title="resolucion == 'OK' ? 'Resolver Incidencia' : 'Rechazar Incidencia'">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
      <r-form
        ref="rform"
        v-slot="{rform}"
        :itemId="null"
        :dbAdapter="Conflicto"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <f7-col width="100" medium="100">
                  <r-input
                    floating-label
                    :label="$t('conflictos.conflicto_form.comentarios_resolucion')"
                    :form="rform"
                    :readonly="false"
                    type="textarea"
                    name="comentarioResolucion"
                    ref="comentarioResolucion"
                  ></r-input>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
      </r-form>
      <f7-button
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="resolverConflicto()"
        ></f7-button>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>

  </f7-page>
</template>
<style scoped>

.separador {
  margin-top: 40px;
}

.label-group {
  margin-top: 20px;
  margin-left: 20px;
  font-weight: bold;
}

.smooth-dnd-container.horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex !important;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: flex-start;
  --f7-cols-per-row: 1;
}
.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex !important;
  flex: 0 0 60%;
  margin: auto;
}
.draggable-item-horizontal {
  width: 100%;
}
.draggable-item-horizontal img {
  pointer-events: none;
}
.smooth-dnd-container {
  min-height: 0;
}
</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rCaracteristica from "./../../components/rCaracteristica.vue";
import rComprobacion from "./../../components/rComprobacion.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rFiles from "./../../components/rFiles.vue";
import Inspeccion from "./../../js/db/Inspeccion.js";
import Conflicto from "./../../js/db/Conflicto.js";
import Cliente from "./../../js/db/Cliente.js";
import Modelo from "./../../js/db/Modelo.js";
import Marca from "./../../js/db/Marca.js";

export default {
  components: {
    rForm,
    rInput,
    rCaracteristica,
    rComprobacion,
    rFiles,
    rAutocomplete
  },
  data: function() {
    return {
      Inspeccion,
      Conflicto,
      Cliente,
      Modelo,
      Marca,
      readonly: !this.editable,
      resolucion: '',
      conflicto: {}
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    resolverConflicto: function() {
      var self = this;
      var app = self.$f7;
      var conflicto = self.conflicto;
      var conflictoResolucion = self.$refs.rform.formData;
      conflicto.resolucion = self.resolucion;
      conflicto.comentarioResolucion = conflictoResolucion.comentarioResolucion;
      app.preloader.show();
      Inspeccion.resolverConflictos(conflicto).then(function(res) {
        var router = self.$f7router;
        self.$refs.rform.formData = res[0];
        if (self.$refs.popupResolucion) {
          self.$refs.popupResolucion.close(true);
        }
        app.toast
        .create({
          text: self.$t("conflictos.conflicto_form.conflicto_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
      })
      .catch(function(error){
        app.dialog.alert(error.message);
      }).finally(function(){
        app.preloader.hide();
      });
    },
    getTipoConflictoBadge: function(tipoConflicto) {
      switch (tipoConflicto) {
        case "RESUELTO_CAMPO":
          var texto = "Resuelto en campo";
          var color = "green";
          break;
        case "ENVIADO_MARCA":
          var texto = "Enviado marca";
          var color = "orange";
          break;
        default:
          var texto = tipoConflicto;
          var color = "gray";
      }
      return `<span class="badge color-` + color + `">` + texto + `</span>`;
    },
    getResolucionConflictoBadge: function(resolucion) {
      switch (resolucion) {
        case "OK":
          var texto = "Positiva";
          var color = "green";
          break;
        case "KO":
          var texto = "Negativa";
          var color = "red";
          break;
        default:
          var texto = "Sin resolver";
          var color = "gray";
      }
      return `<span class="badge color-` + color + `">` + texto + `</span>`;
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>