<template>
  <div v-if="getValue(column, 'tipo') == 'FECHA'" :class="{'label-group': tipo == 'FILTRO'}">
    <template v-if="tipo == 'FILTRO'">
      <li><label>{{$t(column.nombre)}}</label></li>
      <r-input
        ref="mesActual"
        floating-label
        :readonly="!!form && (!!form.getValue(getPathName(column) + 'FechaVacia') || !!form.getValue(getPathName(column) + 'FechaRellena'))"
        outline
        :label="$t('common.mesActual')"
        :form="form"
        :simple="true"
        type="check"
        :name="getPathName(column) + 'MesActual'"
        @change="mesActual"
      ></r-input>
      <r-input
        ref="fechaVacia"
        floating-label
        outline
        :label="$t('common.fechaVacia')"
        :form="form"
        :simple="true"
        type="check"
        :name="getPathName(column) + 'FechaVacia'"
        @change="fechaVacia"
      ></r-input>
      <r-input
        ref="fechaRellena"
        floating-label
        outline
        :label="$t('common.fechaRellena')"
        :form="form"
        :simple="true"
        type="check"
        :name="getPathName(column) + 'FechaRellena'"
        @change="fechaRellena"
      ></r-input>
      <r-input
        ref="fechaDesde"
        v-if="!!form && !form.getValue(getPathName(column) + 'FechaVacia') && !form.getValue(getPathName(column) + 'FechaRellena')"
        :readonly="!!form && (!!form.getValue(getPathName(column) + 'MesActual') || !!form.getValue(getPathName(column) + 'FechaVacia') || !!form.getValue(getPathName(column) + 'FechaRellena'))"
        :overrideReadonly="true"
        floating-label
        outline
        :label="$t('common.desde')"
        :form="form"
        type="datepicker"
        :name="getPathName(column) + 'Desde'"
        :clearButton="true"
        :viewTooltip="viewTooltip"
        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
      ></r-input>
      <r-input
        v-if="!!form && !form.getValue(getPathName(column) + 'FechaVacia') && !form.getValue(getPathName(column) + 'FechaRellena')"
        ref="fechaHasta"
        :readonly="!!form && (!!form.getValue(getPathName(column) + 'MesActual') || !!form.getValue(getPathName(column) + 'FechaVacia') || !!form.getValue(getPathName(column) + 'FechaRellena'))"
        outline
        floating-label
        :label="$t('common.hasta')"
        :form="form"
        type="datepicker"
        :name="getPathName(column) + 'Hasta'"
        :clearButton="true"
        :viewTooltip="viewTooltip"
        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
      ></r-input>
    </template>
    <r-input
      v-else
      outline
      floating-label
      :label="$t(column.nombre)"
      :form="form"
      type="datepicker"
      :name="getPathName(column)"
      :clearButton="true"
      :viewTooltip="viewTooltip"
      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
    ></r-input>
  </div>
  <div v-else-if="getValue(column, 'tipo') == 'BOOLEAN'" :class="{'label-group': tipo == 'FILTRO'}">
    <template v-if="tipo == 'FILTRO'">
      <li><label>{{$t(column.nombre)}}</label></li>
      <r-input
        ref="booleanSI"
        :label="$t('common.yes')"
        :form="form"
        :simple="true"
        type="check"
        :name="getPathName(column) + 'BOOLEANSI'"
      ></r-input>
      <r-input
        ref="booleanNO"
        :label="$t('common.no')"
        :form="form"
        :simple="true"
        type="check"
        :name="getPathName(column) + 'BOOLEANNO'"
      ></r-input>
    </template>
    <template v-else>
      <f7-list-item
        checkbox
        :title="'Cambiar ' + $t(column.nombre)"
        @change="changeBooleanActivo"
      >
      </f7-list-item>
      <r-input
        v-if="booleanCambioMasivoActivar"
        ref="booleanChange"
        outline
        :label="$t(column.nombre)"
        :form="form"
        type="check"
        :name="getPathName(column)"
        :viewTooltip="viewTooltip"
      ></r-input>
    </template>
  </div>
  <div v-else-if="getValue(column, 'tipo') == 'IMPORTE'" :class="{'label-group': tipo == 'FILTRO'}">
    <template v-if="tipo == 'FILTRO'">
      <li><label>{{$t(column.nombre)}}</label></li>
      <r-input
        floating-label
        outline
        :label="$t('common.desde')"
        :form="form"
        type="number"
        step=".01"
        :name="getPathName(column) + 'Desde'"
        :clearButton="true"
        :viewTooltip="viewTooltip"
      ></r-input>
      <r-input
        outline
        floating-label
        :label="$t('common.hasta')"
        :form="form"
        type="number"
        step=".01"
        :name="getPathName(column) + 'Hasta'"
        :clearButton="true"
        :viewTooltip="viewTooltip"
      ></r-input>
    </template>
    <r-input
      v-else
      outline
      floating-label
      :label="$t(column.nombre)"
      :form="form"
      type="number"
      step=".01"
      :name="getPathName(column)"
      :clearButton="true"
      :viewTooltip="viewTooltip"
    ></r-input>
  </div>
  <r-input
    v-else-if="getValue(column, 'tipo') == 'NUMERO'"
    floating-label
    outline
    :label="$t(column.nombre)"
    :form="form"
    type="number"
    :clearButton="true"
    :viewTooltip="viewTooltip"
    :name="getPathName(column)"
  ></r-input>
  <r-autocomplete
    v-else-if="getValue(column, 'tipo') == 'SELECT'"
    floating-label
    outline
    :model="getValue(column, 'model')"
    :label="$t(column.nombre)"
    :dbItems="getValue(column, 'items')"
    :form="form"
    :name="getPathName(column)"
    :idProp="getValue(column, 'idProp')"
    :labelProp="getValue(column, 'labelProp')"
    :prefixProp="getValueOpcion(column, 'prefixProp')"
    :async="getValue(column, 'async')"
    :minLength="getValue(column, 'minLength')"
    :viewTooltip="viewTooltip"
    :clearButton="true"
    :shownFields="getValue(column, 'shownFields')"
  ></r-autocomplete>
  <r-autocomplete-m2m
    v-else-if="getValue(column, 'tipo') == 'MULTISELECT'"
    class="col-100"
    floating-label
    outline
    :model="getValue(column, 'model')"
    :label="$t(column.nombre)"
    :dbItems="getValue(column, 'items')"
    :form="form"
    :name="getPathName(column)"
    type="textarea"
    :lineBreak="true"
    :idProp="getValue(column, 'idProp')"
    :labelProp="getValue(column, 'labelProp')"
    :prefixProp="getValueOpcion(column, 'prefixProp')"
    :viewTooltip="viewTooltip"
    :shownFields="getValue(column, 'shownFields')"
  ></r-autocomplete-m2m>
  <r-autocomplete-m2m
    v-else-if="getValue(column, 'tipo') == 'MES'"
    class="col-100"
    floating-label
    outline
    :model="getValue(column, 'model')"
    :label="$t(column.nombre)"
    :dbItems="getMeses()"
    :form="form"
    :name="getPathName(column)"
    type="textarea"
    :lineBreak="true"
    idProp="id"
    labelProp="nombre"
    :viewTooltip="viewTooltip"
  ></r-autocomplete-m2m>
  <r-autocomplete-m2m
    v-else-if="getValue(column, 'tipo') == 'ANYO'"
    class="col-100"
    floating-label
    outline
    :model="getValue(column, 'model')"
    :label="$t(column.nombre)"
    :dbItems="getAnyos(column)"
    :form="form"
    :name="getPathName(column)"
    type="textarea"
    :lineBreak="true"
    idProp="id"
    labelProp="nombre"
    :viewTooltip="viewTooltip"
  ></r-autocomplete-m2m>
  <r-input
    v-else
    floating-label
    outline
    :label="$t(column.nombre)"
    :form="form"
    type="text"
    :clearButton="true"
    :viewTooltip="viewTooltip"
    :name="getPathName(column)"
  ></r-input>
</template>
<style scoped>
.label-group {
  margin-top: 20px;
  margin-left: 20px;
  font-weight: bold;
}
</style>
<script>

import rInput from "./rInput.vue";
import rAutocompleteM2m from "./rAutocompleteM2m.vue";
import rAutocomplete from "./rAutocomplete.vue";

export default {
  components: {
    rInput,
    rAutocompleteM2m,
    rAutocomplete
  },
  data() {
    return {
      self: this,
      booleanCambioMasivoActivar: false
    };
  },
  props: {
    column: {
      required: true,
      type: Object
    },
    form: {
      type: Object
    },
    viewTooltip: {
      type: Boolean,
      default: true
    },
    tipo: {
      type: String,
      default: "FILTRO"
    }
  },
  mounted() {
    const self = this;
    if (self.getValue(self.column, 'tipo') == 'FECHA' && self.tipo == 'FILTRO') {
      self.setFechasMesActual(!!self.form.getValue(self.getPathName(self.column) + 'MesActual'));
      self.setFechasVacias(!!self.form.getValue(self.getPathName(self.column) + 'FechaVacia'));
      self.setFechasRellenas(!!self.form.getValue(self.getPathName(self.column) + 'FechaRellena'));
    }

  },
  methods: {
    cleanData() {
      const self = this;
      self.cleanDataChildren(self.$children);

    },
    cleanDataChildren(children) {
      const self = this;
      if (children && Array.isArray(children) && children.length) {
        children.forEach(child => {
          if (child.setValue) {
            child.setValue(null);
          }
          self.cleanDataChildren(child.$children);
        });
      }
    },
    changeBooleanActivo (event) {
      const self = this;
      self.booleanCambioMasivoActivar = event.target.checked;
      if (!self.booleanCambioMasivoActivar) {
        self.$refs.booleanChange.setValue(null);
      }
    },
    getMeses () {
      const self = this;
      return [
        {id: 1, nombre: "01 - " + self.$t('Enero')},
        {id: 2, nombre: "02 - " + self.$t('Febrero')},
        {id: 3, nombre: "03 - " + self.$t('Marzo')},
        {id: 4, nombre: "04 - " + self.$t('Abril')},
        {id: 5, nombre: "05 - " + self.$t('Mayo')},
        {id: 6, nombre: "06 - " + self.$t('Junio')},
        {id: 7, nombre: "07 - " + self.$t('Julio')},
        {id: 8, nombre: "08 - " + self.$t('Agosto')},
        {id: 9, nombre: "09 - " + self.$t('Septiembre')},
        {id: 10, nombre: "10 - " + self.$t('Octubre')},
        {id: 11, nombre: "11 - " + self.$t('Noviembre')},
        {id: 12, nombre: "12 - " + self.$t('Diciembre')}
      ];
    },
    getAnyos (column) {
      let anyosAtras = column.filtro.opciones && column.filtro.opciones["anyosAtras"] ? column.filtro.opciones["anyosAtras"] : 5;
      let currentYear = new Date().getFullYear(); 
      var arr = [];
      for (let i = 0; i <= anyosAtras; i++) {
        arr.push({id: currentYear, nombre: "" + currentYear});
        currentYear--;
      }

      return arr;

    },
    mesActual (event) {
      const self = this;
      if (event.target) {
        self.setFechasMesActual(event.target.checked);
      }
    },
    fechaVacia (event) {
      const self = this;
      if (event.target) {
        self.setFechasVacias(event.target.checked);
      }
    },
    fechaRellena (event) {
      const self = this;
      if (event.target) {
        self.setFechasRellenas(event.target.checked);
      }
    },
    setFechasMesActual(isChecked) {
      const self = this;
      if (isChecked) {
        var date = new Date();
        var y = date.getFullYear(); 
        var m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        self.$refs.fechaDesde.setValue([firstDay], "calendar");
        self.$refs.fechaHasta.setValue([lastDay], "calendar");
      } else {
        self.$refs.fechaDesde.setValue(null, "calendar");
        self.$refs.fechaHasta.setValue(null, "calendar");
          
      }
    },
    setFechasVacias(isChecked) {
      const self = this;
      if (isChecked) {
        if (self.$refs.fechaDesde) {
          self.$refs.fechaDesde.setValue(null, "calendar");
        }
        if (self.$refs.fechaHasta) {
          self.$refs.fechaHasta.setValue(null, "calendar");
        }
        self.$refs.mesActual.setValue(false);
        self.$refs.fechaRellena.setValue(false);
      } 
    },
    setFechasRellenas(isChecked) {
      const self = this;
      if (isChecked) {
        if (self.$refs.fechaDesde) {
          self.$refs.fechaDesde.setValue(null, "calendar");
        }
        if (self.$refs.fechaHasta) {
          self.$refs.fechaHasta.setValue(null, "calendar");
        }
        self.$refs.mesActual.setValue(false);
        self.$refs.fechaVacia.setValue(false);
      } 
    },
    getValue: function(column, field) {
      const self = this;
      var value = column.filtro[field];
      if (self.tipo == "MASIVO") {
        var overrideFields = self.getValueOpcion(column, "overrideFields");
        if (overrideFields && Array.isArray(overrideFields) && overrideFields.indexOf(field) > -1) {
          value = column.cambioMasivo[field];
        } else {
          value = column.cambioMasivo[field] || column.filtro[field];
        }
      }
      return value;
    },
    getValueOpcion: function(column, opcion) {
      const self = this;
      var value = column.filtro.opciones && column.filtro.opciones[opcion] ? column.filtro.opciones[opcion] : undefined;
      if (self.tipo == "MASIVO" && column.cambioMasivo && column.cambioMasivo.opciones && column.cambioMasivo.opciones[opcion]) {
        value = column.cambioMasivo.opciones[opcion];
      }
      return value;
    },
    getPathName: function(column) {
      const self = this;
      var pathName = column.pathName;
      if (self.tipo == "FILTRO") {
        pathName = column.filtro.pathName || column.pathName;
        pathName = pathName.replaceAll(".", "##");
      } else if (self.tipo == "MASIVO") {
        pathName = column.cambioMasivo.pathName || column.pathName;
      }
      return pathName;
    }
  }
};
</script>