import DatabaseModel from './DatabaseModel.js';

var Venta = Object.create(DatabaseModel);

Venta.model = 'importacion/ventas';

Venta.delete = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.anyo || !params.mes) reject({ error: 'Año o mes no relleno' });
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/' + params.anyo + '/' + params.mes,
            method: 'DELETE',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.list);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Venta.post = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                var obj = JSON.parse(xhr.response);
                reject(obj.message ? obj.message : obj.error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Venta.downloadPlantilla = function() {
    var self = this;
    return self.download(
        self.server_inspecciones + self.prefix + self.model + '/plantilla', 
        'Plantilla-Ventas.xlsx');
};

export default Venta;