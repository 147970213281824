var Utils = {
    padZeros: function (input, maxLength) {
        if (maxLength === void 0) { maxLength = 0; }
        return ("0000" + input).slice(-maxLength);
    },
    cleanObj: function(obj) {
        for (var propName in obj) { 
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    },
    getDireccionStr(direccion) {
        if (direccion) {
          return (direccion.direccion || '') + ' ' + (direccion.cp || '') + ' ' + (direccion.poblacion || '') + ' ' + (direccion.pais || '');
        } else {
          return '';
        }
    },
    getUser() {
        if (window.app && window.app.data && window.app.data.user && window.app.data.user.username) {
            return window.app.data.user;
        } else {
            var user = localStorage.getItem("user");
            if (typeof user == "undefined" || !user) { 
                return null;
            } else {
                user = JSON.parse(user);
                if (typeof user == "undefined" || !user) {
                    return null;
                } else {
                    return user;
                }
            }
        }
    },
    getMenu() {
        const self = this;
        const user = self.getUser();
        if (user) {
            return user.menu;
        } else {
            return null;
        }
    },
    userLoaded() {
        const self = this;
        return !!self.getUser();
    }
}

export default Utils;