var CacheService = {
    currentRequests: {},
    cachedResponses: {},
    getCachedRequest: function(service, params) {
        var self = this;
        var normParams = self._normalizedParams(params);
        if (self.currentRequests[service] && self.currentRequests[service][normParams]) {
            var cachedRequest = self.currentRequests[service][normParams];
            if (cachedRequest.expirationTime > Date.now()) {
                console.info("Cached request", service, params.url);
                return cachedRequest.promise;
            } else {
                delete self.currentRequests[service][normParams];
            }
        } else if (self.cachedResponses[service] && self.cachedResponses[service][normParams]) {
            var cachedResponse = self.cachedResponses[service][normParams];
            if (cachedResponse.expirationTime > Date.now()) {
                console.info("Cached response", service, params.url);
                return new Promise(function(resolve, reject) {
                    if (cachedResponse.response.error) {
                        reject(cachedResponse.response);
                    } else if (cachedResponse.response.dto) {
                        resolve(cachedResponse.response.dto);
                    } else {
                        resolve(cachedResponse.response);
                    }
                });
            } else {
                delete self.cachedResponses[service][normParams];
            }
        }
        return null;
    },
    addCachedRequest: function(service, params, promise) {
        var self = this;
        var expiresIn = params.expiresIn;
        var expirationTime = Date.now() + expiresIn;
        var normParams = self._normalizedParams(params);
        if (!self.currentRequests[service]) {
            self.currentRequests[service] = {};
        }
        self.currentRequests[service][normParams] = {
            expirationTime: expirationTime,
            promise: promise
        }
        promise.then(function(res) {
            delete self.currentRequests[service][normParams];
            if (res.error) {
                console.log("Error. No cache");
                return;
            }
            if (!self.cachedResponses[service]) {
                self.cachedResponses[service] = {};
            }
            self.cachedResponses[service][normParams] = {
                expirationTime: expirationTime,
                response: res
            }
        }, function(err) {
            delete self.currentRequests[service][normParams];
            console.log("Error. No cache");
        });
        setTimeout(function() { self._clearObsoleteCache(service, params) }, expiresIn);
    },
    clearCache: function(service) {
        var self = this;
        delete self.cachedResponses[service];
    },
    _clearObsoleteCache: function(service, params) {
        var self = this;
        var normParams = self._normalizedParams(params);
        delete self.currentRequests[service][normParams];
    },
    _normalizedParams: function(a) {
        function sortProperties(unordered) {
            var ordered = {};
            Object.keys(unordered).sort().forEach(key => { ordered[key] = unordered[key] });
            return ordered;
        }
        return JSON.stringify(sortProperties(a));
    },
    _isEqual: function(a, b) {
        return self._normalizedParams(a) == self._normalizedParams(b);
    }
}

export default CacheService;