<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('inspecciones.inspecciones.inspecciones')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Inspeccion"
        tableCode="INSPECCIONES"
        filterPosition="PANEL"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['codigo','estado.estado','inspector','cliente._id']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="110"
        :searchOnInit="true"
        :mostrarBadgeFilters="false"
      >
        <!-- <template v-slot:optionsColumn="{item}" style="width: 100px;"> -->
        <template v-slot:optionsColumn="{item}">
          <f7-link icon-f7="eye" color="green" :href="`/inspecciones/view/${item.id}/`" v-tooltip="'Ver certificación'"></f7-link>
          <f7-link 
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
            v-if="item.estado.estado != 'CANCELADA' && item.estado.estado != 'FACTURADA'" 
            icon-f7="square_pencil" 
            color="blue" 
            v-tooltip="'Editar certificación'"
            @click="inspeccion = item; popupEditarOpened = true"
          ></f7-link>
            <!-- v-if="item.estado.estado == 'PENDIENTE_FACTURACION' || item.estado.estado == 'CREADA'"  -->
          <f7-link 
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'SUPERVISOR']}"
            icon-f7="arrow_2_circlepath_circle" 
            color="orange" 
            popup-open=".popup-cambio-estado" 
            v-tooltip="'Cambiar estado'"
            @click="inspeccion = item"
          ></f7-link>
          <!-- <f7-link icon-f7="trash" color="red" @click="rlist.removeItem(item.id)" title="Borra certificación"></f7-link> -->
          <f7-link v-if="item.formato && item.formato.nombreCertificado && (item.calificacion == 'BUENO' || item.calificacion == 'EXCELENTE')" icon-f7="cloud_download_fill" color="green" v-tooltip="'Descargar certificado'"
            @click.prevent="downloadCertificado(item)"
          ></f7-link>
          <f7-link 
            v-if="item.estado && item.estado.estado == 'PENDIENTE_FACTURACION'"
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}"
            icon-f7="doc_plaintext" 
            color="black" 
            v-tooltip="'Facturar certificación'"
            @click="inspeccion = item; popupFacturarOpened = true"
          ></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>

    <!-- Popup de edición -->
    <f7-popup ref="popupEditar" v-if="popupEditarOpened" :opened="true" class="popup-editar-inspeccion" @popup:closed="popupEditarOpened = false" swipe-to-close>
      <f7-page>
        <f7-navbar title="Editar Inspeccion">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
      <r-form
        ref="rform"
        v-slot="{rform}"
        :itemId="inspeccion?inspeccion.id:null"
        :readonly="false"
        :initialData="inspeccion ? inspeccion : null"
        :defaultData="inspeccion ? inspeccion : null"
        :dbAdapter="Inspeccion"
        :beforeSave="beforeSave"
        @save="onSave"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <f7-col width="100" medium="100">
                  <!-- <r-autocomplete
                    v-if="inspeccion && inspeccion.estado && inspeccion.estado.estado == 'CREADA'" 
                    :dbAdapter="TipoInspeccion"
                    floating-label
                    :label="$t('inspecciones.inspeccion_form.tipo_inspeccion')"
                    :form="rform"
                    :name="'lineaPedido.tipoInspeccion'"
                    idProp="valor"
                    labelProp="nombre"
                  ></r-autocomplete> -->
                  <r-autocomplete
                    v-if="inspeccion && inspeccion.estado && inspeccion.estado.estado == 'CREADA'" 
                    :dbAdapter="Inspector"
                    floating-label
                    :label="$t('inspecciones.inspeccion_form.inspector_new')"
                    :form="rform"
                    name="inspector"
                    idProp="username"
                    labelProp="nombre"
                    :filter="{filter:{activo: true}}"
                  ></r-autocomplete>
                  <r-input
                    v-if="inspeccion && inspeccion.estado && inspeccion.estado.estado == 'CREADA'" 
                    floating-label
                    :label="$t('inspecciones.inspeccion_form.fecha_visita_new')"
                    :form="rform"
                    type="datepicker"
                    name="fechaVisitaEfectiva"
                    ref="fechaVisitaEfectiva"
                    :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                  ></r-input>
                  <r-timepicker
                    v-if="inspeccion && inspeccion.estado && inspeccion.estado.estado == 'CREADA'" 
                    floating-label
                    :label="$t('inspecciones.inspeccion_form.hora_new')"
                    :form="rform"
                    :readonly="!rform.formData.fechaVisitaEfectiva"
                    name="fechaVisitaEfectiva"
                  ></r-timepicker>
                  <r-input
                    v-if="inspeccion && inspeccion.estado && inspeccion.estado.estado == 'CREADA' && rform && rform.formData && rform.formData.fechaVisitaEfectiva"
                    floating-label
                    :label="$t('inspecciones.inspeccion_form.motivo')"
                    :form="rform"
                    type="text"
                    name="motivo"
                  ></r-input>
                  <r-input
                    floating-label
                    :label="$t('inspecciones.inspeccion_form.importe_new')"
                    :form="rform"
                    type="text"
                    name="importe"
                  ></r-input>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
      </r-form>
      <f7-button
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
    <f7-popup ref="popupCambioEstado" class="popup-cambio-estado" @popup:closed="$refs.rformCambioEstado.clear();" swipe-to-close>
      <f7-page>
        <f7-navbar title="Cambiar Estado de Inspeccion">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
      <r-form
        ref="rformCambioEstado"
        v-slot="{rform}"
        :itemId="inspeccion?inspeccion.id:null"
        :readonly="false"
        :dbAdapter="Inspeccion"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <f7-col width="100" medium="100">
                  <r-input
                    floating-label
                    :label="$t('inspecciones.inspeccion_form.estado')"
                    :form="rform"
                    type="select"
                    name="estado"
                  >
                    <option value></option>
                    <option value="CREADA" v-if="inspeccion && inspeccion.estado && (inspeccion.estado.estado == 'PENDIENTE_FACTURACION' || inspeccion.estado.estado == 'FACTURADA')">Creada</option>
                    <option value="PENDIENTE_FACTURACION" v-if="inspeccion && inspeccion.estado && inspeccion.estado.estado == 'CREADA'">Pendiente de facturación</option>
                    <option value="FACTURADA" v-if="inspeccion && inspeccion.estado && (inspeccion.estado.estado == 'PENDIENTE_FACTURACION')">Facturada</option>
                    <option value="CANCELADA" v-if="inspeccion && inspeccion.estado && inspeccion.estado.estado == 'CREADA'">Cancelada</option>
                  </r-input>
                  <r-input
                      floating-label
                      label="Comentario"
                      :form="rform"
                      type="textarea"
                      name="comentario"
                    ></r-input>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
      </r-form>
      <f7-button
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="cambiarEstado"
        ></f7-button>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>














    <!-- Popup de facturación -->
    <f7-popup ref="popupFacturar" v-if="popupFacturarOpened" :opened="true" class="popup-facturar" @popup:closed="popupFacturarOpened = false" @popup:opened="openPopupFacturar" swipe-to-close>
      <f7-page>
        <f7-navbar title="Facturar inspección">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card class="elevation-3 padding-vertical-half padding-right" >
          <r-form
            ref="rformFactura"
            v-slot="{rform}"
            @save="onSaveFactura"
            :readonly="false"
            :dbAdapter="Factura"
            :defaultData="{fechaHasta:new Date(), fechaEmision:new Date(), fechaVencimiento:new Date((new Date()).getTime() + 30*24*60*60*1000)}"
          >
            <f7-row class="padding-vertical-half padding-right">
              <f7-col width="100" medium="75">
              </f7-col>
            </f7-row>
            <f7-block-title>
              Factura
            </f7-block-title>
            <f7-card class="padding-vertical-half padding-right">
              <f7-card-content>
                <!-- <f7-row no-gap class="list list-form no-hairlines">
                  <f7-col width="100" medium="100">
                    <label class="label-group">{{$t('facturas.a_quien_facturar')}}</label>
                    <r-input
                      floating-label
                      :label="$t('facturas.comoFacturar')"
                      :form="rform"
                      type="select"
                      name="formaFacturacion"
                      :readonly="true"
                      :overrideReadonly="true"
                    >
                      <option selected value="A_CADA_UNO_LO_SUYO">Generar facturas diferentes para cada concesionario / Marca</option>
                      <option value="SOLO_LO_MIO">Facturar solo el cliente seleccionado a su nombre</option>
                      <option value="TODO_A_LA_MARCA">Factura a nombre de la marca</option>
                      <option value="TODO_AL_SELECCIONADO">Facturar a nombre del cliente seleccionado (Él mismo e Hijos)</option>
                    </r-input>
                  </f7-col>
                </f7-row> -->
                <f7-row no-gap class="list list-form no-hairlines">
                  <f7-col width="100" medium="50" class="col-50">
                    <r-input
                      floating-label
                      :label="$t('facturas.fecha_emision')"
                      :form="rform"
                      type="datepicker"
                      name="fechaEmision"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                  </f7-col>
                  <f7-col width="100" medium="50" class="col-50">
                    <r-input
                      floating-label
                      :label="$t('facturas.fecha_vencimiento')"
                      :form="rform"
                      type="datepicker"
                      name="fechaVencimiento"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                  </f7-col>
                </f7-row>
              </f7-card-content>
            </f7-card>
            <pre style="display:none;">{{rform.formData}}</pre>
          </r-form>
          <f7-button
              fill
              icon-f7="floppy_disk"
              text="Guardar"
              class="display-flex"
              @click="$refs.rformFactura.saveItem()"
          ></f7-button>
        </f7-card>
      </f7-page>
    </f7-popup>

  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rList from "./../../components/rList.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rPanel from "./../../components/rPanel.vue";
import Inspeccion from "./../../js/db/Inspeccion.js";
import Inspector from "./../../js/db/Inspector.js";
import Factura from "./../../js/db/Factura.js";
import Cliente from "./../../js/db/Cliente.js";
import TipoInspeccion from "./../../js/db/TipoInspeccion.js";
import rDynamicList from "./../../components/rDynamicList.vue";
import Utils from "./../../js/Utils.js";

export default {
  components: {
    rList,
    rPanel,
    rForm,
    rInput,
    rTimepicker,
    rAutocomplete,
    rDynamicList
  },
  data() {
    return {
      Inspeccion,
      Inspector,
      Factura,
      Cliente,
      TipoInspeccion,
      Utils,
      rPanelOpenedByBreakpoint: false,
      inspeccion: {},
      popupEditarOpened: false,
      popupFacturarOpened: false
    };
  },
  computed: {},
  methods: {
    beforeSave: function(data, resolve, reject) {
      if (typeof data.inspector != 'string') {
        data.inspector = data.inspector.username;
      }
      resolve(data);
    },
    showCheckInList: function(item) {
      // return item.estado && item.estado.estado && item.estado.estado == "INSPECCIONADO"; 
      return false; 
    },
    cambiarEstado: function(e) {
      var self = this;
      var app = self.$f7;
      var data = self.$refs.rformCambioEstado.formData;
      app.dialog.confirm(
        "¿Esta seguro de querer cambiar el estado de la certificación " + self.inspeccion.codigo + "?",
        "Cambio de estado",
        function() {
          app.preloader.show();
          Inspeccion.cambioEstado(self.inspeccion.id, data).then(function(res) {
            self.$refs.rlist.reload();
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: 'Cambio de estado realizado',
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
                self.$refs.rformCambioEstado.clear();
                if (self.$refs.popupCambioEstado) {
                  self.$refs.popupCambioEstado.close(true);
                }
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          }).finally( _ => app.preloader.hide());
        }
      );
    },
    submitFilter: function(rlist, event, rpanel) {
      var self = this;
      if (!self.$refs.incluirConcesionarios || !self.$refs.incluirConcesionarios.checked) {
        event.clienteId = event.keyword;
        delete event.keyword;
      }
      rlist.setFilter(event); 
      rpanel.close();
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.inspeccion = null;
      if (self.$refs.popupEditar) {
        self.$refs.popupEditar.close();
      }
      if (self.$refs.rform) {
        self.$refs.rform.clear();
      }
      app.toast
      .create({
        text: self.$t("inspecciones.inspeccion_form.inspeccion_guardada"),
        position: "center",
        closeTimeout: 2000,
        icon: '<i class="f7-icons">checkmark_alt</i>'
      })
      .open();
      if (self.$refs.rlist) {
        self.$refs.rlist.refresh()
      }
    },
    getEstadoBadge: function(estado) {
      switch (estado) {
        case "CREADA":
          var texto = "Creada";
          var color = "blue";
          break;
        case "PENDIENTE_FACTURACION":
          var texto = "Realizada";
          var color = "green";
          break;
        case "FACTURADA":
          var texto = "Facturada";
          var color = "teal";
          break;
        case "CANCELADA":
          var texto = "Cancelada";
          var color = "red";
          break;
        case "COPIA_ORIGINAL":
          var texto = "Copia Original";
          var color = "gray";
          break;
        default:
          var texto = estado;
          var color = "gray";
      }
      return `<span class="badge color-` + color + `">` + texto + `</span>`;
    },
    getTipoInspeccionBadge: function(tipoInspeccion) {
      switch (tipoInspeccion) {
        case "CERTIFICACION_1":
          var texto = "1ª Certificación";
          var color = "blue";
          break;
        case "CERTIFICACION_2":
          var texto = "2ª Certificación";
          var color = "orange";
          break;
        case "CERTIFICACION_3":
          var texto = "3ª Certificación";
          var color = "purple";
          break;
        case "CERTIFICACION_4":
          var texto = "4ª Certificación";
          var color = "teal";
          break;
        case "CERTIFICACION_M":
          var texto = "Protocolo de entrega";
          var color = "teal";
          break;
        case "DESINFECCION":
          var texto = "Desinfección";
          var color = "purple";
          break;
        default:
          var texto = tipoInspeccion;
          var color = "gray";
      }
      return `<span class="badge color-` + color + `">` + texto + `</span>`;
    },
    getConflictosBadge: function(conflictos) {
      if (conflictos == null || conflictos == undefined || conflictos.length == 0) {
        var texto = "No";
        var color = "green";
      } else {
        var texto = "Si (" + conflictos.length + ")";
        var color = "red";
      }
      return `<span class="badge color-` + color + `">` + texto + `</span>`;
    },
    getCalificacionBadge: function(certificacion) {
      if(certificacion.lineaPedido && certificacion.lineaPedido.tipoInspeccion == "CERTIFICACION_M"){
        var realizada = !!certificacion.calificacion;
        var texto = realizada ? "Realizado" : "Sin realizar";
        var color = realizada ? "green" : "gray";
      } else {
        switch (certificacion.calificacion) {
          case "EXCELENTE":
            var texto = "Favorable";
            var color = "green";
            break;
          case "BUENO":
            var texto = "Favorable";
            var color = "lime";
            break;
          case "DESFAVORABLE":
            var texto = "Desfavorable";
            var color = "red";
            break;
          case "EN_CONFLICTO":
            var texto = "Pendiente";
            var color = "orange";
            break;
          case "JUSTIFICADA":
            var texto = "Justificada";
            var color = "orange";
            break;
          case "DEFECTOS_CORREGIDOS":
            var texto = "Resuelto de 2as";
            var color = "black";
            break;
          default:
            var texto = "Sin realizar";
            var color = "gray";
        }
      }
      return `<span class="badge color-` + color + `">` + texto + `</span>`;
    },
    downloadCertificado: function (inspeccion){
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Inspeccion.downloadCertificado(inspeccion).finally(_=>app.preloader.hide());
    },
    mostrarCliente: function(autocomplete) {
      const self = this;
      return function(query, render) {
        var results = [];
        for (var i = 0; i < autocomplete.items.length; i++) {
          if ((self.$refs.todosClientes.checked || autocomplete.items[i].tipoCliente == 'MARCA') && autocomplete.items[i].nombre.toLowerCase().includes(query.toLowerCase())) {
            autocomplete.items[i]['txtValue'] = autocomplete.items[i][autocomplete.searchProp];
            results.push(autocomplete.items[i]);
          }
        }
        render(results);
      };
    },
    openPopupFacturar: function () {
      var self = this;
      var app = self.$f7;
      self.$refs.rformFactura.formData.clienteId = self.inspeccion.cliente.id;
      self.$refs.rformFactura.formData.inspecciones = [self.inspeccion.id];
    },
    onSaveFactura: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("facturas.facturas_generadas"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
        self.$refs.popupFacturar.close();
        self.$refs.rlist.reload();
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>