import Modelos from './modelos.vue';
import ModeloForm from './modelo-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/modelos/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('modelos', ['ADMINISTRATIVO','SUPERVISOR'], Modelos),
        //keepAlive: true
    },
    {
        path: '/modelos/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('modelos/new', ['ADMINISTRATIVO','SUPERVISOR'], ModeloForm)
    },
    {
        path: '/modelos/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('modelos/edit', ['ADMINISTRATIVO','SUPERVISOR'], ModeloForm)
    },
    {
        path: '/modelos/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('modelos/view', ['ADMINISTRATIVO','SUPERVISOR'], ModeloForm, { editable: false })
    },
];
export default routes;