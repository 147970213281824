import DatabaseModel from './DatabaseModel.js';

var Application = Object.create(DatabaseModel);

Application.model = 'application';

Application.getByAppcode = function(appcode) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server + 'security/' + self.model + '/' + appcode,
        method: 'GET'
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};

export default Application;