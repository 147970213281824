import Login from './login.vue';
import Usuarios from './usuarios.vue';
import UsuarioForm from './usuario-form.vue';
import Perfil from './perfil.vue';
/*import Registro from './registro.vue';
import ForgotPassword from './olvide_contrasena.vue';
import ProfileView from './perfil_view.vue';
import ProfileEdit from './perfil_edit.vue';
//import Notifications from './notificaciones.vue';*/
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/login/',
        beforeEnter: function(routeTo, routeFrom, resolve, reject) {
            var self = this;
            if (RoutesUtils.checkAuth()) {
                reject();
                self.app.views.main.router.navigate('/');
            } else {
                resolve();
            }
        },
        popup: {
            component: Login
        }
    },
    {
        path: '/usuarios/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('usuarios', ['ADMINISTRATIVO'], Usuarios)
    },
    {
        path: '/usuarios/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('usuarios/new', ['ADMINISTRATIVO'], UsuarioForm)
    },
    {
        path: '/usuarios/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('usuarios/edit', ['ADMINISTRATIVO'], UsuarioForm)
    },
    {
        path: '/usuarios/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('usuarios/view', ['ADMINISTRATIVO'], UsuarioForm, {editable: false})
    },
    {
        path: '/perfil/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('perfil', ['ADMINISTRATIVO', 'CLIENTE', 'INSPECTOR','SUPERVISOR'], Perfil)
    },
    /*{
    	path: '/registro/',
    	beforeEnter: function (routeTo, routeFrom, resolve, reject) {
    		var self = this;
    		if (self.app.methods.checkAuth()) {
    			reject();
    			self.app.views.main.router.navigate('/');
    		} else {
    			resolve();
    		}
    	},
    	component: Registro
    },
    {
    	path: '/olvide-contrasena/',
    	beforeEnter: function (routeTo, routeFrom, resolve, reject) {
    		var self = this;
    		if (self.app.methods.checkAuth()) {
    			reject();
    			self.app.views.main.router.navigate('/');
    		} else {
    			resolve();
    		}
    	},
    	component: ForgotPassword
    },
    {
    	path: '/perfil/view/:id/',
    	alias: '/perfil/',
    	beforeEnter: function (routeTo, routeFrom, resolve, reject) {
    		var self = this;
    		if (self.app.methods.checkAuth()) {
    			resolve();
    		} else {
    			reject();
    			self.app.views.main.router.navigate('/login/');
    		}
    	},
    	async: function (routeTo, routeFrom, resolve, reject) {
    		var self = this;
    		if (self.app.methods.checkPermission('usuarios_view')) {
    			resolve({
    				component: ProfileView
    			});
    		} else {
    			resolve({
    				component: Error403
    			});
    		}
    	}
    },
    {
    	path: '/perfil/edit/',
    	beforeEnter: function (routeTo, routeFrom, resolve, reject) {
    		var self = this;
    		if (self.app.methods.checkAuth()) {
    			resolve();
    		} else {
    			reject();
    			self.app.views.main.router.navigate('/login/');
    		}
    	},
    	async: function (routeTo, routeFrom, resolve, reject) {
    		var self = this;
    		if (self.app.methods.checkPermission('usuarios_edit')) {
    			resolve({
    				component: ProfileEdit
    			});
    		} else {
    			resolve({
    				component: Error403
    			});
    		}
    	}
    }*/
];
export default routes;